import ITarget from "./Target";

class RedirectTarget implements ITarget {
    public readonly requiresAuthorization: boolean;

    constructor(public readonly href: string) {
        this.requiresAuthorization = false;
    }
}

export default RedirectTarget;
