/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { HecateV1_AddParticipantRequest } from "../models/HecateV1_AddParticipantRequest";
import type { HecateV1_AmortizationBasisDialogueCaseResponse } from "../models/HecateV1_AmortizationBasisDialogueCaseResponse";
import type { HecateV1_ApplicantIndata } from "../models/HecateV1_ApplicantIndata";
import type { HecateV1_ApplicantResponse } from "../models/HecateV1_ApplicantResponse";
import type { HecateV1_ApplicantsResponse } from "../models/HecateV1_ApplicantsResponse";
import type { HecateV1_ApplicationLoansResponse } from "../models/HecateV1_ApplicationLoansResponse";
import type { HecateV1_ApplicationResponse } from "../models/HecateV1_ApplicationResponse";
import type { HecateV1_BanksResponse } from "../models/HecateV1_BanksResponse";
import type { HecateV1_ChatCaseResponse } from "../models/HecateV1_ChatCaseResponse";
import type { HecateV1_CollateralIndata } from "../models/HecateV1_CollateralIndata";
import type { HecateV1_CollateralResponse } from "../models/HecateV1_CollateralResponse";
import type { HecateV1_CollateralsResponse } from "../models/HecateV1_CollateralsResponse";
import type { HecateV1_ComplementCaseForCustomerResponse } from "../models/HecateV1_ComplementCaseForCustomerResponse";
import type { HecateV1_ConfigurationResponse } from "../models/HecateV1_ConfigurationResponse";
import type { HecateV1_ConvertToPurchaseApplicationRequest } from "../models/HecateV1_ConvertToPurchaseApplicationRequest";
import type { HecateV1_CreateApplicantIndata } from "../models/HecateV1_CreateApplicantIndata";
import type { HecateV1_CreateApplicationIndata } from "../models/HecateV1_CreateApplicationIndata";
import type { HecateV1_CreateApplicationResponse } from "../models/HecateV1_CreateApplicationResponse";
import type { HecateV1_CreateSubmissionRequest } from "../models/HecateV1_CreateSubmissionRequest";
import type { HecateV1_CreateSubmissionResponse } from "../models/HecateV1_CreateSubmissionResponse";
import type { HecateV1_CurrentLoansResponse } from "../models/HecateV1_CurrentLoansResponse";
import type { HecateV1_DocumentResponse } from "../models/HecateV1_DocumentResponse";
import type { HecateV1_DocumentsResponse } from "../models/HecateV1_DocumentsResponse";
import type { HecateV1_EngagementBridgeLoanOutdata } from "../models/HecateV1_EngagementBridgeLoanOutdata";
import type { HecateV1_EngagementDepositLoanOutdata } from "../models/HecateV1_EngagementDepositLoanOutdata";
import type { HecateV1_EngagementMortgageOutdata } from "../models/HecateV1_EngagementMortgageOutdata";
import type { HecateV1_EngagementResponse } from "../models/HecateV1_EngagementResponse";
import type { HecateV1_ErrorLogRequestBody } from "../models/HecateV1_ErrorLogRequestBody";
import type { HecateV1_InteractionLogRequestBody } from "../models/HecateV1_InteractionLogRequestBody";
import type { HecateV1_InterestRateFixationCaseResponse } from "../models/HecateV1_InterestRateFixationCaseResponse";
import type { HecateV1_InterestTermResetDialogueCaseResponse } from "../models/HecateV1_InterestTermResetDialogueCaseResponse";
import type { HecateV1_ListCaseResponse } from "../models/HecateV1_ListCaseResponse";
import type { HecateV1_ListSignatureCollectionDocumentResponse } from "../models/HecateV1_ListSignatureCollectionDocumentResponse";
import type { HecateV1_LoanResponse } from "../models/HecateV1_LoanResponse";
import type { HecateV1_LoansResponse } from "../models/HecateV1_LoansResponse";
import type { HecateV1_LogResponse } from "../models/HecateV1_LogResponse";
import type { HecateV1_NavigationLogRequestBody } from "../models/HecateV1_NavigationLogRequestBody";
import type { HecateV1_OtherProductsResponse } from "../models/HecateV1_OtherProductsResponse";
import type { HecateV1_OtherPropertiesResponse } from "../models/HecateV1_OtherPropertiesResponse";
import type { HecateV1_PaginatedApplicationResponse } from "../models/HecateV1_PaginatedApplicationResponse";
import type { HecateV1_PaginatedEngagementResponse } from "../models/HecateV1_PaginatedEngagementResponse";
import type { HecateV1_PaginatedLoanInvoicesResponse } from "../models/HecateV1_PaginatedLoanInvoicesResponse";
import type { HecateV1_ParticipantResponse } from "../models/HecateV1_ParticipantResponse";
import type { HecateV1_PatchDocumentsBody } from "../models/HecateV1_PatchDocumentsBody";
import type { HecateV1_PostSignatureBody } from "../models/HecateV1_PostSignatureBody";
import type { HecateV1_PropertyResponse } from "../models/HecateV1_PropertyResponse";
import type { HecateV1_QuestionnaireResponse } from "../models/HecateV1_QuestionnaireResponse";
import type { HecateV1_RatesResponse } from "../models/HecateV1_RatesResponse";
import type { HecateV1_RegisterIndividualIncomesRequest } from "../models/HecateV1_RegisterIndividualIncomesRequest";
import type { HecateV1_SearchAddressResponse } from "../models/HecateV1_SearchAddressResponse";
import type { HecateV1_SearchPropertyResponse } from "../models/HecateV1_SearchPropertyResponse";
import type { HecateV1_SignatureCollectionCaseForCustomerResponse } from "../models/HecateV1_SignatureCollectionCaseForCustomerResponse";
import type { HecateV1_SignatureCollectionDocumentResponse } from "../models/HecateV1_SignatureCollectionDocumentResponse";
import type { HecateV1_SubmissionsResponse } from "../models/HecateV1_SubmissionsResponse";
import type { HecateV1_UpdateApplicantRequest } from "../models/HecateV1_UpdateApplicantRequest";
import type { HecateV1_UpdateApplicationIndata } from "../models/HecateV1_UpdateApplicationIndata";
import type { HecateV1_UpdateConsentsRequest } from "../models/HecateV1_UpdateConsentsRequest";
import type { HecateV1_UpdateCurrentLoanRequest } from "../models/HecateV1_UpdateCurrentLoanRequest";
import type { HecateV1_UpdateLoanIndata } from "../models/HecateV1_UpdateLoanIndata";
import type { HecateV1_UpdateOtherPropertiesRequest } from "../models/HecateV1_UpdateOtherPropertiesRequest";
import type { HecateV1_UpdatePropertySaleFinancingRequest } from "../models/HecateV1_UpdatePropertySaleFinancingRequest";

export class HecateApiV1Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        HecateApiV1Service.url = url;
    }

    /**
     * @returns HecateV1_PaginatedApplicationResponse
     */
    public static async hecateApplicationControllerV1List({
        limit,
        offset,
        propertyId,
    }: {
        limit?: number,
        offset?: number,
        propertyId?: string,
    }, ): Promise<HecateV1_PaginatedApplicationResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
            "property_id": propertyId,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1List (${"/v1h/applications"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_CreateApplicationResponse
     */
    public static async hecateApplicationControllerV1Create({
        requestBody,
    }: {
        requestBody: HecateV1_CreateApplicationIndata,
    }, ): Promise<HecateV1_CreateApplicationResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1Create (${"/v1h/applications"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicationResponse
     */
    public static async hecateApplicationControllerV1Get({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_ApplicationResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1Get (${"/v1h/applications/${applicationId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateApplicationControllerV1Delete({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<any> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}`, query }), {
            method: "DELETE",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1Delete (${"/v1h/applications/${applicationId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicationResponse
     */
    public static async hecateApplicationControllerV1Update({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: HecateV1_UpdateApplicationIndata,
    }, ): Promise<HecateV1_ApplicationResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}`, query }), {
            method: "PATCH",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1Update (${"/v1h/applications/${applicationId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicantsResponse
     */
    public static async hecateApplicationControllerV1ListApplicants({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_ApplicantsResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/applicants`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1ListApplicants (${"/v1h/applications/${applicationId}/applicants"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicantResponse
     */
    public static async hecateApplicationControllerV1CreateApplicant({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: HecateV1_CreateApplicantIndata,
    }, ): Promise<HecateV1_ApplicantResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/applicants`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1CreateApplicant (${"/v1h/applications/${applicationId}/applicants"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicantResponse
     */
    public static async hecateApplicationControllerV1UpdateApplicant({
        applicationId,
        applicantId,
        requestBody,
    }: {
        applicationId: string,
        applicantId: string,
        requestBody: HecateV1_ApplicantIndata,
    }, ): Promise<HecateV1_ApplicantResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/applicants/${applicantId}`, query }), {
            method: "PATCH",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1UpdateApplicant (${"/v1h/applications/${applicationId}/applicants/${applicantId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateApplicationControllerV1DeleteApplicant({
        applicationId,
        applicantId,
    }: {
        applicationId: string,
        applicantId: string,
    }, ): Promise<any> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/applicants/${applicantId}`, query }), {
            method: "DELETE",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1DeleteApplicant (${"/v1h/applications/${applicationId}/applicants/${applicantId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_SearchPropertyResponse
     */
    public static async hecateApplicationControllerV1SearchAddresses({
        applicationId,
        address,
    }: {
        applicationId: string,
        address?: string,
    }, ): Promise<HecateV1_SearchPropertyResponse> {
        const query =  {
            "address": address,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/properties`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1SearchAddresses (${"/v1h/applications/${applicationId}/properties"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_CollateralsResponse
     */
    public static async hecateApplicationControllerV1ListCollaterals({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_CollateralsResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/collaterals`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1ListCollaterals (${"/v1h/applications/${applicationId}/collaterals"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_CollateralResponse
     */
    public static async hecateApplicationControllerV1CreateCollateral({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: HecateV1_CollateralIndata,
    }, ): Promise<HecateV1_CollateralResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/collaterals`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1CreateCollateral (${"/v1h/applications/${applicationId}/collaterals"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicationLoansResponse
     */
    public static async hecateApplicationControllerV1ListLoan({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_ApplicationLoansResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/loans`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1ListLoan (${"/v1h/applications/${applicationId}/loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicationLoansResponse
     */
    public static async hecateApplicationControllerV1UpdateLoan({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: Array<HecateV1_UpdateLoanIndata>,
    }, ): Promise<HecateV1_ApplicationLoansResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/loans`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1UpdateLoan (${"/v1h/applications/${applicationId}/loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_CurrentLoansResponse
     */
    public static async hecateApplicationControllerV1GetCurrentLoans({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_CurrentLoansResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/current-loans`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1GetCurrentLoans (${"/v1h/applications/${applicationId}/current-loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_CurrentLoansResponse
     */
    public static async hecateApplicationControllerV1UpdateCurrentLoans({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: Array<HecateV1_UpdateCurrentLoanRequest>,
    }, ): Promise<HecateV1_CurrentLoansResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/current-loans`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1UpdateCurrentLoans (${"/v1h/applications/${applicationId}/current-loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_OtherPropertiesResponse
     */
    public static async hecateApplicationControllerV1GetOtherProperties({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_OtherPropertiesResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/other-properties`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1GetOtherProperties (${"/v1h/applications/${applicationId}/other-properties"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_OtherPropertiesResponse
     */
    public static async hecateApplicationControllerV1UpdateOtherProperties({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: Array<HecateV1_UpdateOtherPropertiesRequest>,
    }, ): Promise<HecateV1_OtherPropertiesResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/other-properties`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1UpdateOtherProperties (${"/v1h/applications/${applicationId}/other-properties"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateApplicationControllerV1UpdatePropertySaleFinancing({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: HecateV1_UpdatePropertySaleFinancingRequest,
    }, ): Promise<any> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/property-sale-financing`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1UpdatePropertySaleFinancing (${"/v1h/applications/${applicationId}/property-sale-financing"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_CreateSubmissionResponse
     */
    public static async hecateApplicationControllerV1ConvertToPurchaseApplication({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: HecateV1_ConvertToPurchaseApplicationRequest,
    }, ): Promise<HecateV1_CreateSubmissionResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/convert-to-purchase-application`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1ConvertToPurchaseApplication (${"/v1h/applications/${applicationId}/convert-to-purchase-application"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_SubmissionsResponse
     */
    public static async hecateApplicationControllerV1GetSubmissions({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_SubmissionsResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/submissions`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1GetSubmissions (${"/v1h/applications/${applicationId}/submissions"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_CreateSubmissionResponse
     */
    public static async hecateApplicationControllerV1CreateSubmission({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: HecateV1_CreateSubmissionRequest,
    }, ): Promise<HecateV1_CreateSubmissionResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/submissions`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1CreateSubmission (${"/v1h/applications/${applicationId}/submissions"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateApplicationControllerV1DeleteSubmission({
        applicationId,
        submissionId,
    }: {
        applicationId: string,
        submissionId: string,
    }, ): Promise<any> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/submissions/${submissionId}`, query }), {
            method: "DELETE",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1DeleteSubmission (${"/v1h/applications/${applicationId}/submissions/${submissionId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_SearchAddressResponse
     */
    public static async hecateApplicationControllerV1SearchAddress({
        applicationId,
        text,
    }: {
        applicationId: string,
        text: string,
    }, ): Promise<HecateV1_SearchAddressResponse> {
        const query =  {
            "text": text,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/addresses`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1SearchAddress (${"/v1h/applications/${applicationId}/addresses"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_RatesResponse
     */
    public static async hecateApplicationControllerV1ListRates({
        applicationId,
        propertyValuationAmount,
        mortgageAmount,
    }: {
        applicationId: string,
        propertyValuationAmount?: number,
        mortgageAmount?: number,
    }, ): Promise<HecateV1_RatesResponse> {
        const query =  {
            "property_valuation_amount": propertyValuationAmount,
            "mortgage_amount": mortgageAmount,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/rates`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1ListRates (${"/v1h/applications/${applicationId}/rates"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_DocumentResponse
     */
    public static async hecateApplicationControllerV1DownloadCommitmentDocument({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<HecateV1_DocumentResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/commitment-document`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicationControllerV1DownloadCommitmentDocument (${"/v1h/applications/${applicationId}/commitment-document"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicationResponse
     */
    public static async hecateApplicantIncomeControllerV1RegisterIncomes({
        applicationId,
        applicantId,
        requestBody,
    }: {
        applicationId: string,
        applicantId: string,
        requestBody: HecateV1_RegisterIndividualIncomesRequest,
    }, ): Promise<HecateV1_ApplicationResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/applications/${applicationId}/applicant/${applicantId}/incomes`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateApplicantIncomeControllerV1RegisterIncomes (${"/v1h/applications/${applicationId}/applicant/${applicantId}/incomes"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ConfigurationResponse
     */
    public static async hecateConfigurationControllerV1Get(): Promise<HecateV1_ConfigurationResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/configuration`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateConfigurationControllerV1Get (${"/v1h/configuration"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_BanksResponse
     */
    public static async hecateBankControllerV1List(): Promise<HecateV1_BanksResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/banks`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateBankControllerV1List (${"/v1h/banks"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ListCaseResponse
     */
    public static async hecateCaseControllerV1All({
        limit,
        offset,
        isOpen,
    }: {
        limit?: number,
        offset?: number,
        isOpen?: 'true' | 'false',
    }, ): Promise<HecateV1_ListCaseResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
            "is_open": isOpen,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1All (${"/v1h/cases"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateCaseControllerV1Get({
        id,
    }: {
        id: string,
    }, ): Promise<(HecateV1_SignatureCollectionCaseForCustomerResponse | HecateV1_ComplementCaseForCustomerResponse | HecateV1_InterestRateFixationCaseResponse | HecateV1_ChatCaseResponse | HecateV1_InterestTermResetDialogueCaseResponse | HecateV1_AmortizationBasisDialogueCaseResponse)> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1Get (${"/v1h/cases/${id}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateCaseControllerV1Update({
        id,
    }: {
        id: string,
    }, ): Promise<(HecateV1_SignatureCollectionCaseForCustomerResponse | HecateV1_ComplementCaseForCustomerResponse | HecateV1_InterestRateFixationCaseResponse | HecateV1_ChatCaseResponse | HecateV1_InterestTermResetDialogueCaseResponse | HecateV1_AmortizationBasisDialogueCaseResponse)> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}`, query }), {
            method: "PATCH",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1Update (${"/v1h/cases/${id}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ParticipantResponse
     */
    public static async hecateCaseControllerV1AddParticipant({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: HecateV1_AddParticipantRequest,
    }, ): Promise<HecateV1_ParticipantResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/participants`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1AddParticipant (${"/v1h/cases/${id}/participants"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ParticipantResponse
     */
    public static async hecateCaseControllerV1UpdateParticipant({
        id,
        participantId,
        requestBody,
    }: {
        id: string,
        participantId: string,
        requestBody: HecateV1_UpdateApplicantRequest,
    }, ): Promise<HecateV1_ParticipantResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/participants/${participantId}`, query }), {
            method: "PATCH",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1UpdateParticipant (${"/v1h/cases/${id}/participants/${participantId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateCaseControllerV1Delete({
        id,
        participantId,
    }: {
        id: string,
        participantId: string,
    }, ): Promise<any> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/participants/${participantId}`, query }), {
            method: "DELETE",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1Delete (${"/v1h/cases/${id}/participants/${participantId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateCaseControllerV1InitiateSignature({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: HecateV1_PostSignatureBody,
    }, ): Promise<(HecateV1_SignatureCollectionCaseForCustomerResponse | HecateV1_ComplementCaseForCustomerResponse | HecateV1_InterestRateFixationCaseResponse | HecateV1_ChatCaseResponse | HecateV1_InterestTermResetDialogueCaseResponse | HecateV1_AmortizationBasisDialogueCaseResponse)> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/sign`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1InitiateSignature (${"/v1h/cases/${id}/sign"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_SignatureCollectionDocumentResponse
     */
    public static async hecateCaseControllerV1GetAttachment({
        id,
        attachmentId,
    }: {
        id: string,
        attachmentId: string,
    }, ): Promise<HecateV1_SignatureCollectionDocumentResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/attachments/${attachmentId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1GetAttachment (${"/v1h/cases/${id}/attachments/${attachmentId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ListSignatureCollectionDocumentResponse
     */
    public static async hecateCaseControllerV1List({
        id,
    }: {
        id: string,
    }, ): Promise<Array<HecateV1_ListSignatureCollectionDocumentResponse>> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/documents`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1List (${"/v1h/cases/${id}/documents"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_SignatureCollectionDocumentResponse
     */
    public static async hecateCaseControllerV1GetCaseDocuments({
        id,
        documentId,
    }: {
        id: string,
        documentId: string,
    }, ): Promise<HecateV1_SignatureCollectionDocumentResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/documents/${documentId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateCaseControllerV1GetCaseDocuments (${"/v1h/cases/${id}/documents/${documentId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ComplementCaseForCustomerResponse
     */
    public static async hecateComplementControllerV1UpdateTaskDocuments({
        id,
        taskId,
        requestBody,
    }: {
        id: string,
        taskId: string,
        requestBody: HecateV1_PatchDocumentsBody,
    }, ): Promise<HecateV1_ComplementCaseForCustomerResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/cases/${id}/tasks/${taskId}`, query }), {
            method: "PATCH",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateComplementControllerV1UpdateTaskDocuments (${"/v1h/cases/${id}/tasks/${taskId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_DocumentsResponse
     */
    public static async hecateDocumentControllerV1List(): Promise<HecateV1_DocumentsResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/documents`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateDocumentControllerV1List (${"/v1h/documents"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_DocumentResponse
     */
    public static async hecateDocumentControllerV1Download({
        documentId,
        variant,
    }: {
        documentId: string,
        variant?: 'bridge_loan' | 'deposit_loan' | 'mortgage',
    }, ): Promise<HecateV1_DocumentResponse> {
        const query =  {
            "variant": variant,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/documents/${documentId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateDocumentControllerV1Download (${"/v1h/documents/${documentId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_PaginatedEngagementResponse
     */
    public static async hecateEngagementControllerV1List({
        limit,
        offset,
    }: {
        limit?: number,
        offset?: number,
    }, ): Promise<HecateV1_PaginatedEngagementResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/engagements`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateEngagementControllerV1List (${"/v1h/engagements"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_EngagementResponse
     */
    public static async hecateEngagementControllerV1Get({
        engagementId,
    }: {
        engagementId: string,
    }, ): Promise<HecateV1_EngagementResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/engagements/${engagementId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateEngagementControllerV1Get (${"/v1h/engagements/${engagementId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async hecateEngagementControllerV1GetLoan({
        engagementId,
        loanId,
    }: {
        engagementId: string,
        loanId: string,
    }, ): Promise<(HecateV1_EngagementMortgageOutdata | HecateV1_EngagementDepositLoanOutdata | HecateV1_EngagementBridgeLoanOutdata)> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/engagements/${engagementId}/loans/${loanId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateEngagementControllerV1GetLoan (${"/v1h/engagements/${engagementId}/loans/${loanId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_LoansResponse
     */
    public static async hecateLoanControllerV1List(): Promise<HecateV1_LoansResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/loans`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateLoanControllerV1List (${"/v1h/loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_LoanResponse
     */
    public static async hecateLoanControllerV1GetLoan({
        loanId,
    }: {
        loanId: string,
    }, ): Promise<HecateV1_LoanResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/loans/${loanId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateLoanControllerV1GetLoan (${"/v1h/loans/${loanId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_PaginatedLoanInvoicesResponse
     */
    public static async hecateLoanControllerV1GetInvoices({
        loanId,
        limit,
        offset,
    }: {
        loanId: string,
        limit?: number,
        offset?: number,
    }, ): Promise<HecateV1_PaginatedLoanInvoicesResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/loans/${loanId}/invoices`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateLoanControllerV1GetInvoices (${"/v1h/loans/${loanId}/invoices"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_DocumentResponse
     */
    public static async hecateLoanControllerV1GetTerms({
        loanId,
    }: {
        loanId: string,
    }, ): Promise<HecateV1_DocumentResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/loans/${loanId}/terms`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateLoanControllerV1GetTerms (${"/v1h/loans/${loanId}/terms"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns string
     */
    public static async hecateLoanControllerV1StartInterestRateFixation({
        loanId,
    }: {
        loanId: string,
    }, ): Promise<string> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/loans/${loanId}/start-interest-rate-fixation`, query }), {
            method: "POST",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateLoanControllerV1StartInterestRateFixation (${"/v1h/loans/${loanId}/start-interest-rate-fixation"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_LogResponse
     */
    public static async hecateLogControllerV1Log({
        requestBody,
    }: {
        requestBody: (HecateV1_InteractionLogRequestBody | HecateV1_NavigationLogRequestBody | HecateV1_ErrorLogRequestBody),
    }, ): Promise<HecateV1_LogResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/logs`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateLogControllerV1Log (${"/v1h/logs"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_PropertyResponse
     */
    public static async hecatePropertyControllerV1GetById({
        propertyId,
    }: {
        propertyId: string,
    }, ): Promise<HecateV1_PropertyResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/properties/${propertyId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecatePropertyControllerV1GetById (${"/v1h/properties/${propertyId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_QuestionnaireResponse
     */
    public static async hecateQuestionnaireControllerV1List({
        applicationType,
    }: {
        /** Type has to be specified for purchase loan applications. **/
        applicationType?: 'NEW' | 'TRANSFER' | 'TOPUP' | 'PURCHASE' | 'COMMITMENT',
    }, ): Promise<HecateV1_QuestionnaireResponse> {
        const query =  {
            "application_type": applicationType,
        } ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/questionnaires/origination`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateQuestionnaireControllerV1List (${"/v1h/questionnaires/origination"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_RatesResponse
     */
    public static async hecateRateControllerV1List(): Promise<HecateV1_RatesResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/rates`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateRateControllerV1List (${"/v1h/rates"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_OtherProductsResponse
     */
    public static async hecateRateControllerV1Others(): Promise<HecateV1_OtherProductsResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/rates/others`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateRateControllerV1Others (${"/v1h/rates/others"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns HecateV1_ApplicationResponse
     */
    public static async hecateConsentsControllerV1UpdateConsents({
        requestBody,
    }: {
        requestBody: HecateV1_UpdateConsentsRequest,
    }, ): Promise<HecateV1_ApplicationResponse> {
        const query =  {} ;
        if (HecateApiV1Service.url === undefined) throw new Error("No url set for generated HecateApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": HecateApiV1Service.url + `/v1h/consents`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `HecateApiV1Service.hecateConsentsControllerV1UpdateConsents (${"/v1h/consents"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}