class SessionToken {
    constructor(
        public readonly id?: string,
        public readonly name?: string,
        public readonly realm?: string,
        public readonly issuer?: string,
        public readonly expireAt?: Date,
        public readonly issuedAt?: Date,
        public readonly raw?: string,
        public readonly userId?: string,
    ) { }
}

export default SessionToken;
