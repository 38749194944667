import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import * as React from "react";

import { stabeloTheme } from "../theme-config";

interface IProps {
	children?: React.ReactNode;
}

const App = ({ children }: IProps) => {
	return (
		<ThemeProvider theme={stabeloTheme}>
			<CssBaseline />
			<>{children}</>
		</ThemeProvider>
	);
};

export default App;
