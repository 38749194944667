/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { StabeloV2_AcknowledgeMessageRequest } from "../models/StabeloV2_AcknowledgeMessageRequest";
import type { StabeloV2_AmortizationRequirementBasisDialogueResponse } from "../models/StabeloV2_AmortizationRequirementBasisDialogueResponse";
import type { StabeloV2_CreateAmortizationRequirementBasisDialogueRequest } from "../models/StabeloV2_CreateAmortizationRequirementBasisDialogueRequest";
import type { StabeloV2_InterestTermResetDialogueResponse } from "../models/StabeloV2_InterestTermResetDialogueResponse";

export class StabeloApiV2Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        StabeloApiV2Service.url = url;
    }

    /**
     * @returns any
     */
    public static async stabeloV2DialogueControllerCreate({
        requestBody,
    }: {
        requestBody: (StabeloV2_CreateAmortizationRequirementBasisDialogueRequest),
    }, ): Promise<(StabeloV2_AmortizationRequirementBasisDialogueResponse | StabeloV2_InterestTermResetDialogueResponse)> {
        const query =  {} ;
        if (StabeloApiV2Service.url === undefined) throw new Error("No url set for generated StabeloApiV2Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": StabeloApiV2Service.url + `/v2s/dialogues/$create-dialogue`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `StabeloApiV2Service.stabeloV2DialogueControllerCreate (${"/v2s/dialogues/$create-dialogue"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async stabeloV2DialogueControllerGet({
        id,
    }: {
        id: string,
    }, ): Promise<(StabeloV2_AmortizationRequirementBasisDialogueResponse | StabeloV2_InterestTermResetDialogueResponse)> {
        const query =  {} ;
        if (StabeloApiV2Service.url === undefined) throw new Error("No url set for generated StabeloApiV2Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": StabeloApiV2Service.url + `/v2s/dialogues/${id}/$get-dialogue`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `StabeloApiV2Service.stabeloV2DialogueControllerGet (${"/v2s/dialogues/${id}/$get-dialogue"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async stabeloV2DialogueControllerAcknowledgeMessage({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: StabeloV2_AcknowledgeMessageRequest,
    }, ): Promise<(StabeloV2_AmortizationRequirementBasisDialogueResponse | StabeloV2_InterestTermResetDialogueResponse)> {
        const query =  {} ;
        if (StabeloApiV2Service.url === undefined) throw new Error("No url set for generated StabeloApiV2Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": StabeloApiV2Service.url + `/v2s/dialogues/${id}/$acknowledge-message`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `StabeloApiV2Service.stabeloV2DialogueControllerAcknowledgeMessage (${"/v2s/dialogues/${id}/$acknowledge-message"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}