import { MenuItem, Select, Theme, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { LanaPengarV1_BankResponse, LanaPengarV1_CreateTransferApplicationRequest } from "../../../generated/iris";
import { MAXIMUM_MORTGAGE, MINIMUM_MORTGAGE } from "../../constants";
import { NumberTextField } from "../maskedInputs/NumberTextField";

import { decimalToSerializedAmount } from "./helpers/decimalToSerializedAmount";
import { displaySekAmount } from "./helpers/displaySekAmount";
import { numberTextFieldErrorStyle, numberTextFieldStyle } from "./numberTextFieldStyle";
import { TabPanelForm } from "./TabPanelForm";

const selectFieldStyle = {
	marginBottom: "1rem",
};

const selectFieldErrorStyle = (theme: Theme) => ({
	...selectFieldStyle,
	borderColor: theme.palette.error.main,
});

const menuItemStyle = {
	fontSize: "1rem",
};

export const TransferApplicationForm = ({
	id,
	labelledBy,
	hidden,
	loading,
	onSubmit,
	banks,
}: {
	id: string;
	labelledBy: string;
	hidden: boolean;
	loading: boolean;
	onSubmit: (request: LanaPengarV1_CreateTransferApplicationRequest) => Promise<void>;
	banks: LanaPengarV1_BankResponse[] | undefined;
}) => {
	const theme = useTheme();
	const [currentLoanAmount, setCurrentLoanAmount] = React.useState<number | undefined>(undefined);
	const [currentBank, setCurrentBank] = React.useState<string>("CHOOSE");
	const [wantsToIncreaseLoan, setWantsToIncreaseLoan] = React.useState<boolean>(true);
	const [increaseLoanAmount, setIncreaseLoanAmount] = React.useState<number | undefined>(undefined);
	const [showError, setShowError] = React.useState(false);

	const currentLoanAmountInvalid =
		currentLoanAmount === undefined
			? false
			: currentLoanAmount > MAXIMUM_MORTGAGE || (!wantsToIncreaseLoan && currentLoanAmount < MINIMUM_MORTGAGE);

	const currentBankInvalid = currentBank === "CHOOSE";

	const increaseLoanAmountInvalid =
		!wantsToIncreaseLoan || currentLoanAmount === undefined || increaseLoanAmount === undefined
			? false
			: currentLoanAmount + increaseLoanAmount < MINIMUM_MORTGAGE ||
				currentLoanAmount + increaseLoanAmount > MAXIMUM_MORTGAGE;

	const submit = () => {
		if (
			currentLoanAmount === undefined ||
			currentLoanAmountInvalid ||
			currentBankInvalid ||
			(wantsToIncreaseLoan && (increaseLoanAmount === undefined || increaseLoanAmountInvalid))
		) {
			setShowError(true);
			return;
		}

		void onSubmit({
			type: "TRANSFER",
			current_mortgage_amount: decimalToSerializedAmount(currentLoanAmount),
			current_mortgage_bank_id: currentBank === "OTHER" ? undefined : currentBank,
			mortgage_topup_amount: decimalToSerializedAmount(wantsToIncreaseLoan ? increaseLoanAmount! : 0),
		});
	};

	return (
		<TabPanelForm id={id} labelledBy={labelledBy} hidden={hidden} loading={loading} onSubmit={submit}>
			<Typography id="current-loan-amount-label">Storlek på ditt bolån idag</Typography>
			<NumberTextField
				aria-labelledby="current-loan-amount-label"
				hiddenLabel
				fullWidth
				disabled={loading}
				value={currentLoanAmount?.toString() ?? ""}
				suffix={"kr"}
				min={0}
				onChange={(value) => setCurrentLoanAmount(value === "" ? undefined : parseInt(value))}
				error={showError && currentLoanAmountInvalid}
				helperText={
					showError && currentLoanAmountInvalid
						? wantsToIncreaseLoan
							? `Bolån får inte överskrida ${displaySekAmount(MAXIMUM_MORTGAGE)}.`
							: `Bolån måste vara minst ${displaySekAmount(MINIMUM_MORTGAGE)} och får inte överskrida ${displaySekAmount(MAXIMUM_MORTGAGE)}.`
						: undefined
				}
				sx={showError && currentLoanAmount === undefined ? numberTextFieldErrorStyle(theme) : numberTextFieldStyle}
			/>
			<Typography id="current-bank-label">Nuvarande bank</Typography>
			<Select
				labelId="current-bank-label"
				disabled={loading}
				hiddenLabel
				aria-label="Nuvarande bank"
				value={currentBank}
				onChange={(event) => setCurrentBank(event.target.value)}
				sx={showError && currentBankInvalid ? selectFieldErrorStyle(theme) : selectFieldStyle}
			>
				<MenuItem value={"CHOOSE"} sx={menuItemStyle}>
					Välj bank
				</MenuItem>
				{banks !== undefined &&
					banks.map((bank) => (
						<MenuItem key={bank.id} value={bank.id} sx={menuItemStyle}>
							{bank.name}
						</MenuItem>
					))}
				<MenuItem value={"OTHER"} sx={menuItemStyle}>
					Annan bank
				</MenuItem>
			</Select>
			<Typography>Vill du låna mer än ditt nuvarande bolån?</Typography>
			<ToggleButtonGroup
				exclusive
				fullWidth
				value={wantsToIncreaseLoan}
				onChange={(_, value: boolean) => {
					if (value === null) {
						return;
					}
					setWantsToIncreaseLoan(value);
				}}
				sx={{ marginBottom: "1rem" }}
			>
				<ToggleButton disabled={loading} value={true}>
					Ja
				</ToggleButton>
				<ToggleButton disabled={loading} value={false}>
					Nej
				</ToggleButton>
			</ToggleButtonGroup>

			{wantsToIncreaseLoan && (
				<>
					<Typography id="increase-loan-amount-label">Hur mycket mer vill du låna?</Typography>
					<NumberTextField
						aria-labelledby="increase-loan-amount-label"
						hiddenLabel
						fullWidth
						disabled={loading}
						value={increaseLoanAmount?.toString() ?? ""}
						suffix={"kr"}
						min={1}
						onChange={(value) => setIncreaseLoanAmount(value === "" ? undefined : parseInt(value))}
						error={showError && increaseLoanAmountInvalid}
						helperText={
							showError && increaseLoanAmountInvalid
								? `Bolån och utökning måste vara minst ${displaySekAmount(MINIMUM_MORTGAGE)} och får inte överskrida ${displaySekAmount(MAXIMUM_MORTGAGE)}.`
								: undefined
						}
						sx={showError && increaseLoanAmount === undefined ? numberTextFieldErrorStyle(theme) : numberTextFieldStyle}
					/>
				</>
			)}
		</TabPanelForm>
	);
};
