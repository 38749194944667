import * as React from "react";
import { IMask } from "react-imask";

import { IMaskedTextFieldProps, MaskedTextField } from "./MaskedTextField";

const getNumberMaskedOptions = (max?: number, min?: number) => ({
	mask: Number,
	thousandsSeparator: " ",
	lazy: false,
	max,
	min,
});

const getSuffixMaskOptions = (suffix: string, max?: number, min?: number) => ({
	mask: `numberWithSuffix ${suffix}`,
	lazy: false,
	blocks: {
		numberWithSuffix: getNumberMaskedOptions(max, min),
	},
});

const removeSuffixFromEmptyValue = (value: string): string => (/\d/.test(value) ? value : "");
const getOptions = (value: string, suffix?: string, max?: number, min?: number) =>
	suffix === undefined || value === "" ? getNumberMaskedOptions(max, min) : getSuffixMaskOptions(suffix, max, min);

export const numberFormatter: (value: string, suffix?: string) => string = (value: string, suffix?: string) => {
	return IMask.pipe(removeSuffixFromEmptyValue(value), getOptions(value, suffix)) as string;
};

const stripSpacesFromString = (string: string): string => string.replace(/\s/, "");

interface INumberTextFieldProps extends IMaskedTextFieldProps {
	value: string;
	suffix?: string;
	max?: number;
	min?: number;
}

export const NumberTextField = ({ onChange, suffix, max, min, ...props }: INumberTextFieldProps) => {
	const handleChange = (newValue: string) => {
		if (onChange) {
			onChange(newValue);
		}
	};

	const value = stripSpacesFromString(props.value);
	const options = getOptions(value, suffix, max, min);
	const valueToShow = numberFormatter(value, suffix);

	return <MaskedTextField {...props} {...options} unmask={true} currentValue={valueToShow} onAccept={handleChange} />;
};
