import { Box, Divider, Skeleton, styled, SxProps, Typography } from "@mui/material";
import * as React from "react";

import { stabeloTheme } from "../../../../../theme-config";

export interface IRateItem {
	nominalRate: number;
	effectiveRate: number;
	fixationLabel: string;
	isEpc: boolean;
}

interface IProps {
	rateItems: IRateItem[];
	loading: boolean;
	showEffectiveRate: boolean;
	sx?: SxProps;
}

const RateTableContentCell = styled(Typography)(() => ({
	fontSize: "1.67em",
	fontWeight: 400,
	[stabeloTheme.breakpoints.up("xl")]: {
		fontSize: 24,
	},
	[stabeloTheme.breakpoints.down("lg")]: {
		fontSize: "1.67em",
	},
	[stabeloTheme.breakpoints.down("md")]: {
		fontSize: 20,
	},
	[stabeloTheme.breakpoints.down("sm")]: {
		fontSize: 24,
	},
}));

const headerAlign = (headers: string[], index: number) => {
	const isLastHeader = headers.length === index + 1;
	if (isLastHeader) {
		return "right";
	} else {
		return "left";
	}
};

const effectiveRateColumn = (rateItem: IRateItem, showEffectiveRate: boolean) => {
	if (showEffectiveRate) {
		return (
			<RateTableContentCell key={`${rateItem.fixationLabel}-effectiveRate`}>
				{`${rateItem.effectiveRate.toFixed(2)} %`}
			</RateTableContentCell>
		);
	} else {
		return undefined;
	}
};

export const RateTable = ({ rateItems, loading, showEffectiveRate, sx }: IProps) => {
	const rateTableHeaders = showEffectiveRate ? ["Bindningstid", "Ränta", "Effektiv ränta"] : ["Bindningstid", "Ränta"];
	return (
		<Box sx={{ display: "grid", columnGap: 1, padding: 0, ...sx }}>
			{rateTableHeaders
				.map((header, i) => (
					<Typography
						key={header}
						sx={{
							fontWeight: 500,
							fontSize: 22,
							[stabeloTheme.breakpoints.down("md")]: {
								fontSize: 18,
							},
							[stabeloTheme.breakpoints.down("sm")]: {
								fontSize: 22,
							},
						}}
						align={headerAlign(rateTableHeaders, i)}
					>
						{header}
					</Typography>
				))
				.concat(
					<Divider
						key={`rate-divider-header`}
						sx={{ marginY: 1.2, gridColumnStart: 1, gridColumnEnd: rateTableHeaders.length + 1, borderColor: "Black" }}
					></Divider>,
				)
				.concat(
					loading
						? [...Array(6 * rateTableHeaders.length).keys()].map((i) => (
								<Skeleton key={i} variant="rectangular" height={46} sx={{ marginBottom: 1 }} />
							))
						: rateItems
								.map((r, i) => {
									return [
										<RateTableContentCell key={r.fixationLabel}>{r.fixationLabel}</RateTableContentCell>,
										<RateTableContentCell
											align={headerAlign(rateTableHeaders, 1)}
											key={`${r.fixationLabel}-nominalRate`}
										>{`${r.nominalRate.toFixed(2)} %`}</RateTableContentCell>,
										effectiveRateColumn(r, showEffectiveRate),
										<Divider
											key={`rate-divider-${r.fixationLabel}`}
											sx={{
												marginY: 1.2,
												gridColumnStart: 1,
												gridColumnEnd: rateTableHeaders.length + 1,
												borderColor: "Black",
												opacity: i + 1 === rateItems.length ? 1 : 0.25,
											}}
										></Divider>,
									].filter((element): element is React.ReactElement => element !== undefined);
								})
								.flat(),
				)}
		</Box>
	);
};
