import { Amount, Percentage } from "@stabelo/awesome-money-library";

export const calculateLTV = (loan: Amount, valuation?: Amount, currentMortgage?: Amount): number => {
	if (valuation === undefined || loan === undefined) {
		return Number.NaN;
	}
	if (currentMortgage !== undefined) {
		loan = loan.add(currentMortgage);
	}

	const factor = loan.factor(valuation);
	if (Number.isFinite(factor)) {
		return Math.round(Percentage.fromDecimal(factor).toPercentage());
	}

	return Number.NaN;
};
