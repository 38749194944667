import * as React from "react";

import SessionState from "./SessionState";

const SessionContext = React.createContext<SessionState>({
    authenticated: false,
    destroy: () => void (0),
    token: {},
});

export default SessionContext;
