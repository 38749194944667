import { Theme } from "@mui/material";

export const numberTextFieldStyle = {
	height: "5rem",
};

export const numberTextFieldErrorStyle = (theme: Theme) => ({
	...numberTextFieldStyle,
	"& div": { borderColor: theme.palette.error.main },
});
