/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { SopV1_AbortApplicationRequest } from "../models/SopV1_AbortApplicationRequest";
import type { SopV1_AddApplicantRequest } from "../models/SopV1_AddApplicantRequest";
import type { SopV1_AnswersResponse } from "../models/SopV1_AnswersResponse";
import type { SopV1_CommitmentApplicationResponse } from "../models/SopV1_CommitmentApplicationResponse";
import type { SopV1_ComplementResponse } from "../models/SopV1_ComplementResponse";
import type { SopV1_CreateCommitmentApplicationRequest } from "../models/SopV1_CreateCommitmentApplicationRequest";
import type { SopV1_CreatePurchaseApplicationRequest } from "../models/SopV1_CreatePurchaseApplicationRequest";
import type { SopV1_CreateTopupApplicationRequest } from "../models/SopV1_CreateTopupApplicationRequest";
import type { SopV1_CreateTransferApplicationRequest } from "../models/SopV1_CreateTransferApplicationRequest";
import type { SopV1_PaginatedAnswerResponse } from "../models/SopV1_PaginatedAnswerResponse";
import type { SopV1_PaginatedApplicationResponse } from "../models/SopV1_PaginatedApplicationResponse";
import type { SopV1_PaginatedComplementResponse } from "../models/SopV1_PaginatedComplementResponse";
import type { SopV1_PaginatedSubscriptionResponse } from "../models/SopV1_PaginatedSubscriptionResponse";
import type { SopV1_PurchaseApplicationResponse } from "../models/SopV1_PurchaseApplicationResponse";
import type { SopV1_RegisterAnswersRequest } from "../models/SopV1_RegisterAnswersRequest";
import type { SopV1_RegisterBridgeLoanRequest } from "../models/SopV1_RegisterBridgeLoanRequest";
import type { SopV1_RegisterCollateralRequest } from "../models/SopV1_RegisterCollateralRequest";
import type { SopV1_RegisterComplementRequest } from "../models/SopV1_RegisterComplementRequest";
import type { SopV1_RegisterDepositLoanRequest } from "../models/SopV1_RegisterDepositLoanRequest";
import type { SopV1_RegisterHouseholdInformationRequest } from "../models/SopV1_RegisterHouseholdInformationRequest";
import type { SopV1_RegisterMortgagePartsRequest } from "../models/SopV1_RegisterMortgagePartsRequest";
import type { SopV1_RegisterMortgageRequest } from "../models/SopV1_RegisterMortgageRequest";
import type { SopV1_RegisterPropertySaleInformationRequest } from "../models/SopV1_RegisterPropertySaleInformationRequest";
import type { SopV1_RegisterPurchaseInformationRequest } from "../models/SopV1_RegisterPurchaseInformationRequest";
import type { SopV1_RegisterResolveLoansRequest } from "../models/SopV1_RegisterResolveLoansRequest";
import type { SopV1_RegisterSubscriptionRequest } from "../models/SopV1_RegisterSubscriptionRequest";
import type { SopV1_RemoveApplicantRequest } from "../models/SopV1_RemoveApplicantRequest";
import type { SopV1_RequestSignatureRequest } from "../models/SopV1_RequestSignatureRequest";
import type { SopV1_SignatureResponse } from "../models/SopV1_SignatureResponse";
import type { SopV1_SubscriptionResponse } from "../models/SopV1_SubscriptionResponse";
import type { SopV1_TopupApplicationResponse } from "../models/SopV1_TopupApplicationResponse";
import type { SopV1_TransferApplicationResponse } from "../models/SopV1_TransferApplicationResponse";
import type { SopV1_UpdateApplicantRequest } from "../models/SopV1_UpdateApplicantRequest";

export class SDerbergPartnersApiV1Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        SDerbergPartnersApiV1Service.url = url;
    }

    /**
     * @returns SopV1_AnswersResponse
     */
    public static async sopV1AnswerControllerRegister({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterAnswersRequest,
    }, ): Promise<SopV1_AnswersResponse> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/answers/$register-answers`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1AnswerControllerRegister (${"/v1-sop/applications/${applicationId}/answers/$register-answers"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_PaginatedAnswerResponse
     */
    public static async sopV1AnswerControllerList({
        applicationId,
        limit,
        offset,
    }: {
        applicationId: string,
        limit?: number,
        offset?: number,
    }, ): Promise<SopV1_PaginatedAnswerResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
        } ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/answers/$list-answers`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1AnswerControllerList (${"/v1-sop/applications/${applicationId}/answers/$list-answers"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerCreate({
        requestBody,
    }: {
        requestBody: (SopV1_CreateTransferApplicationRequest | SopV1_CreatePurchaseApplicationRequest | SopV1_CreateTopupApplicationRequest | SopV1_CreateCommitmentApplicationRequest),
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/$create-application`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerCreate (${"/v1-sop/applications/$create-application"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_PaginatedApplicationResponse
     */
    public static async sopV1ApplicationControllerList({
        limit,
        offset,
    }: {
        limit?: number,
        offset?: number,
    }, ): Promise<SopV1_PaginatedApplicationResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
        } ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/$list-applications`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerList (${"/v1-sop/applications/$list-applications"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerGetApplication({
        applicationId,
    }: {
        applicationId: string,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$get-application`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerGetApplication (${"/v1-sop/applications/${applicationId}/$get-application"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerAbortApplication({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_AbortApplicationRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$abort-application`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerAbortApplication (${"/v1-sop/applications/${applicationId}/$abort-application"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerAddApplicant({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_AddApplicantRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$add-applicant`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerAddApplicant (${"/v1-sop/applications/${applicationId}/$add-applicant"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerUpdateApplicant({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_UpdateApplicantRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$update-applicant`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerUpdateApplicant (${"/v1-sop/applications/${applicationId}/$update-applicant"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRemoveApplicant({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RemoveApplicantRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$remove-applicant`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRemoveApplicant (${"/v1-sop/applications/${applicationId}/$remove-applicant"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterCollateral({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterCollateralRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-collateral`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterCollateral (${"/v1-sop/applications/${applicationId}/$register-collateral"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterHouseholdInformation({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterHouseholdInformationRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-household-information`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterHouseholdInformation (${"/v1-sop/applications/${applicationId}/$register-household-information"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterMortgage({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterMortgageRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-mortgage`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterMortgage (${"/v1-sop/applications/${applicationId}/$register-mortgage"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterMortgageParts({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterMortgagePartsRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-mortgage-parts`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterMortgageParts (${"/v1-sop/applications/${applicationId}/$register-mortgage-parts"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterResolveLoans({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterResolveLoansRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-resolve-loans`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterResolveLoans (${"/v1-sop/applications/${applicationId}/$register-resolve-loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterDepositLoan({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterDepositLoanRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-deposit-loan`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterDepositLoan (${"/v1-sop/applications/${applicationId}/$register-deposit-loan"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterBridgeLoan({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterBridgeLoanRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-bridge-loan`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterBridgeLoan (${"/v1-sop/applications/${applicationId}/$register-bridge-loan"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterPurchaseInformation({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterPurchaseInformationRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-purchase-information`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterPurchaseInformation (${"/v1-sop/applications/${applicationId}/$register-purchase-information"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async sopV1ApplicationControllerRegisterPropertySaleInformation({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RegisterPropertySaleInformationRequest,
    }, ): Promise<(SopV1_CommitmentApplicationResponse | SopV1_TransferApplicationResponse | SopV1_TopupApplicationResponse | SopV1_PurchaseApplicationResponse)> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/$register-property-sale-information`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ApplicationControllerRegisterPropertySaleInformation (${"/v1-sop/applications/${applicationId}/$register-property-sale-information"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_PaginatedComplementResponse
     */
    public static async sopV1ComplementControllerList({
        applicationId,
        limit,
        offset,
    }: {
        applicationId: string,
        limit?: number,
        offset?: number,
    }, ): Promise<SopV1_PaginatedComplementResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
        } ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/complements/$list-complements`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ComplementControllerList (${"/v1-sop/applications/${applicationId}/complements/$list-complements"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_ComplementResponse
     */
    public static async sopV1ComplementControllerRegister({
        applicationId,
        complementId,
        requestBody,
    }: {
        applicationId: string,
        complementId: string,
        requestBody: SopV1_RegisterComplementRequest,
    }, ): Promise<SopV1_ComplementResponse> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/complements/${complementId}/$register-complement`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1ComplementControllerRegister (${"/v1-sop/applications/${applicationId}/complements/${complementId}/$register-complement"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_SignatureResponse
     */
    public static async sopV1SignatureControllerRequest({
        applicationId,
        requestBody,
    }: {
        applicationId: string,
        requestBody: SopV1_RequestSignatureRequest,
    }, ): Promise<SopV1_SignatureResponse> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/signatures/$request-signature`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1SignatureControllerRequest (${"/v1-sop/applications/${applicationId}/signatures/$request-signature"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_SignatureResponse
     */
    public static async sopV1SignatureControllerGet({
        applicationId,
        signatureId,
    }: {
        applicationId: string,
        signatureId: string,
    }, ): Promise<SopV1_SignatureResponse> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/applications/${applicationId}/signatures/${signatureId}/$get-signature`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1SignatureControllerGet (${"/v1-sop/applications/${applicationId}/signatures/${signatureId}/$get-signature"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_SubscriptionResponse
     */
    public static async sopV1SubscriptionControllerRegister({
        requestBody,
    }: {
        requestBody: SopV1_RegisterSubscriptionRequest,
    }, ): Promise<SopV1_SubscriptionResponse> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/subscriptions/$register-subscription`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1SubscriptionControllerRegister (${"/v1-sop/subscriptions/$register-subscription"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_PaginatedSubscriptionResponse
     */
    public static async sopV1SubscriptionControllerList({
        limit,
        offset,
    }: {
        limit?: number,
        offset?: number,
    }, ): Promise<SopV1_PaginatedSubscriptionResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
        } ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/subscriptions/$list-subscriptions`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1SubscriptionControllerList (${"/v1-sop/subscriptions/$list-subscriptions"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns SopV1_SubscriptionResponse
     */
    public static async sopV1SubscriptionControllerDeregister({
        subscriptionId,
    }: {
        subscriptionId: string,
    }, ): Promise<SopV1_SubscriptionResponse> {
        const query =  {} ;
        if (SDerbergPartnersApiV1Service.url === undefined) throw new Error("No url set for generated SDerbergPartnersApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SDerbergPartnersApiV1Service.url + `/v1-sop/subscriptions/${subscriptionId}/$remove-subscription`, query }), {
            method: "POST",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SDerbergPartnersApiV1Service.sopV1SubscriptionControllerDeregister (${"/v1-sop/subscriptions/${subscriptionId}/$remove-subscription"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}