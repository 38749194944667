import * as React from "react";

import NavigationState from "./NavigationState";

const NavigationContext = React.createContext<NavigationState>({
    url: () => void(0),
    params: undefined,
    history: undefined,
    location: undefined,
    loading: false,
});

export default NavigationContext;
