import { Box, Card, keyframes, styled, SxProps, Typography } from "@mui/material";
import { Amount, Percentage } from "@stabelo/awesome-money-library";
import { ConfigurationHelper } from "@stabelo/awesome-react-infrastructure-library";
import Lottie from "lottie-react";
import * as React from "react";

import { ComponentsV1_PriceResponse } from "../../../generated/iris/models/ComponentsV1_PriceResponse";
import { stabeloTheme } from "../../../theme-config";
import { MAXIMUM_MORTGAGE, MINIMUM_MORTGAGE } from "../../constants";
import * as housePercentageAnimation from "../../lottie/housePercentage.json";
import { ApplyButton } from "../buttons/ApplyButton";

import { calculateLTV } from "./calculators/ltv";
import { calculateMaxMortgageFromValuation } from "./calculators/morgage";
import { AmountSelector } from "./components/amountSelector/AmountSelector";
import { GreenMortgageSwitch } from "./components/greenMortgageSwitch/GreenMortgageSwitch";
import { IRateItem, RateTable } from "./components/rateTable/RateTable";
import { findRatesForLtvAndLoanSize, getRateFixationDictionary } from "./helpers/rate";
import { RateCalculatorApi } from "./rateCalculator.api";
import {
	DEFAULT_MORTGAGE,
	DEFAULT_VALUATION,
	MAXIMUM_VALUATION,
	MINIMUM_VALUATION,
	SLIDER_STEP,
	VALUATION_TEXTFIELD_DEBOUNCE_MS,
} from "./rateCalculator.constants";

interface ProductAmountDimension {
	loan_to_value_bps: number;
	price: ComponentsV1_PriceResponse;
	is_epc: boolean;
}

interface RateFixationDimension {
	product_amount_value: number;
	items: ProductAmountDimension[];
}

export interface IRateDictionary {
	[key: string]: RateFixationDimension[];
}

const SectionHeader = styled(Typography)(() => ({
	fontFamily: "EditorialNew",
	fontSize: "40px",
	fontWeight: 400,
	lineHeight: "40px",
	letterSpacing: "0em",
	margin: "20px 0 48px 0",
	[stabeloTheme.breakpoints.down("md")]: {
		marginBottom: "48px",
	},
	[stabeloTheme.breakpoints.down("sm")]: {
		marginBottom: "16px",
	},
}));

const moveCircleKeyframe = keyframes`
	  0% {
	    transform: translate(0, 0);
	  }
	  100% {
	    transform: translate(20px, 40px);
	  }
	`;

interface IProps {
	clientId?: string;
	epcApplied?: boolean;
	epcLocked?: boolean;
	squished?: boolean;
	showApplyButton?: boolean;
	showEffectiveRate?: boolean;
	showTitleHeading?: boolean;
	redirectUrl?: string;
	sx?: SxProps;
}

export const existsRateDifference = (rateDictionary: IRateDictionary) => {
	for (const fixationKey of Object.keys(rateDictionary)) {
		let bpss = undefined;
		for (const d1 of rateDictionary[fixationKey]) {
			for (const d2 of d1.items) {
				if (bpss === undefined) {
					bpss = {
						nominal: d2.price.nominal.bps,
						effective: d2.price.effective.bps,
					};
				} else {
					if (bpss.nominal !== d2.price.nominal.bps || bpss.effective !== d2.price.effective.bps) {
						return true;
					}
				}
			}
		}
	}
	return false;
};

export const RateCalculator = ({
	clientId,
	epcApplied,
	epcLocked,
	squished,
	showApplyButton,
	showEffectiveRate,
	showTitleHeading,
	redirectUrl,
	sx,
}: IProps) => {
	const [api] = React.useState(RateCalculatorApi());
	const [valuation, setValuation] = React.useState<number>(DEFAULT_VALUATION);
	const [mortgage, setMortgage] = React.useState<number>(DEFAULT_MORTGAGE);
	const [ltvLimit, setLtvLimit] = React.useState<number>();
	const [maxMortgage, setMaxMortgage] = React.useState<number>(MAXIMUM_MORTGAGE);
	const [ltv, setLtv] = React.useState<number>(0);
	const [rateFixationDictionary, setRateFixationDictonary] = React.useState<IRateDictionary>();
	const [epcRateFixationDictionary, setEpcRateFixationDictonary] = React.useState<IRateDictionary>();
	const [nonEpcrateFixationDictionary, setNonEpcRateFixationDictonary] = React.useState<IRateDictionary>();
	const [rateItems, setRateItems] = React.useState<IRateItem[]>([]);
	const [useEnergyPerformanceCeritificate, setUseEnergyPerformanceCeritificate] = React.useState(epcApplied ?? false);
	const [showSliders, setShowSliders] = React.useState(false);

	React.useEffect(() => {
		const fetch = async () => {
			const applicationConfiguration = await api.getApplicationConfiguration();
			setLtvLimit(Percentage.fromJSON(applicationConfiguration.ltv_limit).toPercentage());
		};

		void fetch();
	}, [api]);

	React.useEffect(() => {
		const fetch = async () => {
			const rateTablesResponse = await api.getRateTables(clientId);
			const nonEpcRateDictionary = getRateFixationDictionary(rateTablesResponse.mortgage_overview);
			const epcRateDictionary = getRateFixationDictionary(rateTablesResponse.green_mortgage_overview);
			setShowSliders(existsRateDifference(epcRateDictionary) || existsRateDifference(nonEpcRateDictionary));
			setNonEpcRateFixationDictonary(nonEpcRateDictionary);
			setEpcRateFixationDictonary(epcRateDictionary);
		};

		void fetch();
	}, [api, clientId]);

	React.useEffect(() => {
		if (useEnergyPerformanceCeritificate) {
			setRateFixationDictonary(epcRateFixationDictionary);
		} else {
			setRateFixationDictonary(nonEpcrateFixationDictionary);
		}
	}, [epcRateFixationDictionary, nonEpcrateFixationDictionary, useEnergyPerformanceCeritificate]);

	React.useEffect(() => {
		if (Number.isNaN(valuation) || Number.isNaN(mortgage)) {
			setLtv(Number.NaN);
			return;
		}

		setLtv(calculateLTV(Amount.fromDecimal(mortgage, "SEK"), Amount.fromDecimal(valuation, "SEK")));
	}, [valuation, mortgage]);

	React.useEffect(() => {
		if (ltvLimit && ltv <= ltvLimit && Number.isInteger(mortgage) && rateFixationDictionary) {
			const rates = findRatesForLtvAndLoanSize(ltv, mortgage, rateFixationDictionary);
			setRateItems(rates);
		}
	}, [ltv, mortgage, ltvLimit, rateFixationDictionary]);

	React.useEffect(() => {
		if (ltvLimit) {
			setMaxMortgage(calculateMaxMortgageFromValuation(valuation, ltvLimit));
		}
	}, [valuation, ltvLimit]);

	React.useEffect(() => {
		if (Number.isNaN(maxMortgage) || maxMortgage < mortgage) {
			setMortgage(maxMortgage);
		}
	}, [maxMortgage, mortgage]);

	const handleValuationChange = (value: number) => {
		setValuation(value);
	};

	const handleMortgageChange = (value: number) => {
		setMortgage(value);
	};

	const handleEnergyPerformanceCertificateChange = (checked: boolean) => {
		setUseEnergyPerformanceCeritificate(checked);
	};

	const cardDefaultStyle = {
		padding: 0,
		display: showSliders ? "grid" : "inline",
		gridTemplateColumns: "1fr 1fr",
		width: showSliders ? "1080px" : "540px",
		userSelect: "none",
		boxShadow: 6,
		[stabeloTheme.breakpoints.down("sm")]: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			borderRadius: 0,
			boxShadow: "none",
		},
	};

	const cardSquishedStyle = {
		padding: 0,
		display: "flex",
		flexDirection: "column",
		userSelect: "none",
		width: "100%",
		boxShadow: "none",
		borderRadius: 0,
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				padding: "16px 5.56em;",
				[stabeloTheme.breakpoints.down("md")]: {
					padding: "16px 5.21em",
				},
				[stabeloTheme.breakpoints.down("sm")]: {
					padding: 0,
				},
				...sx,
			}}
		>
			<Card sx={squished === true ? cardSquishedStyle : cardDefaultStyle}>
				{showSliders && (
					<Box
						key={"input-wrapper-box"}
						sx={{
							display: "flex",
							flexDirection: "column",
							paddingTop: 6,
							paddingX: 8,
							[stabeloTheme.breakpoints.down("md")]: {
								paddingTop: 4,
								paddingX: 5,
							},
							[stabeloTheme.breakpoints.down("sm")]: {
								paddingTop: 3,
								paddingX: "5em",
								background: stabeloTheme.palette.info.light,
							},
						}}
					>
						<SectionHeader variant="h2">Vad får jag för ränta?</SectionHeader>

						<Box key={"input-box"} sx={{ display: "flex", flexDirection: "column", rowGap: 1.5 }}>
							<AmountSelector
								key={"valuation-input"}
								label={"Bostadens värde"}
								value={valuation}
								max={MAXIMUM_VALUATION}
								min={MINIMUM_VALUATION}
								textFieldDebounceMS={VALUATION_TEXTFIELD_DEBOUNCE_MS}
								sliderStep={SLIDER_STEP}
								onChange={handleValuationChange}
							/>

							<AmountSelector
								key={"amount-input"}
								label={"Lånebelopp"}
								value={mortgage}
								max={MAXIMUM_MORTGAGE}
								min={MINIMUM_MORTGAGE}
								sliderStep={SLIDER_STEP}
								error={Number.isNaN(mortgage) || mortgage < MINIMUM_MORTGAGE}
								helperText={`Minsta bolånebelopp är ${MINIMUM_MORTGAGE.toLocaleString().replace(",", " ")} kr`}
								onChange={handleMortgageChange}
							/>
						</Box>

						<Typography
							sx={{
								marginTop: 5,
								[stabeloTheme.breakpoints.down("sm")]: {
									display: "none",
								},
							}}
						>{`Belåningsgrad: ${Number.isNaN(ltv) ? 0 : ltv}%`}</Typography>

						<Box
							key={"animation-box"}
							sx={{
								[stabeloTheme.breakpoints.down("sm")]: {
									display: "none",
								},
							}}
						>
							<Lottie
								loop={false}
								autoplay={true}
								animationData={housePercentageAnimation}
								rendererSettings={{
									preserveAspectRatio: "xMidYMid slice",
								}}
								height={250}
								width={250}
							/>
						</Box>
					</Box>
				)}

				<Box
					key={"rate-wrapper-box"}
					sx={{
						display: "flex",
						position: "relative",
						background: stabeloTheme.palette.info.light,
						paddingY: 6,
						paddingX: 8,
						boxShadow: "10px 0 15px 15px #e1e1e1",
						[stabeloTheme.breakpoints.down("md")]: {
							paddingY: 4,
							paddingX: 5,
						},
						[stabeloTheme.breakpoints.down("sm")]: {
							paddingY: 3,
							paddingX: "5em",
							boxShadow: "none",
							paddingTop: 1,
						},
					}}
				>
					<Box
						key={"rate-inner-wrapper-box"}
						sx={{
							display: "flex",
							flexDirection: "column",
							flex: 1,
							position: "relative",
							zIndex: 2,
							paddingTop: 0,
						}}
					>
						{showTitleHeading !== false ? (
							<SectionHeader
								variant="h2"
								sx={{
									[stabeloTheme.breakpoints.down("sm")]: {
										display: "none",
									},
								}}
							>
								Din ränta
							</SectionHeader>
						) : null}

						<Box sx={{ margin: "auto 0" }}>
							<RateTable
								rateItems={rateItems}
								sx={{ paddingTop: 0 }}
								loading={nonEpcrateFixationDictionary === undefined}
								showEffectiveRate={showEffectiveRate === undefined ? false : showEffectiveRate}
							/>

							<GreenMortgageSwitch
								value={useEnergyPerformanceCeritificate}
								isLocked={epcLocked}
								onChange={handleEnergyPerformanceCertificateChange}
								sx={{ marginBottom: 4, marginTop: 2 }}
							/>
						</Box>

						{showApplyButton && (
							<ApplyButton
								onClick={() => {
									window.parent.location =
										redirectUrl !== undefined
											? redirectUrl
											: `${ConfigurationHelper.get<string>("stabelo.url")}/valj-lan`;
								}}
								sx={{ marginTop: "auto" }}
							>
								Skaffa nytt eller flytta bolån
							</ApplyButton>
						)}
					</Box>

					{(showSliders || !showEffectiveRate) && (
						<Box
							key={"position-box"}
							sx={{
								position: "absolute",
								top: 0,
								right: "-25em",
								width: "43.62em",
								height: "43.62em",
								borderRadius: "100vw",
								backgroundColor: stabeloTheme.palette.warning.main,
								animation: `${moveCircleKeyframe}`,
								animationDuration: "4s",
								animationIterationCount: 2,
								animationDirection: "alternate",
								[stabeloTheme.breakpoints.down("xl")]: {
									top: 0,
									right: "-380px",
									width: "690px",
									height: "690px",
								},
								[stabeloTheme.breakpoints.down("lg")]: {
									top: 0,
									right: "-37em",
									width: "65em",
									height: "65em",
								},
								[stabeloTheme.breakpoints.down("sm")]: {
									top: 0,
									right: "-320px",
									width: "590px",
									height: "590px",
								},
								[stabeloTheme.breakpoints.down("xs")]: {
									top: 0,
									right: "-70em",
									width: "125em",
									height: "125em",
								},
							}}
						></Box>
					)}
				</Box>
			</Card>
		</Box>
	);
};
