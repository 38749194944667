import * as React from "react";

import SessionContext from "./SessionContext";
import SessionHandler from "./SessionHandler";
import SessionState from "./SessionState";

interface IProperties {
    handler: SessionHandler;
    children: React.ReactNode;
}

// tslint:disable-next-line:no-empty-interface
interface IState extends SessionState {
    // Nothing to add
}

class SessionProvider extends React.Component<IProperties, IState> {
    constructor(props: IProperties) {
        super(props);

        const existingSession = props.handler.currentSession;

        if (!!existingSession) {
            this.state = {
                authenticated: true,
                destroy: () => existingSession.destroy(),
                token: existingSession.token,
            };
        } else {
            this.state = {
                authenticated: false,
                destroy: () => void (0),
                token: {},
            };
        }

        props.handler.onUpdate((session) => {
            if (!!session) {
                this.setState({
                    authenticated: true,
                    destroy: () => session.destroy(),
                    token: session.token,
                });
            } else {
                this.setState({
                    authenticated: false,
                    destroy: () => void (0),
                    token: {},
                });
            }
        });
    }

    public render() {
        const {
            children,
        } = this.props;

        return (
            <SessionContext.Provider value={this.state}>{children}</SessionContext.Provider>
        );
    }
}

export default SessionProvider;
