import { Typography, useTheme } from "@mui/material";
import * as React from "react";

import { LanaPengarV1_CreatePurchaseApplicationRequest } from "../../../generated/iris";
import { MAXIMUM_LTV, MAXIMUM_MORTGAGE, MINIMUM_MORTGAGE } from "../../constants";
import { NumberTextField } from "../maskedInputs/NumberTextField";

import { decimalToSerializedAmount } from "./helpers/decimalToSerializedAmount";
import { displaySekAmount } from "./helpers/displaySekAmount";
import { numberTextFieldErrorStyle, numberTextFieldStyle } from "./numberTextFieldStyle";
import { TabPanelForm } from "./TabPanelForm";

const validatePurchaseApplicationMortgage = (propertyPrice: number | undefined, mortgage: number | undefined) => {
	if (mortgage === undefined) return [];

	const errors = [];
	if (mortgage < MINIMUM_MORTGAGE || mortgage > MAXIMUM_MORTGAGE) {
		errors.push(
			`Lånebelopp måste vara minst ${displaySekAmount(MINIMUM_MORTGAGE)} och får inte överskrida ${displaySekAmount(MAXIMUM_MORTGAGE)}.`,
		);
	}
	if (propertyPrice !== undefined && mortgage > propertyPrice * MAXIMUM_LTV) {
		errors.push(`Belåningsgraden får inte överstiga 60%.`);
	}
	return errors;
};

export const PurchaseApplicationForm = ({
	id,
	labelledBy,
	hidden,
	loading,
	onSubmit,
}: {
	id: string;
	labelledBy: string;
	hidden: boolean;
	loading: boolean;
	onSubmit: (request: LanaPengarV1_CreatePurchaseApplicationRequest) => Promise<void>;
}) => {
	const theme = useTheme();
	const [propertyPrice, setPropertyPrice] = React.useState<number | undefined>(undefined);
	const [downPaymentAmount, setDownPaymentAmount] = React.useState<number | undefined>(undefined);
	const [showError, setShowError] = React.useState(false);

	const mortgage =
		propertyPrice && downPaymentAmount && downPaymentAmount < propertyPrice ? propertyPrice - downPaymentAmount : 0;
	const mortgageErrors = validatePurchaseApplicationMortgage(propertyPrice, mortgage);

	const submit = () => {
		if (mortgageErrors.length > 0) {
			setShowError(true);
			return;
		}

		void onSubmit({
			type: "PURCHASE",
			mortgage_amount: decimalToSerializedAmount(0),
		});
	};

	return (
		<TabPanelForm id={id} labelledBy={labelledBy} hidden={hidden} loading={loading} onSubmit={submit}>
			<Typography id="property-price-amount-label">Bostadens pris</Typography>
			<NumberTextField
				aria-labelledby="property-price-amount-label"
				hiddenLabel
				fullWidth
				disabled={loading}
				value={propertyPrice?.toString() ?? ""}
				suffix={"kr"}
				min={0}
				onChange={(value) => setPropertyPrice(value === "" ? undefined : parseInt(value))}
				sx={showError && propertyPrice === undefined ? numberTextFieldErrorStyle(theme) : numberTextFieldStyle}
			/>

			<Typography id="down-payment-amount-label">Kontantinsatsens storlek</Typography>
			<NumberTextField
				aria-labelledby="down-payment-amount-label"
				hiddenLabel
				fullWidth
				disabled={loading}
				value={downPaymentAmount?.toString() ?? ""}
				suffix={"kr"}
				min={0}
				onChange={(value) => setDownPaymentAmount(value === "" ? undefined : parseInt(value))}
				sx={showError && downPaymentAmount === undefined ? numberTextFieldErrorStyle(theme) : numberTextFieldStyle}
			/>

			<Typography id="mortgage-amount-label">Lånebelopp att söka</Typography>
			<NumberTextField
				aria-labelledby="mortgage-amount-label"
				hiddenLabel
				fullWidth
				disabled={true}
				value={mortgage?.toString() ?? ""}
				suffix={"kr"}
				error={showError && mortgageErrors.length > 0}
				helperText={showError && mortgageErrors.length > 0 ? mortgageErrors.join(" ") : undefined}
			/>
		</TabPanelForm>
	);
};
