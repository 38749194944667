import { createBrowserHistory, Location } from "history";
import { parse, stringify } from "qs";
import * as React from "react";
import UniversalRouter, { Context, Options, Route } from "universal-router";
import generateUrls, { Params } from "universal-router/generateUrls";

import ComponentTarget from "./ComponentTarget";
import NavigationProvider from "./NavigationProvider";
import NavigationState from "./NavigationState";
import NavigationTarget from "./NavigationTarget";
import RedirectTarget from "./RedirectTarget";
import ITarget from "./Target";
import ITargetWrapper from "./TargetWrapper";

interface IPoint {
    x: number;
    y: number;
}

interface IRenderFunctions {
    render: (component: React.ReactElement<any>) => Promise<void>;
    hydrate: (component: React.ReactElement<any>) => Promise<void>;
}

type IAuthorizeFunction = (path: string, target: ITarget) => Promise<ITarget>;
type IChangeCallback = (state: NavigationState) => void;

class Router extends UniversalRouter<Context, ITargetWrapper> {

    public state: NavigationState;

    private initialized: boolean;
    private scroll: Map<string, IPoint>;
    private readonly generator: (route: string, params: Params) => string;
    private readonly authorize: IAuthorizeFunction;
    private readonly changeCallbacks: IChangeCallback[];
    private readonly renderFunctions: IRenderFunctions;

    constructor(routes: Route[], options: Options, renderFunctions: IRenderFunctions, authorize?: IAuthorizeFunction) {
        super(routes, options);

        this.state = {
            url: (route, params, opts) => this.generateUrl(route, params, opts),
            params: undefined,
            history: createBrowserHistory(),
            location: undefined,
            loading: false,
        };

        this.initialized = false;
        this.scroll = new Map();
        this.generator = generateUrls(this, { stringifyQueryParams: stringify });
        this.authorize = authorize;
        this.changeCallbacks = [];
        this.renderFunctions = renderFunctions;

        this.state.history.listen((location, action) => this.locationChanged(location, action));
    }

    public async initialize() {
        return this.locationChanged(this.state.history.location, "INIT").then(() => this);
    }

    public onStateChange(callback: IChangeCallback) {
        this.changeCallbacks.push(callback);
    }

    private async locationChanged(location: Location, action: string = "PUSH") {
        // console.log("CHANGE", this);
        if (this.state.location != null) {
            this.scroll[this.state.location.key] = {
                x: window.pageXOffset,
                y: window.pageYOffset,
            };
        }
        if (action === "PUSH") {
            delete this.scroll[location.key];
        }

        this.setState({ location, loading: true });

        try {
            const wrapper = await this.resolve({
                pathname: location.pathname,
                query: parse(location.search),
            });

            let target = wrapper.target;

            if (this.authorize !== undefined) {
                target = await this.authorize(location.pathname + location.search + location.hash, target);
            }

            if (target instanceof NavigationTarget) {
                const route = (target as NavigationTarget);
                // console.log("NAVIGATE", route.href);
                return window.location.href = route.href;
            } else if (target instanceof RedirectTarget) {
                const route = (target as RedirectTarget);
                // console.log("REDIR", route.href);
                return this.state.history.replace(route.href);
            } else if (target instanceof ComponentTarget) {
                const route = (target as ComponentTarget);
                const component = React.createElement(NavigationProvider, { router: this, children: route.component });
                // console.log("RENDER", route.title);
                await this.render(component);
                // console.log("RENDERED");

                document.title = route.title;
            }

            this.setState({ loading: false, params: wrapper.params });

            if (window.history && "scrollRestoration" in window.history) {
                window.history.scrollRestoration = "manual";
            }

            if (this.scroll[location.key] != null) {
                window.scrollTo(this.scroll[location.key].x, this.scroll[location.key].y);
            } else {
                window.scrollTo(0, 0);
            }
        } catch (exception) {
            // tslint:disable-next-line:no-console
            console.error(exception);
            this.setState({ loading: false });
        }
    }

    private setState(nextState: Partial<NavigationState>) {
        this.state = { ...this.state, ...nextState };

        this.changeCallbacks.forEach((callback) => callback(this.state));
    }

    private render(component: React.ReactElement<any>) {
        return (this.initialized === true ? this.refreshComponent(component) : this.attachComponent(component));
    }

    private attachComponent(component: React.ReactElement<any>) {
        this.initialized = true;
        return this.renderFunctions.render(component);
    }

    private refreshComponent(component: React.ReactElement<any>) {
        return this.renderFunctions.hydrate(component);
    }

    private generateUrl(name: string, params?: Params, options?: { absolute: boolean }) {
        const path = this.generator(name, params);

        if (options === undefined || options.absolute !== true) {
            return path;
        }

        const protocol = window.location.protocol;
        const host = window.location.host;

        return `${protocol}//${host}${path}`;
    }
}

export default Router;
