import * as React from "react";

import NavigationContext from "./NavigationContext";
import NavigationState from "./NavigationState";

interface IProperties {
    children: (value: NavigationState) => React.ReactNode;
}

class NavigationConsumer extends React.Component<IProperties> {
    public render() {
        const {
            children,
        } = this.props;

        return (
            <NavigationContext.Consumer>{children}</NavigationContext.Consumer>
        );
    }
}

export default NavigationConsumer;
