import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import * as React from "react";

import {
	LanaPengarApiV1Service,
	LanaPengarV1_BankResponse,
	LanaPengarV1_CreateCommitmentApplicationRequest,
	LanaPengarV1_CreatePurchaseApplicationRequest,
	LanaPengarV1_CreateTransferApplicationRequest,
} from "../../../generated/iris";
import { useBanks } from "../../hooks/useBanks";

import { CommitmentApplicationForm } from "./CommitmentApplicationForm";
import { PurchaseApplicationForm } from "./PurchaseApplicationForm";
import { TransferApplicationForm } from "./TransferApplicationForm";

const applicationTypes = ["TRANSFER", "PURCHASE", "COMMITMENT"] as const;
type ApplicationType = (typeof applicationTypes)[number];

const applicationTypeText = (type: ApplicationType) => {
	switch (type) {
		case "TRANSFER":
			return "Flytta eller utöka bolån";
		case "PURCHASE":
			return "Nytt lån";
		case "COMMITMENT":
			return "Lånelöfte";
	}

	return type;
};

const applicationToggleStyle = {
	marginBottom: "1rem",
};

const tabButtonStyle = {
	fontSize: "0.8rem",
};

const notSelectedTabButtonStyle = {
	...tabButtonStyle,
};

const selectedTabButtonStyle = (color: string, backgroundColor: string) => ({
	...tabButtonStyle,

	borderColor: `${color} !important`,
	color: `${color} !important`,

	backgroundColor: `${backgroundColor} !important`,

	borderWidth: "2px",
	borderStyle: "solid",
});

const notLastTabButtonStyle = {
	borderRightWidth: "2px",
};

const notFirstTabButtonStyle = {
	borderLeftWidth: "2px !important",
};

type CreateApplicationRequest =
	| LanaPengarV1_CreateTransferApplicationRequest
	| LanaPengarV1_CreatePurchaseApplicationRequest
	| LanaPengarV1_CreateCommitmentApplicationRequest;

export const BolaneguidenApplicationForm: React.FC = () => {
	const theme = useTheme();
	const [banks] = useBanks();
	const [loading, setLoading] = React.useState(false);
	const [tabIndex, setTabIndex] = React.useState(0);
	const tabRefs = React.useRef<(HTMLButtonElement | null)[]>([]);

	const onSubmit = async (requestBody: CreateApplicationRequest) => {
		setLoading(true);
		const result = await LanaPengarApiV1Service.lanaPengarV1ControllerCreateApplication({
			requestBody,
		});

		if (window.parent === window) {
			window.location.assign(result.redirect_url);
		} else {
			window.parent.postMessage({ type: "REDIRECT_REQUEST", redirect_url: result.redirect_url }, window.parent.origin);
		}
	};

	// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role
	const tabListOnKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
			const newIndex =
				event.key === "ArrowRight"
					? tabIndex === applicationTypes.length - 1
						? 0
						: tabIndex + 1
					: tabIndex === 0
						? applicationTypes.length - 1
						: tabIndex - 1;
			setTabIndex(newIndex);
			tabRefs.current[newIndex]?.focus();
		}
	};

	return (
		<>
			<ToggleButtonGroup
				tabIndex={-1}
				role="tablist"
				aria-label="Ansökningstyp"
				exclusive
				fullWidth
				value={tabIndex}
				onKeyDown={tabListOnKeyDown}
				onChange={(_, value: number) => {
					if (value === null) {
						return;
					}
					setTabIndex(value);
				}}
				sx={applicationToggleStyle}
			>
				{applicationTypes.map((applicationType, index) => {
					const selected = index === tabIndex;
					return (
						<ToggleButton
							ref={(e) => (tabRefs.current[index] = e)}
							role="tab"
							aria-selected={selected}
							aria-controls={`tabpanel-${index}`}
							id={`tab-${index}`}
							tabIndex={selected ? 0 : -1}
							selected={selected}
							key={index}
							disabled={loading}
							value={index}
							sx={
								!selected
									? notSelectedTabButtonStyle
									: {
											...selectedTabButtonStyle(theme.palette.secondary.main, theme.palette.secondary.light),
											...(index !== 0 ? notFirstTabButtonStyle : {}),
											...(index !== applicationTypes.length - 1 ? notLastTabButtonStyle : {}),
										}
							}
						>
							{applicationTypeText(applicationType)}
						</ToggleButton>
					);
				})}
			</ToggleButtonGroup>
			{applicationTypes.map((applicationType, index) => (
				<ApplicationForm
					key={index}
					applicationType={applicationType}
					index={index}
					hidden={index !== tabIndex}
					loading={loading}
					onSubmit={onSubmit}
					banks={banks}
				/>
			))}
		</>
	);
};

const ApplicationForm = ({
	applicationType,
	index,
	hidden,
	loading,
	onSubmit,
	banks,
}: {
	applicationType: ApplicationType;
	index: number;
	hidden: boolean;
	loading: boolean;
	onSubmit: (request: CreateApplicationRequest) => Promise<void>;
	banks: LanaPengarV1_BankResponse[] | undefined;
}) => {
	switch (applicationType) {
		case "TRANSFER":
			return (
				<TransferApplicationForm
					id={`panel-${index}`}
					labelledBy={`tab-${index}`}
					hidden={hidden}
					loading={loading}
					onSubmit={onSubmit}
					banks={banks}
				/>
			);
		case "PURCHASE":
			return (
				<PurchaseApplicationForm
					id={`panel-${index}`}
					labelledBy={`tab-${index}`}
					hidden={hidden}
					loading={loading}
					onSubmit={onSubmit}
				/>
			);
		case "COMMITMENT":
			return (
				<CommitmentApplicationForm
					id={`panel-${index}`}
					labelledBy={`tab-${index}`}
					hidden={hidden}
					loading={loading}
					onSubmit={onSubmit}
				/>
			);
	}
};
