/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { ComponentsV1_ConfigurationResponse } from "../models/ComponentsV1_ConfigurationResponse";
import type { ComponentsV1_RateTablesResponse } from "../models/ComponentsV1_RateTablesResponse";

export class ComponentsApiV1Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        ComponentsApiV1Service.url = url;
    }

    /**
     * @returns ComponentsV1_ConfigurationResponse
     */
    public static async componentsV1ConfigurationControllerGet(): Promise<ComponentsV1_ConfigurationResponse> {
        const query =  {} ;
        if (ComponentsApiV1Service.url === undefined) throw new Error("No url set for generated ComponentsApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": ComponentsApiV1Service.url + `/v1c/configuration`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `ComponentsApiV1Service.componentsV1ConfigurationControllerGet (${"/v1c/configuration"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns ComponentsV1_RateTablesResponse
     */
    public static async componentsV1RateControllerGetRateTables({
        productType,
        clientId,
    }: {
        productType: 'MORTGAGE' | 'BRIDGE_LOAN' | 'DEPOSIT_LOAN',
        clientId?: string,
    }, ): Promise<ComponentsV1_RateTablesResponse> {
        const query =  {
            "client_id": clientId,
        } ;
        if (ComponentsApiV1Service.url === undefined) throw new Error("No url set for generated ComponentsApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": ComponentsApiV1Service.url + `/v1c/rates/${productType}/tables`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `ComponentsApiV1Service.componentsV1RateControllerGetRateTables (${"/v1c/rates/${productType}/tables"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}