import { InfoOutlined } from "@mui/icons-material";
import { Box, Popover, Switch, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import * as React from "react";

interface IProps {
	value: boolean;
	isLocked?: boolean;
	sx?: SxProps;
	onChange: (value: boolean) => void;
}

export const GreenMortgageSwitch = ({ value, isLocked, sx, onChange }: IProps) => {
	const [greenMortgagePopoverAnchorElement, setGreenMortgagePopoverAnchorElement] = React.useState<Element | null>(
		null,
	);
	const [openGreenMortgagePopover, setOpenGreenMortgagePopover] = React.useState(false);

	const handleGreenMortgageInfoClick = (event: React.SyntheticEvent) => {
		setGreenMortgagePopoverAnchorElement(event.currentTarget);
		setOpenGreenMortgagePopover(true);
	};

	const handleGreenMortgagePopoverClose = () => {
		setGreenMortgagePopoverAnchorElement(null);
		setOpenGreenMortgagePopover(false);
	};

	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		onChange(checked);
	};

	return (
		<Box sx={{ display: "flex", alignItems: "center", columnGap: 1, ...sx }}>
			<Switch checked={value} onChange={handleSwitchChange} color={"success"} disabled={isLocked} />
			<Typography>Bostaden ger rätt till grönt bolån</Typography>
			<InfoOutlined onClick={handleGreenMortgageInfoClick} sx={{ cursor: "pointer" }} />
			<Popover
				open={openGreenMortgagePopover}
				anchorEl={greenMortgagePopoverAnchorElement}
				onClose={handleGreenMortgagePopoverClose}
				anchorOrigin={{
					vertical: -10,
					horizontal: 0,
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						rowGap: 2,
						maxHeight: 510,
						minWidth: 250,
						maxWidth: 280,
						padding: 2,
						userSelect: "none",
					}}
				>
					<Typography variant="h6">Grönt bolån</Typography>
					<Typography>
						Har du en energideklaration som utfärdats 2014 eller senare med energiklass A eller B får du ett så kallat
						Grönt bolån hos oss. Det ger dig 0,10 procentenheters rabatt på ditt bolån. Det kollar vi åt dig i samband
						med att du söker ett lån hos oss, så du behöver inte göra något själv.
					</Typography>
				</Box>
			</Popover>
		</Box>
	);
};
