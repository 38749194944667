/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { SppV1_PaginatedApplicationResponse } from "../models/SppV1_PaginatedApplicationResponse";

export class SppApiV1Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        SppApiV1Service.url = url;
    }

    /**
     * @returns SppV1_PaginatedApplicationResponse
     */
    public static async sppV1ControllerListApplications({
        limit,
        offset,
    }: {
        limit?: number,
        offset?: number,
    }, ): Promise<SppV1_PaginatedApplicationResponse> {
        const query =  {
            "limit": limit,
            "offset": offset,
        } ;
        if (SppApiV1Service.url === undefined) throw new Error("No url set for generated SppApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": SppApiV1Service.url + `/v1-spp/$list-applications`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `SppApiV1Service.sppV1ControllerListApplications (${"/v1-spp/$list-applications"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}