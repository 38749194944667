/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { TaskResponse } from "../models/TaskResponse";

export class LiftoffIrisService {
    private static url: string | undefined;

    public static setUrl(url: string) {
        LiftoffIrisService.url = url;
    }

    /**
     * @returns TaskResponse A list of all tasks defined in the service.
     */
    public static async taskControllerList(): Promise<Array<TaskResponse>> {
        const query =  {} ;
        if (LiftoffIrisService.url === undefined) throw new Error("No url set for generated LiftoffIrisService.");
        return HttpInterface.fetch(stringifyUrl({ "url": LiftoffIrisService.url + `/v1/tasks`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `LiftoffIrisService.taskControllerList (${"/v1/tasks"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns TaskResponse The requested task.
     */
    public static async taskControllerGet({
        name,
    }: {
        name: string,
    }, ): Promise<TaskResponse> {
        const query =  {} ;
        if (LiftoffIrisService.url === undefined) throw new Error("No url set for generated LiftoffIrisService.");
        return HttpInterface.fetch(stringifyUrl({ "url": LiftoffIrisService.url + `/v1/tasks/${name}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `LiftoffIrisService.taskControllerGet (${"/v1/tasks/${name}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns string The execution result.
     */
    public static async taskControllerStart({
        name,
    }: {
        name: string,
    }, ): Promise<'SUCCESSFUL' | 'FAILED' | 'IGNORED'> {
        const query =  {} ;
        if (LiftoffIrisService.url === undefined) throw new Error("No url set for generated LiftoffIrisService.");
        return HttpInterface.fetch(stringifyUrl({ "url": LiftoffIrisService.url + `/v1/tasks/${name}/executions`, query }), {
            method: "POST",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `LiftoffIrisService.taskControllerStart (${"/v1/tasks/${name}/executions"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}