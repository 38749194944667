import { QueryParams, ResolveContext, RouteContext } from "universal-router";
import ITarget from "./Target";
import ITargetWrapper from "./TargetWrapper";

type TTarget = ITarget | ((params?: QueryParams) => ITarget) | ((params?: QueryParams) => Promise<ITarget>);

function RouteAction(arg: TTarget): (context: RouteContext<ResolveContext>) => Promise<ITargetWrapper> {
    const action = typeof(arg) !== "function" ? () => arg : arg;

    return (context: RouteContext<ResolveContext>) => {
        const query = context.query as object;
        const params = {...context.params, ...Object.keys(query).reduce((cleaned: object, name: string) => ({...cleaned, ...(name.startsWith("?") ? { [name.substr(1)]: query[name] } : { [name]: query[name] })}), {})};

        return Promise.resolve(action(params)).then((target: ITarget) => ({
            target,
            params,
        }));
    };
}

export default RouteAction;
