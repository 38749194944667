/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { AvanzaV1_AddressSearchPageResponse } from "../models/AvanzaV1_AddressSearchPageResponse";
import type { AvanzaV1_ApplicantKycUpdateRequest } from "../models/AvanzaV1_ApplicantKycUpdateRequest";
import type { AvanzaV1_ApplicantSubmitRequest } from "../models/AvanzaV1_ApplicantSubmitRequest";
import type { AvanzaV1_ApplicantUpdatedResponse } from "../models/AvanzaV1_ApplicantUpdatedResponse";
import type { AvanzaV1_ApplicantUpdateRequest } from "../models/AvanzaV1_ApplicantUpdateRequest";
import type { AvanzaV1_ApplicationCreateRequest } from "../models/AvanzaV1_ApplicationCreateRequest";
import type { AvanzaV1_ApplicationCreateResponse } from "../models/AvanzaV1_ApplicationCreateResponse";
import type { AvanzaV1_ApplicationResponse } from "../models/AvanzaV1_ApplicationResponse";
import type { AvanzaV1_ApplicationsCurrentLoansUpdateRequest } from "../models/AvanzaV1_ApplicationsCurrentLoansUpdateRequest";
import type { AvanzaV1_ApplicationsLoansUpdateRequest } from "../models/AvanzaV1_ApplicationsLoansUpdateRequest";
import type { AvanzaV1_ApplicationsLoansUpdateResponse } from "../models/AvanzaV1_ApplicationsLoansUpdateResponse";
import type { AvanzaV1_ApplicationsResponse } from "../models/AvanzaV1_ApplicationsResponse";
import type { AvanzaV1_ApplicationUpdateRequest } from "../models/AvanzaV1_ApplicationUpdateRequest";
import type { AvanzaV1_BankIdSessionResponse } from "../models/AvanzaV1_BankIdSessionResponse";
import type { AvanzaV1_CdaUpdateRequest } from "../models/AvanzaV1_CdaUpdateRequest";
import type { AvanzaV1_InterestRatesResponse } from "../models/AvanzaV1_InterestRatesResponse";
import type { AvanzaV1_KycQuestionsResponse } from "../models/AvanzaV1_KycQuestionsResponse";
import type { AvanzaV1_LoansResponse } from "../models/AvanzaV1_LoansResponse";
import type { AvanzaV1_PropertiesResponse } from "../models/AvanzaV1_PropertiesResponse";
import type { AvanzaV1_PropertyResponse } from "../models/AvanzaV1_PropertyResponse";
import type { AvanzaV1_RateTablesResponse } from "../models/AvanzaV1_RateTablesResponse";
import type { AvanzaV1_UpdatePropertyRequest } from "../models/AvanzaV1_UpdatePropertyRequest";
import type { AvanzaV1_UpdatePropertySaleFinancingRequest } from "../models/AvanzaV1_UpdatePropertySaleFinancingRequest";

export class AvanzaApiV1Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        AvanzaApiV1Service.url = url;
    }

    /**
     * @returns AvanzaV1_ApplicationsResponse
     */
    public static async avanzaV1ApplicationControllerListApplications({
        size,
        page,
        ssn,
    }: {
        /** The requested page size. **/
        size?: number,
        /** The requested page. **/
        page?: number,
        /** SSN of applicant or coapplicant. Use 12 digit format without '-'. **/
        ssn?: string,
    }, ): Promise<AvanzaV1_ApplicationsResponse> {
        const query =  {
            "size": size,
            "page": page,
            "ssn": ssn,
        } ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerListApplications (${"/v1a/applications"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_ApplicationCreateResponse
     */
    public static async avanzaV1ApplicationControllerCreate({
        requestBody,
    }: {
        requestBody: AvanzaV1_ApplicationCreateRequest,
    }, ): Promise<AvanzaV1_ApplicationCreateResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerCreate (${"/v1a/applications"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_ApplicationResponse
     */
    public static async avanzaV1ApplicationControllerGet({
        id,
    }: {
        id: number,
    }, ): Promise<AvanzaV1_ApplicationResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerGet (${"/v1a/applications/${id}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async avanzaV1ApplicationControllerUpdateApplication({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: AvanzaV1_ApplicationUpdateRequest,
    }, ): Promise<any> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdateApplication (${"/v1a/applications/${id}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async avanzaV1ApplicationControllerDeleteApplication({
        id,
    }: {
        id: number,
    }, ): Promise<any> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}`, query }), {
            method: "DELETE",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerDeleteApplication (${"/v1a/applications/${id}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_ApplicationsLoansUpdateResponse
     */
    public static async avanzaV1ApplicationControllerUpdateLoans({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: AvanzaV1_ApplicationsLoansUpdateRequest,
    }, ): Promise<AvanzaV1_ApplicationsLoansUpdateResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/loans`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdateLoans (${"/v1a/applications/${id}/loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any Successfully set list of current loans
     */
    public static async avanzaV1ApplicationControllerUpdateCurrentLoans({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: AvanzaV1_ApplicationsCurrentLoansUpdateRequest,
    }, ): Promise<any> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/currentLoans`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdateCurrentLoans (${"/v1a/applications/${id}/currentLoans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async avanzaV1ApplicationControllerUpdatePropertySaleFinancing({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: AvanzaV1_UpdatePropertySaleFinancingRequest,
    }, ): Promise<any> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/propertySaleFinancing`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdatePropertySaleFinancing (${"/v1a/applications/${id}/propertySaleFinancing"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_PropertiesResponse
     */
    public static async avanzaV1ApplicationControllerGetApplicationProperties({
        id,
    }: {
        id: number,
    }, ): Promise<AvanzaV1_PropertiesResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/properties`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerGetApplicationProperties (${"/v1a/applications/${id}/properties"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_PropertyResponse
     */
    public static async avanzaV1ApplicationControllerUpdateApplicationProperty({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: AvanzaV1_UpdatePropertyRequest,
    }, ): Promise<AvanzaV1_PropertyResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/property`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdateApplicationProperty (${"/v1a/applications/${id}/property"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any Accepted
     */
    public static async avanzaV1ApplicationControllerUpdateCda({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: AvanzaV1_CdaUpdateRequest,
    }, ): Promise<any> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/cda`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdateCda (${"/v1a/applications/${id}/cda"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_BankIdSessionResponse
     */
    public static async avanzaV1ApplicationControllerSubmitApplicant({
        id,
        ssn,
        requestBody,
    }: {
        id: number,
        /** Unhyphenated 12 digit personal number. **/
        ssn: string,
        requestBody: AvanzaV1_ApplicantSubmitRequest,
    }, ): Promise<AvanzaV1_BankIdSessionResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/applicants/${ssn}/submit`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerSubmitApplicant (${"/v1a/applications/${id}/applicants/${ssn}/submit"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_ApplicantUpdatedResponse
     */
    public static async avanzaV1ApplicationControllerUpdateApplicant({
        id,
        ssn,
        requestBody,
    }: {
        id: number,
        /** Unhyphenated 12 digit personal number. **/
        ssn: string,
        requestBody: AvanzaV1_ApplicantUpdateRequest,
    }, ): Promise<AvanzaV1_ApplicantUpdatedResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/applicants/${ssn}`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdateApplicant (${"/v1a/applications/${id}/applicants/${ssn}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async avanzaV1ApplicationControllerDeleteApplicant({
        id,
        ssn,
    }: {
        id: number,
        /** Unhyphenated 12 digit personal number. **/
        ssn: string,
    }, ): Promise<any> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/applicants/${ssn}`, query }), {
            method: "DELETE",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerDeleteApplicant (${"/v1a/applications/${id}/applicants/${ssn}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns any
     */
    public static async avanzaV1ApplicationControllerUpdateApplicantKyc({
        id,
        ssn,
        requestBody,
    }: {
        id: number,
        /** Unhyphenated 12 digit personal number. **/
        ssn: string,
        requestBody: AvanzaV1_ApplicantKycUpdateRequest,
    }, ): Promise<any> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/applicants/${ssn}/kyc`, query }), {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerUpdateApplicantKyc (${"/v1a/applications/${id}/applicants/${ssn}/kyc"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_AddressSearchPageResponse
     */
    public static async avanzaV1ApplicationControllerList({
        id,
        text,
    }: {
        id: number,
        text: string,
    }, ): Promise<AvanzaV1_AddressSearchPageResponse> {
        const query =  {
            "text": text,
        } ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/applications/${id}/addresses`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1ApplicationControllerList (${"/v1a/applications/${id}/addresses"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_BankIdSessionResponse
     */
    public static async avanzaV1BankIdControllerGetStatusSessionId({
        sessionId,
    }: {
        /** Submission session ID. **/
        sessionId: string,
    }, ): Promise<AvanzaV1_BankIdSessionResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/bankid/status/${sessionId}`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1BankIdControllerGetStatusSessionId (${"/v1a/bankid/status/${sessionId}"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_InterestRatesResponse
     */
    public static async avanzaV1InterestRateControllerGetInterestRates({
        includeFuture,
    }: {
        includeFuture?: boolean,
    }, ): Promise<AvanzaV1_InterestRatesResponse> {
        const query =  {
            "includeFuture": includeFuture,
        } ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/interestrates`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1InterestRateControllerGetInterestRates (${"/v1a/interestrates"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_RateTablesResponse
     */
    public static async avanzaV1InterestRateControllerGetRateTables({
        productType,
    }: {
        productType: 'MORTGAGE' | 'DEPOSIT_LOAN' | 'BRIDGE_LOAN',
    }, ): Promise<AvanzaV1_RateTablesResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/interestrates/${productType}/table`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1InterestRateControllerGetRateTables (${"/v1a/interestrates/${productType}/table"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_RateTablesResponse
     */
    public static async avanzaV1InterestRateControllerGetMortgageRateTable(): Promise<AvanzaV1_RateTablesResponse> {
        const query =  {} ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/interestrates/mortgagetable`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1InterestRateControllerGetMortgageRateTable (${"/v1a/interestrates/mortgagetable"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_KycQuestionsResponse
     */
    public static async avanzaV1KycControllerGetKyc({
        applicationType,
    }: {
        /** Type has to be specified for purchase loan applications. **/
        applicationType?: 'TRANSFER_LOAN' | 'NEW_LOAN' | 'TOPUP_LOAN' | 'PURCHASE_LOAN' | 'LOAN_COMMITMENT',
    }, ): Promise<AvanzaV1_KycQuestionsResponse> {
        const query =  {
            "applicationType": applicationType,
        } ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/kyc`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1KycControllerGetKyc (${"/v1a/kyc"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns AvanzaV1_LoansResponse
     */
    public static async avanzaV1LoanControllerGetLoans({
        size,
        page,
    }: {
        size?: number,
        page?: number,
    }, ): Promise<AvanzaV1_LoansResponse> {
        const query =  {
            "size": size,
            "page": page,
        } ;
        if (AvanzaApiV1Service.url === undefined) throw new Error("No url set for generated AvanzaApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": AvanzaApiV1Service.url + `/v1a/loans`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `AvanzaApiV1Service.avanzaV1LoanControllerGetLoans (${"/v1a/loans"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}