{
	"default": {
		"api": {
			"url": "https://iris.stabelo.com"
		},
		"stabelo": {
			"url": "https://www.stabelo.se"
		},
		"error_boundary": {
			"display_message": false
		}
	},
	"stage": {
		"api": {
			"url": "https://iris.stabelo.stage"
		},
		"stabelo": {
			"url": "https://www.stabelo.stage"
		},
		"error_boundary": {
			"display_message": false
		}
	},
	"test": {
		"api": {
			"url": "https://iris.stabelo.test"
		},
		"stabelo": {
			"url": "https://www.stabelo.test"
		},
		"error_boundary": {
			"display_message": true
		}
	},
	"beta": {
		"api": {
			"url": "https://iris.stabelo.beta"
		},
		"stabelo": {
			"url": "https://www.stabelo.beta"
		},
		"error_boundary": {
			"display_message": true
		}
	},
	"local": {
		"api": {
			"url": "https://iris.stabelo.test"
		},
		"stabelo": {
			"url": "https://www.stabelo.test"
		},
		"error_boundary": {
			"display_message": true
		}
	}
}
