/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { BudId_GetCommitmentRequest } from "../models/BudId_GetCommitmentRequest";
import type { BudId_GetCommitmentResponse } from "../models/BudId_GetCommitmentResponse";
import type { BudId_HasCommitmentRequest } from "../models/BudId_HasCommitmentRequest";
import type { BudId_HasCommitmentResponse } from "../models/BudId_HasCommitmentResponse";
import type { BudId_RegisterBidRequest } from "../models/BudId_RegisterBidRequest";
import type { BudId_StatusResponse } from "../models/BudId_StatusResponse";

export class BudIdApiV1Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        BudIdApiV1Service.url = url;
    }

    /**
     * @returns BudId_GetCommitmentResponse
     */
    public static async budIdControllerV1Get({
        requestBody,
    }: {
        requestBody: BudId_GetCommitmentRequest,
    }, ): Promise<BudId_GetCommitmentResponse> {
        const query =  {} ;
        if (BudIdApiV1Service.url === undefined) throw new Error("No url set for generated BudIdApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": BudIdApiV1Service.url + `/v1b/$get-commitment`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `BudIdApiV1Service.budIdControllerV1Get (${"/v1b/$get-commitment"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns BudId_HasCommitmentResponse
     */
    public static async budIdControllerV1HasCommitment({
        requestBody,
    }: {
        requestBody: BudId_HasCommitmentRequest,
    }, ): Promise<BudId_HasCommitmentResponse> {
        const query =  {} ;
        if (BudIdApiV1Service.url === undefined) throw new Error("No url set for generated BudIdApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": BudIdApiV1Service.url + `/v1b/$has-commitment`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `BudIdApiV1Service.budIdControllerV1HasCommitment (${"/v1b/$has-commitment"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns BudId_StatusResponse
     */
    public static async budIdControllerV1RegisterBid({
        id,
        requestBody,
    }: {
        /** Our ID for the mortgage commitment. **/
        id: string,
        requestBody: BudId_RegisterBidRequest,
    }, ): Promise<BudId_StatusResponse> {
        const query =  {} ;
        if (BudIdApiV1Service.url === undefined) throw new Error("No url set for generated BudIdApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": BudIdApiV1Service.url + `/v1b/${id}/$register-bid`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `BudIdApiV1Service.budIdControllerV1RegisterBid (${"/v1b/${id}/$register-bid"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns BudId_StatusResponse
     */
    public static async budIdControllerV1RegisterWinningBid({
        id,
        requestBody,
    }: {
        /** Our ID for the mortgage commitment. **/
        id: string,
        requestBody: BudId_RegisterBidRequest,
    }, ): Promise<BudId_StatusResponse> {
        const query =  {} ;
        if (BudIdApiV1Service.url === undefined) throw new Error("No url set for generated BudIdApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": BudIdApiV1Service.url + `/v1b/${id}/$register-winning-bid`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `BudIdApiV1Service.budIdControllerV1RegisterWinningBid (${"/v1b/${id}/$register-winning-bid"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}