/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SopV1_ApplicationState {
    DRAFT = 'DRAFT',
    AWAITING_APPLICATION_SIGNATURE = 'AWAITING_APPLICATION_SIGNATURE',
    ADMINISTRATING = 'ADMINISTRATING',
    APPROVED = 'APPROVED',
    DISBURSED = 'DISBURSED',
    REJECTED = 'REJECTED',
    ABORTED = 'ABORTED',
    AWAITING_LOAN_DOCUMENT_SIGNATURES = 'AWAITING_LOAN_DOCUMENT_SIGNATURES',
}