{
	"v": "5.7.6",
	"fr": 60,
	"ip": 0,
	"op": 61,
	"w": 24,
	"h": 24,
	"nm": "button-arrow",
	"ddd": 0,
	"assets": [],
	"layers": [
		{
			"ddd": 0,
			"ind": 1,
			"ty": 4,
			"nm": "Vector 2",
			"sr": 1,
			"ks": {
				"o": { "a": 0, "k": 100, "ix": 11 },
				"r": { "a": 0, "k": -180, "ix": 10 },
				"p": {
					"a": 1,
					"k": [
						{
							"i": { "x": 0.22, "y": 1 },
							"o": { "x": 0.788, "y": 0 },
							"t": 0,
							"s": [-19.25, 12, 0],
							"to": [6.248, 0, 0],
							"ti": [-5.208, 0, 0]
						},
						{ "t": 60, "s": [12, 12, 0] }
					],
					"ix": 2,
					"l": 2
				},
				"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
				"s": { "a": 0, "k": [100, -100, 100], "ix": 6, "l": 2 }
			},
			"ao": 0,
			"shapes": [
				{
					"ty": "gr",
					"it": [
						{
							"ind": 0,
							"ty": "sh",
							"ix": 1,
							"ks": {
								"a": 0,
								"k": {
									"i": [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									"o": [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									"v": [
										[6.59, -1.41],
										[8, 0],
										[0, 8],
										[-8, 0],
										[-6.58, -1.42],
										[-1, 4.17],
										[-1, -8],
										[1, -8],
										[1, 4.17]
									],
									"c": true
								},
								"ix": 2
							},
							"nm": "Path 1",
							"mn": "ADBE Vector Shape - Group",
							"hd": false
						},
						{
							"ty": "fl",
							"c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
							"o": { "a": 0, "k": 100, "ix": 5 },
							"r": 1,
							"bm": 0,
							"nm": "Fill 1",
							"mn": "ADBE Vector Graphic - Fill",
							"hd": false
						},
						{
							"ty": "tr",
							"p": { "a": 0, "k": [0, 0], "ix": 2 },
							"a": { "a": 0, "k": [0, 0], "ix": 1 },
							"s": { "a": 0, "k": [100, 100], "ix": 3 },
							"r": { "a": 0, "k": 90, "ix": 6 },
							"o": { "a": 0, "k": 100, "ix": 7 },
							"sk": { "a": 0, "k": 0, "ix": 4 },
							"sa": { "a": 0, "k": 0, "ix": 5 },
							"nm": "Transform"
						}
					],
					"nm": "Vector",
					"np": 2,
					"cix": 2,
					"bm": 0,
					"ix": 1,
					"mn": "ADBE Vector Group",
					"hd": false
				}
			],
			"ip": 0,
			"op": 62,
			"st": 0,
			"bm": 0
		},
		{
			"ddd": 0,
			"ind": 2,
			"ty": 4,
			"nm": "Vector",
			"sr": 1,
			"ks": {
				"o": { "a": 0, "k": 100, "ix": 11 },
				"r": { "a": 0, "k": -180, "ix": 10 },
				"p": {
					"a": 1,
					"k": [
						{
							"i": { "x": 0.22, "y": 1 },
							"o": { "x": 0.78, "y": 0 },
							"t": 0,
							"s": [12, 12, 0],
							"to": [6.25, 0, 0],
							"ti": [-6.25, 0, 0]
						},
						{ "t": 60, "s": [49.5, 12, 0] }
					],
					"ix": 2,
					"l": 2
				},
				"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
				"s": { "a": 0, "k": [100, -100, 100], "ix": 6, "l": 2 }
			},
			"ao": 0,
			"shapes": [
				{
					"ty": "gr",
					"it": [
						{
							"ind": 0,
							"ty": "sh",
							"ix": 1,
							"ks": {
								"a": 0,
								"k": {
									"i": [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									"o": [
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0],
										[0, 0]
									],
									"v": [
										[6.59, -1.41],
										[8, 0],
										[0, 8],
										[-8, 0],
										[-6.58, -1.42],
										[-1, 4.17],
										[-1, -8],
										[1, -8],
										[1, 4.17]
									],
									"c": true
								},
								"ix": 2
							},
							"nm": "Path 1",
							"mn": "ADBE Vector Shape - Group",
							"hd": false
						},
						{
							"ty": "fl",
							"c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
							"o": { "a": 0, "k": 100, "ix": 5 },
							"r": 1,
							"bm": 0,
							"nm": "Fill 1",
							"mn": "ADBE Vector Graphic - Fill",
							"hd": false
						},
						{
							"ty": "tr",
							"p": { "a": 0, "k": [0, 0], "ix": 2 },
							"a": { "a": 0, "k": [0, 0], "ix": 1 },
							"s": { "a": 0, "k": [100, 100], "ix": 3 },
							"r": { "a": 0, "k": 90, "ix": 6 },
							"o": { "a": 0, "k": 100, "ix": 7 },
							"sk": { "a": 0, "k": 0, "ix": 4 },
							"sa": { "a": 0, "k": 0, "ix": 5 },
							"nm": "Transform"
						}
					],
					"nm": "Vector",
					"np": 2,
					"cix": 2,
					"bm": 0,
					"ix": 1,
					"mn": "ADBE Vector Group",
					"hd": false
				}
			],
			"ip": 0,
			"op": 62,
			"st": 0,
			"bm": 0
		}
	],
	"markers": []
}
