import { ComponentsV1_ConfigurationResponse } from "../../../generated/iris/models/ComponentsV1_ConfigurationResponse";
import { ComponentsV1_RateTablesResponse } from "../../../generated/iris/models/ComponentsV1_RateTablesResponse";
import { ComponentsApiV1Service } from "../../../generated/iris/services/ComponentsApiV1Service";

interface IRateCalculatorApi {
	getRateTables: (distributorId: string | undefined) => Promise<ComponentsV1_RateTablesResponse>;
	getApplicationConfiguration: () => Promise<ComponentsV1_ConfigurationResponse>;
}

export const RateCalculatorApi = (): IRateCalculatorApi => {
	const getRateTables = async (clientId: string | undefined): Promise<ComponentsV1_RateTablesResponse> => {
		return await ComponentsApiV1Service.componentsV1RateControllerGetRateTables({
			productType: "MORTGAGE",
			clientId,
		});
	};

	const getApplicationConfiguration = async (): Promise<ComponentsV1_ConfigurationResponse> => {
		return ComponentsApiV1Service.componentsV1ConfigurationControllerGet();
	};

	return { getRateTables, getApplicationConfiguration };
};
