/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Add } from "@mui/icons-material";
import {
	accordionSummaryClasses,
	createTheme,
	Fade,
	filledInputClasses,
	inputBaseClasses,
	inputLabelClasses,
} from "@mui/material";
import * as React from "react";
// eslint-disable-next-line import/no-unresolved
import EditorialNewVariable from "url:./public/fonts/editorialNew/EditorialNew-Variable.ttf";
// eslint-disable-next-line import/no-unresolved
import NeueMontrealBold from "url:./public/fonts/neueMontreal/NeueMontreal-Bold.woff2";
// eslint-disable-next-line import/no-unresolved
import NeueMontrealItalic from "url:./public/fonts/neueMontreal/NeueMontreal-Italic.woff2";
// eslint-disable-next-line import/no-unresolved
import NeueMontrealLight from "url:./public/fonts/neueMontreal/NeueMontreal-Light.woff2";
// eslint-disable-next-line import/no-unresolved
import NeueMontrealMedium from "url:./public/fonts/neueMontreal/NeueMontreal-Medium.ttf";
// eslint-disable-next-line import/no-unresolved
import NeueMontrealRegular from "url:./public/fonts/neueMontreal/NeueMontreal-Regular.woff2";
// eslint-disable-next-line import/no-unresolved

export const containerBorderRadius = "16px";
const circularBorderRadius = "100px";
const scrollbarWidth = 17;

const defaultTheme = createTheme({
	breakpoints: {
		// all values 17 smaller than stabelo.se due to scrollbars
		values: {
			xs: 469 - scrollbarWidth,
			sm: 767 - scrollbarWidth,
			md: 991 - scrollbarWidth,
			lg: 1200 - scrollbarWidth,
			xl: 1600 - scrollbarWidth,
		},
	},
});

export let stabeloTheme = createTheme(defaultTheme, {
	palette: {
		primary: {
			main: "#1A1A1A",
		},
		secondary: {
			light: "#E6E8E5",
			main: "#bcbdbb",
		},
		info: {
			light: "#EFF6FB",
			main: "#c5ddec",
		},
		background: {
			paper: "white",
		},
		text: {
			primary: "#000000",
		},
		divider: "#DDDDDD",
		warning: { light: "#fdeabb", main: "#ffd43b", dark: "#edb222" },
		error: { light: "#e0ae92", main: "#D84F0A" },
		success: { light: "#C5E7BF", main: "#59c746" },
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
          font-family: EditorialNew;
          font-style: normal;
          font-display: swap;
          font-weight: 1 999;
          src: url(${EditorialNewVariable}) format("truetype-variations");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url(${NeueMontrealLight}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(${NeueMontrealRegular}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url(${NeueMontrealMedium});
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url(${NeueMontrealBold}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: url(${NeueMontrealItalic}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
		},
	},
	typography: {
		fontFamily: `NeueMontreal, verdana, sans-serif`,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		fontSize: 14,
		htmlFontSize: 14,
		body1: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1vw",
			[defaultTheme.breakpoints.up("xl")]: {
				fontSize: 16,
			},
		},
		body2: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.39em",
			[defaultTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 18,
			},
		},
		h1: {
			fontFamily: `Editorialnew, "Times New Roman", sans-serif`,
			fontSize: "5.56em",
			lineHeight: "0.9em",
			marginTop: 0,
			marginBottom: 0,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: "9.38em",
			},
			[defaultTheme.breakpoints.down("xs")]: {
				fontSize: 20,
			},
		},
		h2: {
			fontFamily: `Editorialnew, "Times New Roman", sans-serif`,
			fontSize: "2.78em",
			lineHeight: "1.05em",
			marginTop: 0,
			marginBottom: "0.5em",
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 40,
				lineHeight: "48px",
				marginBottom: "16px",
			},
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: 32,
				lineheight: "120%",
			},
		},
		h3: {
			fontFamily: `Editorialnew, "Times New Roman", sans-serif`,
			fontSize: 40,
			lineHeight: "40px",
			marginTop: 20,
			marginBottom: 10,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 32,
			},
		},
		h4: {
			fontFamily: `NeueMontreal, Verdana, sans-serif`,
			fontSize: "1.67em",
			lineHeight: "1.25em",
			marginTop: 0,
			marginBottom: "0.56em",
			fontWeight: 500,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 20,
			},
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: 18,
			},
		},
		h5: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.25em",
			lineHeight: "1.56em",
			marginTop: 0,
			marginBottom: 0,
			fontWeight: 500,
		},
		h6: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.5em",
			lineHeight: "1.56em",
			marginTop: 0,
			marginBottom: 0,
			fontWeight: 500,
			[defaultTheme.breakpoints.up("xl")]: {
				fontSize: "1.5em",
			},
			[defaultTheme.breakpoints.down("lg")]: {
				fontSize: 18,
			},
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 20,
			},
		},
		button: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.25em",
			lineHeight: 1.25,
			fontWeight: 500,
			textTransform: "unset",
			textAlign: "left",
			[defaultTheme.breakpoints.up("xl")]: {
				fontSize: "1.25em",
			},
			[defaultTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 18,
				lineHeight: "1em",
			},
		},
		subtitle1: {
			fontSize: "14px",
			fontWeight: 600,
		},
	},
});

stabeloTheme = createTheme(stabeloTheme, {
	components: {
		MuiTypography: {
			defaultProps: {
				variant: "body2",
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: "filled",
			},
			InputProps: {
				disableUnderline: true,
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize: "1.39em",
					[defaultTheme.breakpoints.up("xl")]: {
						fontSize: "1.39em",
					},
					[defaultTheme.breakpoints.down("lg")]: {
						fontSize: 16,
					},
					[defaultTheme.breakpoints.down("md")]: {
						fontSize: 18,
					},
					[`&.${inputBaseClasses.disabled}.${filledInputClasses.disabled}`]: {
						"color": stabeloTheme.palette.text.primary,
						"background": stabeloTheme.palette.background.paper,
						"&:hover": {
							borderColor: "transparent",
						},
					},
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					"&&&:before": {
						borderBottom: "none",
					},
					"&&:after": {
						borderBottom: "none",
					},
					"border": `2px solid transparent`,
					"&:focus-within, &:hover": {
						borderColor: stabeloTheme.palette.primary.main,
					},
					"borderRadius": stabeloTheme.spacing(1),
					[`&.${filledInputClasses.disabled}.${inputBaseClasses.disabled}`]: {
						"color": stabeloTheme.palette.text.primary,
						"background": stabeloTheme.palette.background.paper,
						"&:hover": {
							borderColor: "transparent",
						},
					},
				},
				input: {
					[`&.${filledInputClasses.disabled}.${inputBaseClasses.disabled}`]: {
						"WebkitTextFillColor": stabeloTheme.palette.text.primary,
						"background": stabeloTheme.palette.background.paper,
						"&:hover": {
							borderColor: "transparent",
						},
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					[`&.${inputLabelClasses.disabled}`]: {
						color: stabeloTheme.palette.text.primary,
						opacity: 0.6,
					},
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					height: stabeloTheme.spacing(9),
					boxShadow: "none",
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					flex: 1,
					padding: `0 ${stabeloTheme.spacing(4)}`,
					[stabeloTheme.breakpoints.up("md")]: {
						padding: `0 ${stabeloTheme.spacing(4)}`,
					},
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					borderCollapse: "collapse",
					tableLayout: "fixed",
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					tr: {
						borderBottom: "1px solid rgba(0, 0, 0, 0.75)",
					},
					th: {
						fontFamily: `NeueMontreal, "Verdana", sans-serif`,
						fontWeight: 500,
						fontSize: "13px",
						backgroundColor: "transparent",
					},
				},
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					"tr:last-child": {
						borderBottom: "none",
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
					padding: "10px inherit",
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					"&:first-of-type": {
						paddingLeft: 0,
					},
				},
				body: {
					fontSize: "14px",
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "nowrap",
					paddingTop: "10px",
					paddingBottom: "10px",
					textAlign: "left",
					borderBottom: "none",
				},
				head: {
					textAlign: "left",
					borderBottom: "none",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					"padding": "0.83em 1.39em 0.83em 1.67em",
					"borderRadius": "100vw",
					"transition": "transform 300ms ease",
					[defaultTheme.breakpoints.down("md")]: {
						padding: "12px 18px 12px 24px",
					},
					"&:hover": {
						transform: "translate(0px, -3px)",
					},
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},

		MuiSelect: {
			defaultProps: {
				variant: "filled",
			},
			InputProps: {
				disableUnderline: true,
			},
			styleOverrides: {
				icon: {
					color: stabeloTheme.palette.primary.main,
				},
				standard: {
					fontSize: "13px",
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						fontWeight: 500,
					},
				},
			},
		},
		MuiPaginationItem: {
			styleOverrides: {
				text: {
					fontSize: "13px",
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					padding: stabeloTheme.spacing(4),
					borderRadius: containerBorderRadius,
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: containerBorderRadius,
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					borderRadius: containerBorderRadius,
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: stabeloTheme.palette.primary.main,
				},
			},
		},
		MuiAccordion: {
			defaultProps: {
				elevation: 0,
				disableGutters: true,
			},
			styleOverrides: {
				root: {
					"position": "unset",
					"minHeight": stabeloTheme.spacing(7),
					"background": "transparent",
					"borderBottom": `1px solid ${stabeloTheme.palette.divider}`,
					"&:last-child": {
						borderBottom: 0,
					},
				},
			},
		},
		MuiAccordionSummary: {
			defaultProps: {
				expandIcon: <Add />,
			},
			styleOverrides: {
				root: {
					minHeight: stabeloTheme.spacing(7),
					padding: 0,
				},
				content: {
					alignItems: "center",
				},
				expandIconWrapper: {
					color: stabeloTheme.palette.primary.main,
					transition: stabeloTheme.transitions.create("transform", {
						duration: stabeloTheme.transitions.duration.shortest,
					}),
					[`&.${accordionSummaryClasses.expanded}`]: {
						transform: "rotate(45deg)",
					},
				},
			},
		},

		MuiTab: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					paddingLeft: 0,
					paddingRight: 0,
					minWidth: 0,
					marginRight: stabeloTheme.spacing(2),
				},
			},
		},
		MuiTabPanel: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},

		MuiToggleButton: {
			styleOverrides: {
				root: {
					"border": `1px solid transparent`,
					"borderRadius": circularBorderRadius,
					"borderColor": stabeloTheme.palette.primary.main,
					"color": stabeloTheme.palette.primary.main,
					"&.Mui-selected": {
						backgroundColor: stabeloTheme.palette.primary.main,
						color: stabeloTheme.palette.secondary.light,
					},
					"&.Mui-selected:hover": {
						backgroundColor: stabeloTheme.palette.primary.main,
					},
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				arrow: true,
				TransitionComponent: Fade,
			},
			styleOverrides: {
				tooltip: {
					background: "#000000",
					color: "#FFFFFF",
					fontSize: "12px",
					padding: `${stabeloTheme.spacing(1)} ${stabeloTheme.spacing(2)}`,
				},
				arrow: {
					color: "#000000",
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				thumb: {
					backgroundColor: "#FFFFFF",
					border: "1px solid black",
					height: 25,
					width: 25,
				},
			},
		},
	},
});

const bolaneguidenPrimary = "#096a5e";

const bolaneguidenSecondary = "#11a76f";
const bolaneguidenSecondaryLight = "#e6f4ef";

const bolaneguidenBorderRadius = "8px";

export let bolaneguidenTheme = createTheme(defaultTheme, {
	palette: {
		primary: {
			main: bolaneguidenPrimary,
		},
		secondary: {
			main: bolaneguidenSecondary,
			light: bolaneguidenSecondaryLight,
		},
		info: {
			light: "#EFF6FB",
			main: "#c5ddec",
		},
		background: {
			paper: "white",
		},
		text: {
			primary: "#000000",
		},
		divider: "#DDDDDD",
		warning: { light: "#fdeabb", main: "#ffd43b", dark: "#edb222" },
		error: { light: "#e0ae92", main: "#D84F0A" },
		success: { light: "#C5E7BF", main: "#59c746" },
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
          font-family: EditorialNew;
          font-style: normal;
          font-display: swap;
          font-weight: 1 999;
          src: url(${EditorialNewVariable}) format("truetype-variations");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url(${NeueMontrealLight}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(${NeueMontrealRegular}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url(${NeueMontrealMedium});
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url(${NeueMontrealBold}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: NeueMontreal;
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: url(${NeueMontrealItalic}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
		},
	},
	typography: {
		fontFamily: `NeueMontreal, verdana, sans-serif`,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		fontSize: 14,
		htmlFontSize: 14,
		body1: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1vw",
			[defaultTheme.breakpoints.up("xl")]: {
				fontSize: 16,
			},
		},
		body2: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.39em",
			[defaultTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 18,
			},
		},
		h1: {
			fontFamily: `Editorialnew, "Times New Roman", sans-serif`,
			fontSize: "5.56em",
			lineHeight: "0.9em",
			marginTop: 0,
			marginBottom: 0,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: "9.38em",
			},
			[defaultTheme.breakpoints.down("xs")]: {
				fontSize: 20,
			},
		},
		h2: {
			fontFamily: `Editorialnew, "Times New Roman", sans-serif`,
			fontSize: "2.78em",
			lineHeight: "1.05em",
			marginTop: 0,
			marginBottom: "0.5em",
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 40,
				lineHeight: "48px",
				marginBottom: "16px",
			},
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: 32,
				lineheight: "120%",
			},
		},
		h3: {
			fontFamily: `Editorialnew, "Times New Roman", sans-serif`,
			fontSize: 40,
			lineHeight: "40px",
			marginTop: 20,
			marginBottom: 10,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 32,
			},
		},
		h4: {
			fontFamily: `NeueMontreal, Verdana, sans-serif`,
			fontSize: "1.67em",
			lineHeight: "1.25em",
			marginTop: 0,
			marginBottom: "0.56em",
			fontWeight: 500,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 20,
			},
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: 18,
			},
		},
		h5: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.25em",
			lineHeight: "1.56em",
			marginTop: 0,
			marginBottom: 0,
			fontWeight: 500,
		},
		h6: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.5em",
			lineHeight: "1.56em",
			marginTop: 0,
			marginBottom: 0,
			fontWeight: 500,
			[defaultTheme.breakpoints.up("xl")]: {
				fontSize: "1.5em",
			},
			[defaultTheme.breakpoints.down("lg")]: {
				fontSize: 18,
			},
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 20,
			},
		},
		button: {
			fontFamily: `NeueMontreal, verdana, sans-serif`,
			fontSize: "1.25em",
			lineHeight: 1.25,
			fontWeight: 500,
			textTransform: "unset",
			textAlign: "left",
			[defaultTheme.breakpoints.up("xl")]: {
				fontSize: "1.25em",
			},
			[defaultTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: 18,
				lineHeight: "1em",
			},
		},
		subtitle1: {
			fontSize: "14px",
			fontWeight: 600,
		},
	},
});

bolaneguidenTheme = createTheme(bolaneguidenTheme, {
	components: {
		MuiTextField: {
			defaultProps: {
				variant: "filled",
			},
		},

		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize: "1.39em",
					[defaultTheme.breakpoints.up("xl")]: {
						fontSize: "1.39em",
					},
					[defaultTheme.breakpoints.down("lg")]: {
						fontSize: 16,
					},
					[defaultTheme.breakpoints.down("md")]: {
						fontSize: 18,
					},
					[`&.${inputBaseClasses.disabled}.${filledInputClasses.disabled}`]: {
						"color": bolaneguidenTheme.palette.text.primary,
						"background": bolaneguidenTheme.palette.background.paper,
						"&:hover": {
							borderColor: "transparent",
						},
					},
				},
			},
		},

		MuiFilledInput: {
			styleOverrides: {
				root: {
					"&&&:before": {
						borderBottom: "none",
					},
					"&&:after": {
						borderBottom: "none",
					},
					"border": `2px solid transparent`,
					"&:focus-within, &:hover": {
						borderColor: bolaneguidenTheme.palette.primary.main,
					},
					"borderRadius": bolaneguidenTheme.spacing(1),
					[`&.${filledInputClasses.disabled}.${inputBaseClasses.disabled}`]: {
						"color": bolaneguidenTheme.palette.text.primary,
						"background": bolaneguidenTheme.palette.background.paper,
						"&:hover": {
							borderColor: "transparent",
						},
					},
				},
				input: {
					[`&.${filledInputClasses.disabled}.${inputBaseClasses.disabled}`]: {
						"WebkitTextFillColor": bolaneguidenTheme.palette.text.primary,
						"background": bolaneguidenTheme.palette.background.paper,
						"&:hover": {
							borderColor: "transparent",
						},
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					padding: "0.83em 1.39em 0.83em 1.67em",
					borderRadius: bolaneguidenBorderRadius,
					transition: "transform 300ms ease",
					[defaultTheme.breakpoints.down("md")]: {
						padding: "12px 18px 12px 24px",
					},
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					"border": `1px solid transparent`,
					"borderRadius": bolaneguidenBorderRadius,
					"borderColor": bolaneguidenTheme.palette.text.secondary,
					"color": bolaneguidenTheme.palette.text.secondary,
					"textAlign": "center",

					"&:focus": {
						backgroundColor: "rgba(0, 0, 0, 0.04)",
					},
					"&.Mui-selected:focus": {
						backgroundColor: "#07554c",
					},
					"&.Mui-selected": {
						backgroundColor: bolaneguidenTheme.palette.primary.main,
						color: bolaneguidenTheme.palette.secondary.light,
					},
					"&.Mui-selected:hover": {
						backgroundColor: bolaneguidenTheme.palette.primary.main,
					},
				},
			},
		},
		MuiLoadingButton: {
			styleOverrides: {
				root: {
					"&:hover": {
						backgroundColor: bolaneguidenPrimary,
					},
				},
			},
		},
	},
});
