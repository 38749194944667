/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type { AddressIndata } from "./models/AddressIndata";
export type { AmountIndata } from "./models/AmountIndata";
export type { AmountOutdata } from "./models/AmountOutdata";
export type { AvanzaV1_AddressSearchPageResponse } from "./models/AvanzaV1_AddressSearchPageResponse";
export type { AvanzaV1_AddressSearchResponse } from "./models/AvanzaV1_AddressSearchResponse";
export type { AvanzaV1_AnswerIndata } from "./models/AvanzaV1_AnswerIndata";
export type { AvanzaV1_AnswerOptionIndata } from "./models/AvanzaV1_AnswerOptionIndata";
export type { AvanzaV1_ApplicantCreateRequest } from "./models/AvanzaV1_ApplicantCreateRequest";
export type { AvanzaV1_ApplicantKycUpdateRequest } from "./models/AvanzaV1_ApplicantKycUpdateRequest";
export type { AvanzaV1_ApplicantResponse } from "./models/AvanzaV1_ApplicantResponse";
export type { AvanzaV1_ApplicantSubmitRequest } from "./models/AvanzaV1_ApplicantSubmitRequest";
export type { AvanzaV1_ApplicantUpdatedResponse } from "./models/AvanzaV1_ApplicantUpdatedResponse";
export type { AvanzaV1_ApplicantUpdateRequest } from "./models/AvanzaV1_ApplicantUpdateRequest";
export type { AvanzaV1_ApplicationCreateRequest } from "./models/AvanzaV1_ApplicationCreateRequest";
export type { AvanzaV1_ApplicationCreateResponse } from "./models/AvanzaV1_ApplicationCreateResponse";
export type { AvanzaV1_ApplicationLoanResponse } from "./models/AvanzaV1_ApplicationLoanResponse";
export type { AvanzaV1_ApplicationResponse } from "./models/AvanzaV1_ApplicationResponse";
export type { AvanzaV1_ApplicationsCurrentLoansUpdateRequest } from "./models/AvanzaV1_ApplicationsCurrentLoansUpdateRequest";
export type { AvanzaV1_ApplicationsCurrentLoanUpdateRequest } from "./models/AvanzaV1_ApplicationsCurrentLoanUpdateRequest";
export type { AvanzaV1_ApplicationsLoansUpdateRequest } from "./models/AvanzaV1_ApplicationsLoansUpdateRequest";
export type { AvanzaV1_ApplicationsLoansUpdateResponse } from "./models/AvanzaV1_ApplicationsLoansUpdateResponse";
export type { AvanzaV1_ApplicationsLoanUpdateRequest } from "./models/AvanzaV1_ApplicationsLoanUpdateRequest";
export type { AvanzaV1_ApplicationsResponse } from "./models/AvanzaV1_ApplicationsResponse";
export type { AvanzaV1_ApplicationUpdateRequest } from "./models/AvanzaV1_ApplicationUpdateRequest";
export type { AvanzaV1_BadRequestResponse } from "./models/AvanzaV1_BadRequestResponse";
export type { AvanzaV1_BankIdSessionResponse } from "./models/AvanzaV1_BankIdSessionResponse";
export type { AvanzaV1_BorrowerResponse } from "./models/AvanzaV1_BorrowerResponse";
export type { AvanzaV1_BridgeDepositRateResponse } from "./models/AvanzaV1_BridgeDepositRateResponse";
export type { AvanzaV1_CdaOtherPropertyUpdateRequest } from "./models/AvanzaV1_CdaOtherPropertyUpdateRequest";
export type { AvanzaV1_CdaUpdateRequest } from "./models/AvanzaV1_CdaUpdateRequest";
export type { AvanzaV1_CurrentLoanResponse } from "./models/AvanzaV1_CurrentLoanResponse";
export type { AvanzaV1_DocumentResponse } from "./models/AvanzaV1_DocumentResponse";
export type { AvanzaV1_ForbiddenResponse } from "./models/AvanzaV1_ForbiddenResponse";
export type { AvanzaV1_HouseholdEconomyResponse } from "./models/AvanzaV1_HouseholdEconomyResponse";
export type { AvanzaV1_IncomeIndata } from "./models/AvanzaV1_IncomeIndata";
export type { AvanzaV1_IncomeResponse } from "./models/AvanzaV1_IncomeResponse";
export type { AvanzaV1_InterestRateResponse } from "./models/AvanzaV1_InterestRateResponse";
export type { AvanzaV1_InterestRatesResponse } from "./models/AvanzaV1_InterestRatesResponse";
export type { AvanzaV1_InternalServerErrorResponse } from "./models/AvanzaV1_InternalServerErrorResponse";
export type { AvanzaV1_KycAnswersResponse } from "./models/AvanzaV1_KycAnswersResponse";
export type { AvanzaV1_KycOptionResponse } from "./models/AvanzaV1_KycOptionResponse";
export type { AvanzaV1_KycQuestionResponse } from "./models/AvanzaV1_KycQuestionResponse";
export type { AvanzaV1_KycQuestionsResponse } from "./models/AvanzaV1_KycQuestionsResponse";
export type { AvanzaV1_LoanResponse } from "./models/AvanzaV1_LoanResponse";
export type { AvanzaV1_LoansPropertyResponse } from "./models/AvanzaV1_LoansPropertyResponse";
export type { AvanzaV1_LoansResponse } from "./models/AvanzaV1_LoansResponse";
export type { AvanzaV1_NotFoundResponse } from "./models/AvanzaV1_NotFoundResponse";
export type { AvanzaV1_OtherPropertyResponse } from "./models/AvanzaV1_OtherPropertyResponse";
export type { AvanzaV1_PageResponse } from "./models/AvanzaV1_PageResponse";
export type { AvanzaV1_PaymentResponse } from "./models/AvanzaV1_PaymentResponse";
export type { AvanzaV1_PostApplicationBadRequestResponse } from "./models/AvanzaV1_PostApplicationBadRequestResponse";
export type { AvanzaV1_PostApplicationForbiddenResponse } from "./models/AvanzaV1_PostApplicationForbiddenResponse";
export type { AvanzaV1_PostApplicationNotFoundResponse } from "./models/AvanzaV1_PostApplicationNotFoundResponse";
export type { AvanzaV1_PriceResponse } from "./models/AvanzaV1_PriceResponse";
export type { AvanzaV1_ProductConfigurationResponse } from "./models/AvanzaV1_ProductConfigurationResponse";
export type { AvanzaV1_PropertiesResponse } from "./models/AvanzaV1_PropertiesResponse";
export type { AvanzaV1_PropertyInformationResponse } from "./models/AvanzaV1_PropertyInformationResponse";
export type { AvanzaV1_PropertyResponse } from "./models/AvanzaV1_PropertyResponse";
export type { AvanzaV1_PropertySaleFinancingResponse } from "./models/AvanzaV1_PropertySaleFinancingResponse";
export type { AvanzaV1_PutApplicantBadRequestResponse } from "./models/AvanzaV1_PutApplicantBadRequestResponse";
export type { AvanzaV1_PutApplicantForbiddenResponse } from "./models/AvanzaV1_PutApplicantForbiddenResponse";
export type { AvanzaV1_PutApplicantNotFoundResponse } from "./models/AvanzaV1_PutApplicantNotFoundResponse";
export type { AvanzaV1_PutPropertyForbiddenResponse } from "./models/AvanzaV1_PutPropertyForbiddenResponse";
export type { AvanzaV1_PutPropertySaleFinancingForbiddenResponse } from "./models/AvanzaV1_PutPropertySaleFinancingForbiddenResponse";
export type { AvanzaV1_RateTableItemResponse } from "./models/AvanzaV1_RateTableItemResponse";
export type { AvanzaV1_RateTablesResponse } from "./models/AvanzaV1_RateTablesResponse";
export type { AvanzaV1_SigningResponse } from "./models/AvanzaV1_SigningResponse";
export type { AvanzaV1_UnauthorizedResponse } from "./models/AvanzaV1_UnauthorizedResponse";
export type { AvanzaV1_UpdatePropertyRequest } from "./models/AvanzaV1_UpdatePropertyRequest";
export type { AvanzaV1_UpdatePropertySaleFinancingRequest } from "./models/AvanzaV1_UpdatePropertySaleFinancingRequest";
export type { BadRequestResponse } from "./models/BadRequestResponse";
export type { BudId_BadRequestResponse } from "./models/BudId_BadRequestResponse";
export type { BudId_CtaOutdata } from "./models/BudId_CtaOutdata";
export type { BudId_ForbiddenResponse } from "./models/BudId_ForbiddenResponse";
export type { BudId_GetCommitmentRequest } from "./models/BudId_GetCommitmentRequest";
export type { BudId_GetCommitmentResponse } from "./models/BudId_GetCommitmentResponse";
export type { BudId_HasCommitmentRequest } from "./models/BudId_HasCommitmentRequest";
export type { BudId_HasCommitmentResponse } from "./models/BudId_HasCommitmentResponse";
export type { BudId_InternalServerErrorResponse } from "./models/BudId_InternalServerErrorResponse";
export type { BudId_InvalidSignatureResponse } from "./models/BudId_InvalidSignatureResponse";
export type { BudId_LocalizationOutdata } from "./models/BudId_LocalizationOutdata";
export type { BudId_NotFoundResponse } from "./models/BudId_NotFoundResponse";
export type { BudId_RegisterBidRequest } from "./models/BudId_RegisterBidRequest";
export type { BudId_StatusResponse } from "./models/BudId_StatusResponse";
export type { BudId_UnauthorizedResponse } from "./models/BudId_UnauthorizedResponse";
export type { ComponentsV1_ConfigurationResponse } from "./models/ComponentsV1_ConfigurationResponse";
export type { ComponentsV1_FailureMessagesResponse } from "./models/ComponentsV1_FailureMessagesResponse";
export type { ComponentsV1_MessageOutdata } from "./models/ComponentsV1_MessageOutdata";
export type { ComponentsV1_PriceResponse } from "./models/ComponentsV1_PriceResponse";
export type { ComponentsV1_ProductConfigurationResponse } from "./models/ComponentsV1_ProductConfigurationResponse";
export type { ComponentsV1_RateTableItemResponse } from "./models/ComponentsV1_RateTableItemResponse";
export type { ComponentsV1_RateTablesResponse } from "./models/ComponentsV1_RateTablesResponse";
export type { CronScheduleOutdata } from "./models/CronScheduleOutdata";
export type { GenericErrorDetailOutdata } from "./models/GenericErrorDetailOutdata";
export type { HecateV1_AccountHolderResponse } from "./models/HecateV1_AccountHolderResponse";
export type { HecateV1_AccountResponse } from "./models/HecateV1_AccountResponse";
export type { HecateV1_AddParticipantRequest } from "./models/HecateV1_AddParticipantRequest";
export type { HecateV1_AddressCountryResponse } from "./models/HecateV1_AddressCountryResponse";
export type { HecateV1_AddressOutdata } from "./models/HecateV1_AddressOutdata";
export type { HecateV1_AddressResponse } from "./models/HecateV1_AddressResponse";
export type { HecateV1_AddressSearchOutdata } from "./models/HecateV1_AddressSearchOutdata";
export type { HecateV1_AmortizationBasisDialogueCaseResponse } from "./models/HecateV1_AmortizationBasisDialogueCaseResponse";
export type { HecateV1_AmountAllowanceInformationIndata } from "./models/HecateV1_AmountAllowanceInformationIndata";
export type { HecateV1_AmountAllowanceInformationOutdata } from "./models/HecateV1_AmountAllowanceInformationOutdata";
export type { HecateV1_AmountDisplayResponse } from "./models/HecateV1_AmountDisplayResponse";
export type { HecateV1_AmountOutdata } from "./models/HecateV1_AmountOutdata";
export type { HecateV1_ApplicantIndata } from "./models/HecateV1_ApplicantIndata";
export type { HecateV1_ApplicantOutdata } from "./models/HecateV1_ApplicantOutdata";
export type { HecateV1_ApplicantResponse } from "./models/HecateV1_ApplicantResponse";
export type { HecateV1_ApplicantsResponse } from "./models/HecateV1_ApplicantsResponse";
export type { HecateV1_ApplicationLoanOutdata } from "./models/HecateV1_ApplicationLoanOutdata";
export type { HecateV1_ApplicationLoansResponse } from "./models/HecateV1_ApplicationLoansResponse";
export type { HecateV1_ApplicationOutdata } from "./models/HecateV1_ApplicationOutdata";
export type { HecateV1_ApplicationResponse } from "./models/HecateV1_ApplicationResponse";
export type { HecateV1_BankResponse } from "./models/HecateV1_BankResponse";
export type { HecateV1_BanksResponse } from "./models/HecateV1_BanksResponse";
export type { HecateV1_BridgeLoanLetterSignatureTaskResponse } from "./models/HecateV1_BridgeLoanLetterSignatureTaskResponse";
export type { HecateV1_BridgeLoanOutdata } from "./models/HecateV1_BridgeLoanOutdata";
export type { HecateV1_CapitalReturnResponse } from "./models/HecateV1_CapitalReturnResponse";
export type { HecateV1_ChatCaseMessageOutData } from "./models/HecateV1_ChatCaseMessageOutData";
export type { HecateV1_ChatCaseResponse } from "./models/HecateV1_ChatCaseResponse";
export type { HecateV1_ClientIdTrackerIndata } from "./models/HecateV1_ClientIdTrackerIndata";
export type { HecateV1_Collateral } from "./models/HecateV1_Collateral";
export type { HecateV1_CollateralIndata } from "./models/HecateV1_CollateralIndata";
export type { HecateV1_CollateralOutdata } from "./models/HecateV1_CollateralOutdata";
export type { HecateV1_CollateralResponse } from "./models/HecateV1_CollateralResponse";
export type { HecateV1_CollateralsResponse } from "./models/HecateV1_CollateralsResponse";
export type { HecateV1_ComplementCaseForCustomerResponse } from "./models/HecateV1_ComplementCaseForCustomerResponse";
export type { HecateV1_ComplementCustomerTaskResponse } from "./models/HecateV1_ComplementCustomerTaskResponse";
export type { HecateV1_ComplementDocumentRequest } from "./models/HecateV1_ComplementDocumentRequest";
export type { HecateV1_ConfigurationResponse } from "./models/HecateV1_ConfigurationResponse";
export type { HecateV1_ContactResponse } from "./models/HecateV1_ContactResponse";
export type { HecateV1_ConvertToPurchaseApplicationRequest } from "./models/HecateV1_ConvertToPurchaseApplicationRequest";
export type { HecateV1_CorrelationIdsIndata } from "./models/HecateV1_CorrelationIdsIndata";
export type { HecateV1_CorrelationIdsOutdata } from "./models/HecateV1_CorrelationIdsOutdata";
export type { HecateV1_CountAllowanceInformationIndata } from "./models/HecateV1_CountAllowanceInformationIndata";
export type { HecateV1_CountAllowanceInformationOutdata } from "./models/HecateV1_CountAllowanceInformationOutdata";
export type { HecateV1_CreateApplicantIndata } from "./models/HecateV1_CreateApplicantIndata";
export type { HecateV1_CreateApplicationIndata } from "./models/HecateV1_CreateApplicationIndata";
export type { HecateV1_CreateApplicationResponse } from "./models/HecateV1_CreateApplicationResponse";
export type { HecateV1_CreateSubmissionRequest } from "./models/HecateV1_CreateSubmissionRequest";
export type { HecateV1_CreateSubmissionResponse } from "./models/HecateV1_CreateSubmissionResponse";
export type { HecateV1_CurrentLoanOutdata } from "./models/HecateV1_CurrentLoanOutdata";
export type { HecateV1_CurrentLoansResponse } from "./models/HecateV1_CurrentLoansResponse";
export type { HecateV1_DepositLoanLetterSignatureTaskResponse } from "./models/HecateV1_DepositLoanLetterSignatureTaskResponse";
export type { HecateV1_DepositLoanOutdata } from "./models/HecateV1_DepositLoanOutdata";
export type { HecateV1_Dimension } from "./models/HecateV1_Dimension";
export type { HecateV1_DirectDebitApprovalCustomerSignatureTaskResponse } from "./models/HecateV1_DirectDebitApprovalCustomerSignatureTaskResponse";
export type { HecateV1_DisbursementApprovalCustomerTaskResponse } from "./models/HecateV1_DisbursementApprovalCustomerTaskResponse";
export type { HecateV1_DocumentBundleCustomerTaskResponse } from "./models/HecateV1_DocumentBundleCustomerTaskResponse";
export type { HecateV1_DocumentOutdata } from "./models/HecateV1_DocumentOutdata";
export type { HecateV1_DocumentReference } from "./models/HecateV1_DocumentReference";
export type { HecateV1_DocumentReferenceResponse } from "./models/HecateV1_DocumentReferenceResponse";
export type { HecateV1_DocumentResponse } from "./models/HecateV1_DocumentResponse";
export type { HecateV1_DocumentsResponse } from "./models/HecateV1_DocumentsResponse";
export type { HecateV1_DocumentTags } from "./models/HecateV1_DocumentTags";
export type { HecateV1_DurationResponse } from "./models/HecateV1_DurationResponse";
export type { HecateV1_EngagementBridgeLoanOutdata } from "./models/HecateV1_EngagementBridgeLoanOutdata";
export type { HecateV1_EngagementDepositLoanOutdata } from "./models/HecateV1_EngagementDepositLoanOutdata";
export type { HecateV1_EngagementMortgageOutdata } from "./models/HecateV1_EngagementMortgageOutdata";
export type { HecateV1_EngagementPropertyOutdata } from "./models/HecateV1_EngagementPropertyOutdata";
export type { HecateV1_EngagementResponse } from "./models/HecateV1_EngagementResponse";
export type { HecateV1_ErrorLogOutdata } from "./models/HecateV1_ErrorLogOutdata";
export type { HecateV1_ErrorLogRequestBody } from "./models/HecateV1_ErrorLogRequestBody";
export type { HecateV1_ErrorLogResponse } from "./models/HecateV1_ErrorLogResponse";
export type { HecateV1_FailureMessagesResponse } from "./models/HecateV1_FailureMessagesResponse";
export type { HecateV1_FullDepositInsuranceResponse } from "./models/HecateV1_FullDepositInsuranceResponse";
export type { HecateV1_IncomesResponse } from "./models/HecateV1_IncomesResponse";
export type { HecateV1_InteractionLogOutdata } from "./models/HecateV1_InteractionLogOutdata";
export type { HecateV1_InteractionLogRequestBody } from "./models/HecateV1_InteractionLogRequestBody";
export type { HecateV1_InteractionLogResponse } from "./models/HecateV1_InteractionLogResponse";
export type { HecateV1_Interest } from "./models/HecateV1_Interest";
export type { HecateV1_InterestRateChoiceOutdata } from "./models/HecateV1_InterestRateChoiceOutdata";
export type { HecateV1_InterestRateFixationCaseResponse } from "./models/HecateV1_InterestRateFixationCaseResponse";
export type { HecateV1_InterestRateOutdata } from "./models/HecateV1_InterestRateOutdata";
export type { HecateV1_InterestTermResetDialogueCaseResponse } from "./models/HecateV1_InterestTermResetDialogueCaseResponse";
export type { HecateV1_Invoice } from "./models/HecateV1_Invoice";
export type { HecateV1_LenderBankResponse } from "./models/HecateV1_LenderBankResponse";
export type { HecateV1_LenderResponse } from "./models/HecateV1_LenderResponse";
export type { HecateV1_ListCaseResponse } from "./models/HecateV1_ListCaseResponse";
export type { HecateV1_ListSignatureCollectionDocumentResponse } from "./models/HecateV1_ListSignatureCollectionDocumentResponse";
export type { HecateV1_LoanAmountResponse } from "./models/HecateV1_LoanAmountResponse";
export type { HecateV1_LoanReferenceOutdata } from "./models/HecateV1_LoanReferenceOutdata";
export type { HecateV1_LoanResponse } from "./models/HecateV1_LoanResponse";
export type { HecateV1_LoansResponse } from "./models/HecateV1_LoansResponse";
export type { HecateV1_LogResponse } from "./models/HecateV1_LogResponse";
export type { HecateV1_MessageOutdata } from "./models/HecateV1_MessageOutdata";
export type { HecateV1_MortgageLetterCustomerSignatureTaskResponse } from "./models/HecateV1_MortgageLetterCustomerSignatureTaskResponse";
export type { HecateV1_MortgageOutdata } from "./models/HecateV1_MortgageOutdata";
export type { HecateV1_MortgageResponse } from "./models/HecateV1_MortgageResponse";
export type { HecateV1_NavigationLogOutdata } from "./models/HecateV1_NavigationLogOutdata";
export type { HecateV1_NavigationLogRequestBody } from "./models/HecateV1_NavigationLogRequestBody";
export type { HecateV1_NavigationLogResponse } from "./models/HecateV1_NavigationLogResponse";
export type { HecateV1_OptionOutdata } from "./models/HecateV1_OptionOutdata";
export type { HecateV1_OriginatorResponse } from "./models/HecateV1_OriginatorResponse";
export type { HecateV1_OtherProductOutdata } from "./models/HecateV1_OtherProductOutdata";
export type { HecateV1_OtherProductsResponse } from "./models/HecateV1_OtherProductsResponse";
export type { HecateV1_OtherPropertiesResponse } from "./models/HecateV1_OtherPropertiesResponse";
export type { HecateV1_OtherPropertyOutdata } from "./models/HecateV1_OtherPropertyOutdata";
export type { HecateV1_OwnerResponse } from "./models/HecateV1_OwnerResponse";
export type { HecateV1_OwnershipAndTitleRegistrationSignatureTaskResponse } from "./models/HecateV1_OwnershipAndTitleRegistrationSignatureTaskResponse";
export type { HecateV1_PaginatedApplicationResponse } from "./models/HecateV1_PaginatedApplicationResponse";
export type { HecateV1_PaginatedEngagementResponse } from "./models/HecateV1_PaginatedEngagementResponse";
export type { HecateV1_PaginatedLoanInvoicesResponse } from "./models/HecateV1_PaginatedLoanInvoicesResponse";
export type { HecateV1_ParticipantReferenceResponse } from "./models/HecateV1_ParticipantReferenceResponse";
export type { HecateV1_ParticipantResponse } from "./models/HecateV1_ParticipantResponse";
export type { HecateV1_PatchDocumentsBody } from "./models/HecateV1_PatchDocumentsBody";
export type { HecateV1_PensionWithdrawalResponse } from "./models/HecateV1_PensionWithdrawalResponse";
export type { HecateV1_PercentageOutdata } from "./models/HecateV1_PercentageOutdata";
export type { HecateV1_PermanentEmploymentIncomeResponse } from "./models/HecateV1_PermanentEmploymentIncomeResponse";
export type { HecateV1_PledgeApprovalCustomerSignatureTaskResponse } from "./models/HecateV1_PledgeApprovalCustomerSignatureTaskResponse";
export type { HecateV1_PledgeLetterCustomerSignatureTaskResponse } from "./models/HecateV1_PledgeLetterCustomerSignatureTaskResponse";
export type { HecateV1_PostSignatureBody } from "./models/HecateV1_PostSignatureBody";
export type { HecateV1_ProbationaryEmploymentIncomeResponse } from "./models/HecateV1_ProbationaryEmploymentIncomeResponse";
export type { HecateV1_PropertyOutdata } from "./models/HecateV1_PropertyOutdata";
export type { HecateV1_PropertyResponse } from "./models/HecateV1_PropertyResponse";
export type { HecateV1_PropertySaleFinancingResponse } from "./models/HecateV1_PropertySaleFinancingResponse";
export type { HecateV1_PurchaseInformationIndata } from "./models/HecateV1_PurchaseInformationIndata";
export type { HecateV1_PurchaseInformationOutdata } from "./models/HecateV1_PurchaseInformationOutdata";
export type { HecateV1_QuestionnaireAnswer } from "./models/HecateV1_QuestionnaireAnswer";
export type { HecateV1_QuestionnaireAnswerValue } from "./models/HecateV1_QuestionnaireAnswerValue";
export type { HecateV1_QuestionnaireOutdata } from "./models/HecateV1_QuestionnaireOutdata";
export type { HecateV1_QuestionnaireResponse } from "./models/HecateV1_QuestionnaireResponse";
export type { HecateV1_RateResponse } from "./models/HecateV1_RateResponse";
export type { HecateV1_RatesResponse } from "./models/HecateV1_RatesResponse";
export type { HecateV1_RealEstateCollateralResponse } from "./models/HecateV1_RealEstateCollateralResponse";
export type { HecateV1_RealEstateResponse } from "./models/HecateV1_RealEstateResponse";
export type { HecateV1_RegisterCapitalReturnIncomeIndata } from "./models/HecateV1_RegisterCapitalReturnIncomeIndata";
export type { HecateV1_RegisterIndividualIncomesRequest } from "./models/HecateV1_RegisterIndividualIncomesRequest";
export type { HecateV1_RegisterPensionWithdrawalIncomeIndata } from "./models/HecateV1_RegisterPensionWithdrawalIncomeIndata";
export type { HecateV1_RegisterPermanentEmploymentIncomeIndata } from "./models/HecateV1_RegisterPermanentEmploymentIncomeIndata";
export type { HecateV1_RegisterProbationaryEmploymentIncomeIndata } from "./models/HecateV1_RegisterProbationaryEmploymentIncomeIndata";
export type { HecateV1_RegisterSelfEmploymentIncomeIndata } from "./models/HecateV1_RegisterSelfEmploymentIncomeIndata";
export type { HecateV1_RegisterStudyGrantIncomeIndata } from "./models/HecateV1_RegisterStudyGrantIncomeIndata";
export type { HecateV1_RegisterTemporaryEmploymentIncomeIndata } from "./models/HecateV1_RegisterTemporaryEmploymentIncomeIndata";
export type { HecateV1_ResolveLoanWithLenderBankResponse } from "./models/HecateV1_ResolveLoanWithLenderBankResponse";
export type { HecateV1_SearchAddressResponse } from "./models/HecateV1_SearchAddressResponse";
export type { HecateV1_SearchPropertyResponse } from "./models/HecateV1_SearchPropertyResponse";
export type { HecateV1_SelectorResponse } from "./models/HecateV1_SelectorResponse";
export type { HecateV1_SelfEmploymentIncomeResponse } from "./models/HecateV1_SelfEmploymentIncomeResponse";
export type { HecateV1_SessionIdTrackerIndata } from "./models/HecateV1_SessionIdTrackerIndata";
export type { HecateV1_SignatoryResponse } from "./models/HecateV1_SignatoryResponse";
export type { HecateV1_SignatureCollectionCaseForCustomerResponse } from "./models/HecateV1_SignatureCollectionCaseForCustomerResponse";
export type { HecateV1_SignatureCollectionDocumentResponse } from "./models/HecateV1_SignatureCollectionDocumentResponse";
export type { HecateV1_SignatureRequestAppendixResponse } from "./models/HecateV1_SignatureRequestAppendixResponse";
export type { HecateV1_SigningsResponse } from "./models/HecateV1_SigningsResponse";
export type { HecateV1_StudyGrantIncomeResponse } from "./models/HecateV1_StudyGrantIncomeResponse";
export type { HecateV1_SubmissionOutdata } from "./models/HecateV1_SubmissionOutdata";
export type { HecateV1_SubmissionsResponse } from "./models/HecateV1_SubmissionsResponse";
export type { HecateV1_SupportedAdultIndata } from "./models/HecateV1_SupportedAdultIndata";
export type { HecateV1_SupportedAdultResponse } from "./models/HecateV1_SupportedAdultResponse";
export type { HecateV1_SupportedChildIndata } from "./models/HecateV1_SupportedChildIndata";
export type { HecateV1_SupportedChildResponse } from "./models/HecateV1_SupportedChildResponse";
export type { HecateV1_TemporaryEmploymentIncomeResponse } from "./models/HecateV1_TemporaryEmploymentIncomeResponse";
export type { HecateV1_TenantOwnershipApartmentCollateralResponse } from "./models/HecateV1_TenantOwnershipApartmentCollateralResponse";
export type { HecateV1_TenantOwnershipApartmentResponse } from "./models/HecateV1_TenantOwnershipApartmentResponse";
export type { HecateV1_TenantOwnershipAssociationResponse } from "./models/HecateV1_TenantOwnershipAssociationResponse";
export type { HecateV1_TenantOwnershipResponse } from "./models/HecateV1_TenantOwnershipResponse";
export type { HecateV1_UpdateAnswerIndata } from "./models/HecateV1_UpdateAnswerIndata";
export type { HecateV1_UpdateApplicantRequest } from "./models/HecateV1_UpdateApplicantRequest";
export type { HecateV1_UpdateApplicationIndata } from "./models/HecateV1_UpdateApplicationIndata";
export type { HecateV1_UpdateConsentsRequest } from "./models/HecateV1_UpdateConsentsRequest";
export type { HecateV1_UpdateCurrentLoanRequest } from "./models/HecateV1_UpdateCurrentLoanRequest";
export type { HecateV1_UpdateLoanIndata } from "./models/HecateV1_UpdateLoanIndata";
export type { HecateV1_UpdateOtherPropertiesIndata } from "./models/HecateV1_UpdateOtherPropertiesIndata";
export type { HecateV1_UpdateOtherPropertiesRequest } from "./models/HecateV1_UpdateOtherPropertiesRequest";
export type { HecateV1_UpdatePropertySaleFinancingRequest } from "./models/HecateV1_UpdatePropertySaleFinancingRequest";
export type { HecateV1_UpdateValueIndata } from "./models/HecateV1_UpdateValueIndata";
export type { HecateV1_UserReferenceOutdata } from "./models/HecateV1_UserReferenceOutdata";
export type { HecateV1_UtmParametersIndata } from "./models/HecateV1_UtmParametersIndata";
export type { HecateV1_UtmParametersOutdata } from "./models/HecateV1_UtmParametersOutdata";
export type { HecateV1_UTMTrackerIndata } from "./models/HecateV1_UTMTrackerIndata";
export type { InterestRateOutdata } from "./models/InterestRateOutdata";
export type { IntervalScheduleOutdata } from "./models/IntervalScheduleOutdata";
export type { LanaPengarV1_BankResponse } from "./models/LanaPengarV1_BankResponse";
export type { LanaPengarV1_BanksResponse } from "./models/LanaPengarV1_BanksResponse";
export type { LanaPengarV1_CreateApplicationResponse } from "./models/LanaPengarV1_CreateApplicationResponse";
export type { LanaPengarV1_CreateCommitmentApplicationRequest } from "./models/LanaPengarV1_CreateCommitmentApplicationRequest";
export type { LanaPengarV1_CreatePurchaseApplicationRequest } from "./models/LanaPengarV1_CreatePurchaseApplicationRequest";
export type { LanaPengarV1_CreateTransferApplicationRequest } from "./models/LanaPengarV1_CreateTransferApplicationRequest";
export type { NoScheduleOutdata } from "./models/NoScheduleOutdata";
export type { PercentageIndata } from "./models/PercentageIndata";
export type { PercentageOutdata } from "./models/PercentageOutdata";
export type { SopV1_AbortApplicationRequest } from "./models/SopV1_AbortApplicationRequest";
export type { SopV1_AddApplicantRequest } from "./models/SopV1_AddApplicantRequest";
export type { SopV1_AddressNotValidDetailOutdata } from "./models/SopV1_AddressNotValidDetailOutdata";
export type { SopV1_AddressNotValidResponse } from "./models/SopV1_AddressNotValidResponse";
export type { SopV1_AddressOutdata } from "./models/SopV1_AddressOutdata";
export type { SopV1_AllowanceInformationIndata } from "./models/SopV1_AllowanceInformationIndata";
export type { SopV1_AllowanceInformationOutdata } from "./models/SopV1_AllowanceInformationOutdata";
export type { SopV1_AnswerIndata } from "./models/SopV1_AnswerIndata";
export type { SopV1_AnswerOutdata } from "./models/SopV1_AnswerOutdata";
export type { SopV1_AnswersNotValidDetailOutdata } from "./models/SopV1_AnswersNotValidDetailOutdata";
export type { SopV1_AnswersNotValidResponse } from "./models/SopV1_AnswersNotValidResponse";
export type { SopV1_AnswersResponse } from "./models/SopV1_AnswersResponse";
export type { SopV1_ApplicantNotFoundDetailOutdata } from "./models/SopV1_ApplicantNotFoundDetailOutdata";
export type { SopV1_ApplicantNotFoundResponse } from "./models/SopV1_ApplicantNotFoundResponse";
export type { SopV1_ApplicantOutdata } from "./models/SopV1_ApplicantOutdata";
export type { SopV1_ApplicationNotFoundDetailOutdata } from "./models/SopV1_ApplicationNotFoundDetailOutdata";
export type { SopV1_ApplicationNotFoundResponse } from "./models/SopV1_ApplicationNotFoundResponse";
export { SopV1_ApplicationState } from "./models/SopV1_ApplicationState";
export type { SopV1_BadRequestResponse } from "./models/SopV1_BadRequestResponse";
export type { SopV1_BankOutdata } from "./models/SopV1_BankOutdata";
export type { SopV1_BridgeLoanOutdata } from "./models/SopV1_BridgeLoanOutdata";
export type { SopV1_CapitalReturnIncomeIndata } from "./models/SopV1_CapitalReturnIncomeIndata";
export type { SopV1_CapitalReturnIncomeOutdata } from "./models/SopV1_CapitalReturnIncomeOutdata";
export type { SopV1_CommitmentApplicationResponse } from "./models/SopV1_CommitmentApplicationResponse";
export type { SopV1_ComplementResponse } from "./models/SopV1_ComplementResponse";
export type { SopV1_CreateCommitmentApplicationRequest } from "./models/SopV1_CreateCommitmentApplicationRequest";
export type { SopV1_CreatePurchaseApplicationRequest } from "./models/SopV1_CreatePurchaseApplicationRequest";
export type { SopV1_CreateTopupApplicationRequest } from "./models/SopV1_CreateTopupApplicationRequest";
export type { SopV1_CreateTransferApplicationRequest } from "./models/SopV1_CreateTransferApplicationRequest";
export type { SopV1_DepositLoanOutdata } from "./models/SopV1_DepositLoanOutdata";
export type { SopV1_FileIndata } from "./models/SopV1_FileIndata";
export type { SopV1_ForbiddenResponse } from "./models/SopV1_ForbiddenResponse";
export type { SopV1_HouseholdInformationOutdata } from "./models/SopV1_HouseholdInformationOutdata";
export type { SopV1_IndividualNotFoundDetailOutdata } from "./models/SopV1_IndividualNotFoundDetailOutdata";
export type { SopV1_IndividualNotFoundResponse } from "./models/SopV1_IndividualNotFoundResponse";
export type { SopV1_MortgageOutdata } from "./models/SopV1_MortgageOutdata";
export type { SopV1_MortgagePartIndata } from "./models/SopV1_MortgagePartIndata";
export type { SopV1_MortgagePartOutdata } from "./models/SopV1_MortgagePartOutdata";
export type { SopV1_NoPropertySaleInformationIndata } from "./models/SopV1_NoPropertySaleInformationIndata";
export type { SopV1_NotAllowedResponse } from "./models/SopV1_NotAllowedResponse";
export type { SopV1_NotFoundResponse } from "./models/SopV1_NotFoundResponse";
export type { SopV1_OtherRealEstateHouseholdPropertyIndata } from "./models/SopV1_OtherRealEstateHouseholdPropertyIndata";
export type { SopV1_OtherRealEstateHouseholdPropertyOutdata } from "./models/SopV1_OtherRealEstateHouseholdPropertyOutdata";
export type { SopV1_PaginatedAnswerResponse } from "./models/SopV1_PaginatedAnswerResponse";
export type { SopV1_PaginatedApplicationResponse } from "./models/SopV1_PaginatedApplicationResponse";
export type { SopV1_PaginatedComplementResponse } from "./models/SopV1_PaginatedComplementResponse";
export type { SopV1_PaginatedSubscriptionResponse } from "./models/SopV1_PaginatedSubscriptionResponse";
export type { SopV1_PensionWithdrawalIncomeIndata } from "./models/SopV1_PensionWithdrawalIncomeIndata";
export type { SopV1_PensionWithdrawalIncomeOudata } from "./models/SopV1_PensionWithdrawalIncomeOudata";
export type { SopV1_PermanentEmploymentIncomeIndata } from "./models/SopV1_PermanentEmploymentIncomeIndata";
export type { SopV1_PermanentEmploymentIncomeOutdata } from "./models/SopV1_PermanentEmploymentIncomeOutdata";
export type { SopV1_ProbationaryEmploymentIncomeIndata } from "./models/SopV1_ProbationaryEmploymentIncomeIndata";
export type { SopV1_ProbationaryEmploymentIncomeOutdata } from "./models/SopV1_ProbationaryEmploymentIncomeOutdata";
export type { SopV1_PropertyNotValidDetailOutdata } from "./models/SopV1_PropertyNotValidDetailOutdata";
export type { SopV1_PropertyNotValidResponse } from "./models/SopV1_PropertyNotValidResponse";
export type { SopV1_PropertySaleInformationIndata } from "./models/SopV1_PropertySaleInformationIndata";
export type { SopV1_PropertySaleInformationOutdata } from "./models/SopV1_PropertySaleInformationOutdata";
export type { SopV1_PurchaseApplicationResponse } from "./models/SopV1_PurchaseApplicationResponse";
export type { SopV1_PurchaseInformationOutdata } from "./models/SopV1_PurchaseInformationOutdata";
export type { SopV1_RealEstateCollateralIndata } from "./models/SopV1_RealEstateCollateralIndata";
export type { SopV1_RealEstatePropertyOutdata } from "./models/SopV1_RealEstatePropertyOutdata";
export type { SopV1_RecreationalRealEstateHouseholdPropertyIndata } from "./models/SopV1_RecreationalRealEstateHouseholdPropertyIndata";
export type { SopV1_RecreationalRealEstateHouseholdPropertyOutdata } from "./models/SopV1_RecreationalRealEstateHouseholdPropertyOutdata";
export type { SopV1_RegisterAnswersRequest } from "./models/SopV1_RegisterAnswersRequest";
export type { SopV1_RegisterBridgeLoanRequest } from "./models/SopV1_RegisterBridgeLoanRequest";
export type { SopV1_RegisterCollateralRequest } from "./models/SopV1_RegisterCollateralRequest";
export type { SopV1_RegisterComplementRequest } from "./models/SopV1_RegisterComplementRequest";
export type { SopV1_RegisterDepositLoanRequest } from "./models/SopV1_RegisterDepositLoanRequest";
export type { SopV1_RegisterHouseholdInformationRequest } from "./models/SopV1_RegisterHouseholdInformationRequest";
export type { SopV1_RegisterMortgagePartsRequest } from "./models/SopV1_RegisterMortgagePartsRequest";
export type { SopV1_RegisterMortgageRequest } from "./models/SopV1_RegisterMortgageRequest";
export type { SopV1_RegisterPropertySaleInformationRequest } from "./models/SopV1_RegisterPropertySaleInformationRequest";
export type { SopV1_RegisterPurchaseInformationRequest } from "./models/SopV1_RegisterPurchaseInformationRequest";
export type { SopV1_RegisterResolveLoansRequest } from "./models/SopV1_RegisterResolveLoansRequest";
export type { SopV1_RegisterSubscriptionRequest } from "./models/SopV1_RegisterSubscriptionRequest";
export type { SopV1_RemoveApplicantRequest } from "./models/SopV1_RemoveApplicantRequest";
export type { SopV1_RequestSignatureRequest } from "./models/SopV1_RequestSignatureRequest";
export type { SopV1_ResidentialRealEstateHouseholdPropertyIndata } from "./models/SopV1_ResidentialRealEstateHouseholdPropertyIndata";
export type { SopV1_ResidentialRealEstateHouseholdPropertyOutdata } from "./models/SopV1_ResidentialRealEstateHouseholdPropertyOutdata";
export type { SopV1_ResolveLoanIndata } from "./models/SopV1_ResolveLoanIndata";
export type { SopV1_ResolveLoanOutdata } from "./models/SopV1_ResolveLoanOutdata";
export type { SopV1_SelfEmploymentIncomeIndata } from "./models/SopV1_SelfEmploymentIncomeIndata";
export type { SopV1_SelfEmploymentIncomeOutdata } from "./models/SopV1_SelfEmploymentIncomeOutdata";
export type { SopV1_SignatureNotFoundDetailOutdata } from "./models/SopV1_SignatureNotFoundDetailOutdata";
export type { SopV1_SignatureNotFoundResponse } from "./models/SopV1_SignatureNotFoundResponse";
export type { SopV1_SignatureResponse } from "./models/SopV1_SignatureResponse";
export type { SopV1_StudyGrantIncomeIndata } from "./models/SopV1_StudyGrantIncomeIndata";
export type { SopV1_StudyGrantIncomeOutdata } from "./models/SopV1_StudyGrantIncomeOutdata";
export type { SopV1_SubscriptionNotFoundDetailOutdata } from "./models/SopV1_SubscriptionNotFoundDetailOutdata";
export type { SopV1_SubscriptionNotFoundResponse } from "./models/SopV1_SubscriptionNotFoundResponse";
export type { SopV1_SubscriptionResponse } from "./models/SopV1_SubscriptionResponse";
export type { SopV1_SupportedAdultdOutdata } from "./models/SopV1_SupportedAdultdOutdata";
export type { SopV1_SupportedAdultIndata } from "./models/SopV1_SupportedAdultIndata";
export type { SopV1_SupportedChildIndata } from "./models/SopV1_SupportedChildIndata";
export type { SopV1_SupportedChildOutdata } from "./models/SopV1_SupportedChildOutdata";
export type { SopV1_TemporaryEmploymentIncomeIndata } from "./models/SopV1_TemporaryEmploymentIncomeIndata";
export type { SopV1_TemporaryEmploymentIncomeOutdata } from "./models/SopV1_TemporaryEmploymentIncomeOutdata";
export type { SopV1_TenancyHouseholdPropertyIndata } from "./models/SopV1_TenancyHouseholdPropertyIndata";
export type { SopV1_TenancyHouseholdPropertyOutdata } from "./models/SopV1_TenancyHouseholdPropertyOutdata";
export type { SopV1_TenantOwnershipApartmentCollateralIndata } from "./models/SopV1_TenantOwnershipApartmentCollateralIndata";
export type { SopV1_TenantOwnershipApartmentHouseholdPropertyIndata } from "./models/SopV1_TenantOwnershipApartmentHouseholdPropertyIndata";
export type { SopV1_TenantOwnershipApartmentHouseholdPropertyOutdata } from "./models/SopV1_TenantOwnershipApartmentHouseholdPropertyOutdata";
export type { SopV1_TenantOwnershipApartmentPropertyOutdata } from "./models/SopV1_TenantOwnershipApartmentPropertyOutdata";
export type { SopV1_TenantOwnershipCollateralIndata } from "./models/SopV1_TenantOwnershipCollateralIndata";
export type { SopV1_TenantOwnershipPropertyOutdata } from "./models/SopV1_TenantOwnershipPropertyOutdata";
export type { SopV1_TopupApplicationResponse } from "./models/SopV1_TopupApplicationResponse";
export type { SopV1_TransferApplicationResponse } from "./models/SopV1_TransferApplicationResponse";
export type { SopV1_UnauthorizedResponse } from "./models/SopV1_UnauthorizedResponse";
export type { SopV1_UpdateApplicantRequest } from "./models/SopV1_UpdateApplicantRequest";
export type { SppV1_ApplicationOutdata } from "./models/SppV1_ApplicationOutdata";
export type { SppV1_BadRequestResponse } from "./models/SppV1_BadRequestResponse";
export type { SppV1_BorrowerOutdata } from "./models/SppV1_BorrowerOutdata";
export type { SppV1_ForbiddenResponse } from "./models/SppV1_ForbiddenResponse";
export type { SppV1_InternalServerErrorResponse } from "./models/SppV1_InternalServerErrorResponse";
export type { SppV1_PaginatedApplicationResponse } from "./models/SppV1_PaginatedApplicationResponse";
export type { SppV1_UnauthorizedResponse } from "./models/SppV1_UnauthorizedResponse";
export type { StabeloV2_AcknowledgeMessageRequest } from "./models/StabeloV2_AcknowledgeMessageRequest";
export type { StabeloV2_AmortizationRequirementBasisDialogueResponse } from "./models/StabeloV2_AmortizationRequirementBasisDialogueResponse";
export type { StabeloV2_AttachmentOutdata } from "./models/StabeloV2_AttachmentOutdata";
export type { StabeloV2_CreateAmortizationRequirementBasisDialogueRequest } from "./models/StabeloV2_CreateAmortizationRequirementBasisDialogueRequest";
export type { StabeloV2_InterestTermResetDialogueResponse } from "./models/StabeloV2_InterestTermResetDialogueResponse";
export type { StabeloV2_NotificationMessageOutdata } from "./models/StabeloV2_NotificationMessageOutdata";
export type { StaggeredScheduleOutdata } from "./models/StaggeredScheduleOutdata";
export type { TaskResponse } from "./models/TaskResponse";
export type { UnauthorizedResponse } from "./models/UnauthorizedResponse";

export { AvanzaApiV1Service } from "./services/AvanzaApiV1Service";
export { BudIdApiV1Service } from "./services/BudIdApiV1Service";
export { ComponentsApiV1Service } from "./services/ComponentsApiV1Service";
export { HecateApiV1Service } from "./services/HecateApiV1Service";
export { LanaPengarApiV1Service } from "./services/LanaPengarApiV1Service";
export { LiftoffIrisService } from "./services/LiftoffIrisService";
export { SDerbergPartnersApiV1Service } from "./services/SDerbergPartnersApiV1Service";
export { SppApiV1Service } from "./services/SppApiV1Service";
export { StabeloApiV2Service } from "./services/StabeloApiV2Service";
