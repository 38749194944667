import { TextField, TextFieldProps } from "@mui/material";
import * as React from "react";
import { IMaskMixin } from "react-imask";

export type IMaskedTextFieldProps = Omit<TextFieldProps, "onChange"> & {
	onChange?: (value: string, isValid?: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const MaskedTextField = IMaskMixin(({ inputRef, currentValue, ...otherProps }: any) => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	if ("defaultValue" in otherProps && otherProps.defaultValue === "") {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		delete otherProps.defaultValue;
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	return <TextField {...otherProps} inputRef={inputRef} value={currentValue} />;
});
