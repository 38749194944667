import { isNotNull, isTrue } from "@stabelo/validation-library";

import { Amount } from "./Amount";
import { Percentage } from "./Percentage";
import { SerializedInterestRate } from "./SerializedInterestRate";

export class InterestRate extends Percentage {
	public static fromJSON(json: SerializedInterestRate): InterestRate {
		return new InterestRate(json.bps);
	}

	public static fromBps(bps: number): InterestRate {
		return new InterestRate(bps);
	}

	public static fromDecimal(decimal: number): InterestRate {
		return new InterestRate(Math.round(isNotNull(decimal, "Cannot call fromDecimal with null value.") * 10000));
	}

	public static fromPercentage(percentage: number): InterestRate {
		return new InterestRate(Math.round(isNotNull(percentage, "Cannot call fromPercentage with null value.") * 100));
	}

	public static max(...amounts: InterestRate[]): InterestRate {
		return amounts.reduce((max: InterestRate | undefined, amount: InterestRate) => {
			if (max === undefined) {
				return amount;
			}
			return max.isGreaterOrEqualThan(amount) ? max : amount;
		});
	}

	public static min(...amounts: InterestRate[]): InterestRate {
		return amounts.reduce((min: InterestRate | undefined, amount: InterestRate) => {
			if (min === undefined) {
				return amount;
			}
			return min.isLessOrEqualThan(amount) ? min : amount;
		});
	}

	constructor(bps: number) {
		isTrue(bps < 10000, `Interest rate cannot be above 100%, was ${bps}.`);
		super(bps);
	}

	public add(percentageToAdd: Percentage): InterestRate {
		return new InterestRate(super.add(percentageToAdd).toBps());
	}

	public subtract(percentageToSubtract: Percentage): InterestRate {
		return new InterestRate(super.subtract(percentageToSubtract).toBps());
	}

	public yearlyAccrued(amount: Amount): Amount {
		// Assumes 12 - 30 interest model
		return super.ofAmount(amount);
	}

	public monthlyAccrued(amount: Amount): Amount {
		// Assumes 12 - 30 interest model
		return this.yearlyAccrued(amount).multiply(1 / 12);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public accruedBetween(_amount: Amount, _from: Date, _until: Date): Amount {
		throw new Error("Not implemented.");
	}

	public toString(): string {
		return (this.bps / 10000).toLocaleString("sv-SE", {
			style: "percent",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		});
	}
}
