import { Box, ButtonProps } from "@mui/material";
import Lottie, { LottieRef } from "lottie-react";
import * as React from "react";

import { stabeloTheme } from "../../../theme-config";
import * as arrowForwardAnimation from "../../lottie/button-arrow.json";

import { PrimaryButton } from "./PrimaryButton";

interface ILottieInteractionReference {
	play: () => void;
	stop: () => void;
}

export const ApplyButton = (props: ButtonProps) => {
	const lottieRef = React.useRef() as LottieRef;

	const playButtonArrowAnimation = () => {
		if (lottieRef !== null && lottieRef.current !== null) {
			const lottie = lottieRef.current as unknown as ILottieInteractionReference;
			lottie.stop();
			lottie.play();
		}
	};

	return (
		<PrimaryButton
			{...props}
			endIcon={
				<Box
					sx={{
						width: "1.67em",
						height: "1.67em",
						fontSize: "1vw !important",
						[stabeloTheme.breakpoints.up("xl")]: {
							fontSize: "16px !important",
						},
						[stabeloTheme.breakpoints.down("md")]: {
							width: "24px",
							height: "24px",
						},
					}}
				>
					<Lottie
						lottieRef={lottieRef}
						animationData={arrowForwardAnimation}
						loop={false}
						autoplay={false}
						rendererSettings={{
							preserveAspectRatio: "xMidYMid slice",
						}}
					/>
				</Box>
			}
			onMouseEnter={playButtonArrowAnimation}
			onMouseLeave={playButtonArrowAnimation}
		></PrimaryButton>
	);
};
