import { isBetween, isInteger, isNumber } from "@stabelo/validation-library";

export class MonetaryValue {
	public static fromDecimal(decimal: number): MonetaryValue {
		const n = isBetween(isNumber(decimal), Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);

		if (n > -1e-6 && n < 1e-6) return new MonetaryValue(0);

		const [wholePart, decimalPart] = String(n).split(".") as [string, string | undefined];

		return new MonetaryValue(Number.parseInt(wholePart.concat((decimalPart ?? "").padEnd(2, "0").substring(0, 2))));
	}

	public static fromValue(value: number): MonetaryValue {
		return new MonetaryValue(value);
	}

	private readonly value: number;

	constructor(value: number) {
		this.value = isInteger(value, `${value} is not a valid monetary value.`);
	}

	public add(valueToAdd: MonetaryValue): MonetaryValue {
		return new MonetaryValue(this.value + valueToAdd.value);
	}

	public subtract(valueToSubtract: MonetaryValue): MonetaryValue {
		return new MonetaryValue(this.value - valueToSubtract.value);
	}

	public multiply(multiplicant: number): MonetaryValue {
		return MonetaryValue.fromDecimal((this.value * multiplicant) / 100);
	}

	public divide(valueToDivideWith: MonetaryValue): number {
		return this.value === 0 ? 0 : this.value / valueToDivideWith.value;
	}

	public get isZero(): boolean {
		return this.value === 0;
	}

	public get isNegative(): boolean {
		return this.value < 0;
	}

	public get isPositive(): boolean {
		return this.value > 0;
	}

	public get isNegativeOrZero(): boolean {
		return this.value <= 0;
	}

	public get isPositiveOrZero(): boolean {
		return this.value >= 0;
	}

	public isLessThan(valueToCompareTo: MonetaryValue): boolean {
		return this.value < valueToCompareTo.value;
	}

	public isLessOrEqualThan(valueToCompareTo: MonetaryValue): boolean {
		return this.value <= valueToCompareTo.value;
	}

	public isGreaterThan(valueToCompareTo: MonetaryValue): boolean {
		return this.value > valueToCompareTo.value;
	}

	public isGreaterOrEqualThan(valueToCompareTo: MonetaryValue): boolean {
		return this.value >= valueToCompareTo.value;
	}

	public isEqualTo(valueToCompareTo: MonetaryValue): boolean {
		return this.value === valueToCompareTo.value;
	}

	public toDecimal(): number {
		return this.value / 100;
	}

	public toJSON(): number {
		return this.value;
	}
}
