
type TEnvironment = "production" | "stage" | "test" | "beta" | "local";

interface IConfiguration {
    default: any;
    production?: any;
    stage?: any;
    test?: any;
    local?: any;
}

class ConfigurationHelper {

    public static load(configuration: IConfiguration) {
        ConfigurationHelper.configuration = configuration;
    }

    public static get<T>(property: string, forcedEnvironment?: TEnvironment): T {
        if (ConfigurationHelper.configuration === undefined) {
            throw new Error("Configuration is not loaded into the helper.");
        }

        const environment = forcedEnvironment || ConfigurationHelper.environment();
        const configurations = [ "default", environment ] as TEnvironment[];
        const parts = property.split(".");
        const value = configurations.reduce((previous, configuration) => {
            let current = ConfigurationHelper.configuration[configuration];

            parts.forEach((part) => {
                current = (current === undefined || !current.hasOwnProperty(part)) ? undefined : current[part];
            });

            if (current === undefined) {
                return previous;
            }
            if (previous === undefined) {
                return current;
            }
            if (Array.isArray(previous) && Array.isArray(current)) {
                return current.concat(previous);
            }
            if (typeof previous === "object" && typeof current === "object") {
                return {...(previous || {}), ...current};
            }

            return current;
        }, undefined as T);

        if (value === undefined) {
            throw new Error(`Property ${property} not found, is not defined for ${environment}.`);
        }

        return value;
    }

    private static configuration: any;

    private static environment(): TEnvironment {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;

        if (protocol === "https:") {
            if (hostname.endsWith("stabelo.se") || hostname.endsWith("stabelo.com")) {
                return "production";
            } else if (hostname.endsWith("stabelo.stage")) {
                return "stage";
            } else if (hostname.endsWith("stabelo.test")) {
                return "test";
            } else if (hostname.endsWith("stabelo.beta")) {
                return "beta";
            }
        }

        return "local";
    }
}

export default ConfigurationHelper;
