import * as decode from "jwt-decode";

import SessionToken from "./SessionToken";

type ICallback = (token: SessionToken) => void;

class Session {
    private static readonly EXPIRE_OFFSET = 15000;
    private static parseToken(token: string): SessionToken {
        const decoded = decode(token) as any;

        return new SessionToken(decoded.id, decoded.name, decoded.realm, decoded.iss, new Date(parseInt(decoded.exp, 10) * 1000), new Date(parseInt(decoded.iat, 10) * 1000), token, decoded.userId);
    }

    public readonly token: SessionToken;
    private readonly expireTimerHandle: number;
    private readonly onExpireCallbacks: ICallback[];
    private readonly onDestroyCallbacks: ICallback[];

    constructor(token: string) {
        this.token = Session.parseToken(token);
        this.expireTimerHandle = window.setTimeout(() => this.onExpireCallbacks.forEach((callback) => callback(this.token)), this.token.expireAt.valueOf() - Date.now() - Session.EXPIRE_OFFSET);
        this.onExpireCallbacks = [];
        this.onDestroyCallbacks = [];
    }

    public destroy() {
        window.clearTimeout(this.expireTimerHandle);

        this.onDestroyCallbacks.forEach((callback) => callback(this.token));
    }

    public onExpire(callback: ICallback) {
        this.onExpireCallbacks.push(callback);
    }

    public onDestroy(callback: ICallback) {
        this.onDestroyCallbacks.push(callback);
    }
}

export default Session;
