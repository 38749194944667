import { isLiteral, isNotNull, isTrue } from "@stabelo/validation-library";

import { CurrencyCode, currencyCodes } from "./CurrencyCode";
import { MonetaryValue } from "./MonetaryValue";
import { SerializedAmount } from "./SerializedAmount";

export class Amount {
	public static fromDecimal(decimal: number, currency: CurrencyCode): Amount {
		return new Amount(MonetaryValue.fromDecimal(decimal), currency);
	}

	public static fromValue(value: number, currency: CurrencyCode): Amount {
		return new Amount(MonetaryValue.fromValue(value), currency);
	}

	public static fromJSON(json: SerializedAmount): Amount {
		return new Amount(MonetaryValue.fromValue(json.value), json.currency);
	}

	public static max(...amounts: Amount[]): Amount {
		return amounts.reduce((max: Amount | undefined, amount: Amount) => {
			if (max === undefined) {
				return amount;
			}
			return max.isGreaterOrEqualThan(amount) ? max : amount;
		});
	}

	public static min(...amounts: Amount[]): Amount {
		return amounts.reduce((min: Amount | undefined, amount: Amount) => {
			if (min === undefined) {
				return amount;
			}
			return min.isLessOrEqualThan(amount) ? min : amount;
		});
	}

	private readonly value: MonetaryValue;
	public readonly currency: CurrencyCode;

	constructor(value: MonetaryValue, currency: CurrencyCode) {
		this.value = isNotNull(value);
		this.currency = isLiteral(currency, currencyCodes);
	}

	public add(amount: Amount): Amount {
		isTrue(
			this.currency === amount.currency,
			`Cannot perform addition on different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return new Amount(this.value.add(amount.value), this.currency);
	}

	public subtract(amount: Amount): Amount {
		isTrue(
			this.currency === amount.currency,
			`Cannot perform subtraction on different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return new Amount(this.value.subtract(amount.value), this.currency);
	}

	public multiply(multiplicator: number): Amount {
		return new Amount(this.value.multiply(multiplicator), this.currency);
	}

	public factor(amount: Amount): number {
		isTrue(
			this.currency === amount.currency,
			`Cannot calculate factor on amounts of different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return this.value.divide(amount.value);
	}

	public get isZero(): boolean {
		return this.value.isZero;
	}

	public get isNegative(): boolean {
		return this.value.isNegative;
	}

	public get isPositive(): boolean {
		return this.value.isPositive;
	}

	public get isNegativeOrZero(): boolean {
		return this.value.isNegativeOrZero;
	}

	public get isPositiveOrZero(): boolean {
		return this.value.isPositiveOrZero;
	}

	public isLessThan(amount: Amount): boolean {
		isTrue(
			this.currency === amount.currency,
			`Cannot compare amount in different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return this.value.isLessThan(amount.value);
	}

	public isLessOrEqualThan(amount: Amount): boolean {
		isTrue(
			this.currency === amount.currency,
			`Cannot compare amount in different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return this.value.isLessOrEqualThan(amount.value);
	}

	public isGreaterThan(amount: Amount): boolean {
		isTrue(
			this.currency === amount.currency,
			`Cannot compare amount in different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return this.value.isGreaterThan(amount.value);
	}

	public isGreaterOrEqualThan(amount: Amount): boolean {
		isTrue(
			this.currency === amount.currency,
			`Cannot compare amount in different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return this.value.isGreaterOrEqualThan(amount.value);
	}

	public isEqualTo(amount: Amount): boolean {
		isTrue(
			this.currency === amount.currency,
			`Cannot compare amount in different currencies, got ${this.currency} and ${amount.currency}.`,
		);
		return this.value.isEqualTo(amount.value);
	}

	public equals(amount: Amount | undefined): boolean {
		return this.compareWith(amount) === 0;
	}

	public compareWith(amount: Amount | undefined): number {
		if (amount === undefined) {
			return 1;
		}
		if (this.currency !== amount.currency) {
			return this.currency.localeCompare(amount.currency);
		}
		return this.value.subtract(amount.value).toDecimal();
	}

	public toDecimal(): number {
		return this.value.toDecimal();
	}

	public toString(decimalPoints = 2): string {
		return this.value
			.toDecimal()
			.toLocaleString("sv-SE", {
				style: "currency",
				maximumFractionDigits: decimalPoints,
				minimumFractionDigits: decimalPoints,
				currency: this.currency,
			})
			.replace("Skr", "kr");
	}

	public toJSON() {
		return {
			value: this.value.toJSON(),
			currency: this.currency,
			display: this.toString(),
			display_short: this.toString(0),
		};
	}
}
