import { ConfigurationHelper, HttpInterface, RouterBuilder } from "@stabelo/awesome-react-infrastructure-library";
import * as React from "react";
import * as ReactDOM from "react-dom";

import "./index.scss";
import Configuration from "./config/application.json";
import { LanaPengarApiV1Service } from "./generated/iris";
import { ComponentsApiV1Service } from "./generated/iris/services/ComponentsApiV1Service";
import App from "./src/App";
import { IRoute, routes } from "./src/routes";

interface IConfiguration {
	default: any;
	production?: any;
	stage?: any;
	test?: any;
	beta?: any;
	local?: any;
}

ConfigurationHelper.load(Configuration as unknown as IConfiguration);

ComponentsApiV1Service.setUrl(ConfigurationHelper.get("api.url"));
LanaPengarApiV1Service.setUrl(ConfigurationHelper.get("api.url"));

HttpInterface.defaultHeaders({
	"Accept": "application/json",
	"Content-Type": "application/json",
})
	.requestMode("cors")
	.cachingMode("default");

const init = async () => {
	const routeBuilder = new RouterBuilder();
	routes.forEach((r: IRoute) => routeBuilder.addRoute(r));

	const applicationRoot = document.getElementById("app");
	void routeBuilder
		.withRenderFunction(
			(component: React.ReactElement<any>) =>
				new Promise((resolve) => {
					ReactDOM.render(<App>{component}</App>, applicationRoot, resolve);
				}),
		)
		.withRefreshFunction(
			(component: React.ReactElement<any>) =>
				new Promise((resolve) => {
					ReactDOM.hydrate(<App>{component}</App>, applicationRoot, resolve);
				}),
		)
		.build()
		.initialize();
};

try {
	void init();
} catch (e) {
	console.error("Something went wrong when starting the app..", e);
	throw e;
}
