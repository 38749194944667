class HttpInterface {
    private static readonly AUTHORIZATION_HEADER = "Authorization";

    private caching: RequestCache = "default";
    private mode: RequestMode = "cors";
    private readonly globalHeaders: Record<string, string> = {};

    public defaultHeaders(headers: Record<string, string>): HttpInterface {
        Object.keys(headers).forEach((key) => {
            this.globalHeaders[key] = headers[key];
        });
        return this;
    }

    public authorizationToken(token: string): HttpInterface {
        this.globalHeaders[HttpInterface.AUTHORIZATION_HEADER] = `Bearer ${token}`;
        return this;
    }

    public cachingMode(mode: RequestCache): HttpInterface {
        this.caching = mode;
        return this;
    }

    public requestMode(mode: RequestMode): HttpInterface {
        this.mode = mode;
        return this;
    }

    public fetch(input: RequestInfo, init: RequestInit = {}): Promise<Response> {
        const headers = { ...this.globalHeaders };

        if (!!init.headers) {
            if (init.headers instanceof Headers) {
                init.headers.forEach((value, key) => {
                    headers[key] = value;
                });
            } else {
                Object.keys(init.headers).forEach((key) => {
                    headers[key] = init.headers[key];
                });
            }
        }
        if (!init.cache) {
            init.cache = this.caching;
        }
        if (!init.mode) {
            init.mode = this.mode;
        }
        if (!init.credentials) {
            init.credentials = "include";
        }

        init.headers = headers;

        return fetch(input, init).then((response) => {
            if (response.status >= 400) {
                return Promise.reject(response);
            } else {
                return Promise.resolve(response);
            }
        });
    }

}

export default new HttpInterface();
