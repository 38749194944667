{
		"v": "5.7.6",
		"fr": 60,
		"ip": 31,
		"op": 616,
		"w": 1000,
		"h": 1000,
		"nm": "räntor-animation",
		"ddd": 0,
		"assets": [
			{
				"id": "comp_0",
				"layers": [
					{
						"ddd": 0,
						"ind": 1,
						"ty": 3,
						"nm": "Null 138",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [960, 540, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 2,
						"ty": 4,
						"nm": "Vector",
						"parent": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [-104.209, 101.947, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[19.371, -19.371],
													[-19.371, -19.371],
													[-19.371, 19.371],
													[19.371, 19.371]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Vector",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 3,
						"ty": 4,
						"nm": "Vector",
						"parent": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [49.822, 101.947, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[19.371, -19.371],
													[-19.371, -19.371],
													[-19.371, 19.371],
													[19.371, 19.371]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "rd",
										"nm": "Round Corners 1",
										"r": { "a": 0, "k": 19.371, "ix": 1 },
										"ix": 2,
										"mn": "ADBE Vector Filter - RC",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Vector",
								"np": 3,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 4,
						"ty": 4,
						"nm": "Vector",
						"parent": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [204.209, 128.318, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[19.178, -38.357],
													[-19.178, -38.357],
													[-19.178, 38.357],
													[19.178, 38.357]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Vector",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 5,
						"ty": 4,
						"nm": "Vector",
						"parent": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [203.901, -28.15, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[-57.914, 39.511],
													[-0.152, -39.511],
													[57.914, 39.511]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458086133003, 0.379166662693, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Vector",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 6,
						"ty": 4,
						"nm": "Vector",
						"parent": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [11.158, -28.318, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[144.405, -39.383],
													[-83.31, -39.821],
													[-144.405, 39.821],
													[87.518, 39.821]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392163992, 0.435294121504, 0.352941185236, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Vector",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 7,
						"ty": 4,
						"nm": "Vector",
						"parent": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [-27.35, 101.791, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[115.524, -57.762],
													[-115.524, -57.762],
													[-115.524, 57.762],
													[115.524, 57.762]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Vector",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 8,
						"ty": 4,
						"nm": "Vector",
						"parent": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [203.901, 101.75, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[57.914, -57.914],
													[-57.914, -57.914],
													[-57.914, 57.914],
													[57.914, 57.914]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.831372559071, 0.23137255013, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Vector",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1868,
						"op": 5354,
						"st": 1754,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 9,
						"ty": 3,
						"nm": "Null 21",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 654, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 714, "s": [180] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 766, "s": [180] },
									{ "t": 826, "s": [360] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [960, 540, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [127, 127, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 537,
						"op": 734,
						"st": 537,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 10,
						"ty": 4,
						"nm": "Layer 20",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 614,
										"s": [-36.993, 108.472, 0],
										"to": [0, 23.622, 0],
										"ti": [0, -23.622, 0]
									},
									{ "t": 644, "s": [-36.993, 250.205, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0.014, 29.375, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 560,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.833, 88.111],
																[28.944, 88.111]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.833, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 580,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.889, -28.889],
																[28.889, -28.889]
															],
															"c": true
														}
													]
												},
												{
													"t": 614,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.889, -28.889],
																[28.889, -28.889]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 560,
						"op": 645,
						"st": 604,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 11,
						"ty": 4,
						"nm": "Layer 22",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 578,
										"s": [231.481, 99.097, 0],
										"to": [0, -3.333, 0],
										"ti": [0, 3.333, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.054, "y": 0.054 },
										"t": 598,
										"s": [231.481, 79.097, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 624,
										"s": [231.481, 79.097, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 644, "s": [231.481, -15.391, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 578,
										"s": [60, 60, 100]
									},
									{
										"i": { "x": [0.59, 0.59, 0.59], "y": [1, 1, 1] },
										"o": { "x": [0.164, 0.164, 0.164], "y": [0, 0, 0] },
										"t": 598,
										"s": [100, 100, 100]
									},
									{ "t": 614, "s": [100, 100, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[28.889, 28.889],
													[-28.889, 28.889],
													[-28.889, -28.889],
													[28.889, -28.889]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							},
							{
								"ty": "rd",
								"nm": "Round Corners 1",
								"r": { "a": 0, "k": 335, "ix": 1 },
								"ix": 2,
								"mn": "ADBE Vector Filter - RC",
								"hd": false
							}
						],
						"ip": 578,
						"op": 631,
						"st": 631,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 12,
						"ty": 4,
						"nm": "Layer 19",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 571,
										"s": [136.993, 99.097, 0],
										"to": [0, -3.333, 0],
										"ti": [0, 3.333, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.054, "y": 0.054 },
										"t": 591,
										"s": [136.993, 79.097, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 614,
										"s": [136.993, 79.097, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 634, "s": [136.993, -15.391, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 571,
										"s": [60, 60, 100]
									},
									{
										"i": { "x": [0.59, 0.59, 0.59], "y": [1, 1, 1] },
										"o": { "x": [0.164, 0.164, 0.164], "y": [0, 0, 0] },
										"t": 591,
										"s": [100, 100, 100]
									},
									{ "t": 614, "s": [100, 100, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[28.889, 28.889],
													[-28.889, 28.889],
													[-28.889, -28.889],
													[28.889, -28.889]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							},
							{
								"ty": "rd",
								"nm": "Round Corners 1",
								"r": { "a": 0, "k": 335, "ix": 1 },
								"ix": 2,
								"mn": "ADBE Vector Filter - RC",
								"hd": false
							}
						],
						"ip": 571,
						"op": 631,
						"st": 624,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 13,
						"ty": 4,
						"nm": "Layer 21",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 614, "s": [0] },
									{ "t": 644, "s": [90] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.833, "y": 0.833 },
										"o": { "x": 0.167, "y": 0.167 },
										"t": 614,
										"s": [136.993, 79.097, 0],
										"to": [-0.394, 7.48, 0],
										"ti": [0.394, -7.48, 0]
									},
									{ "t": 644, "s": [134.631, 123.979, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 560,
										"s": [90, 90, 100]
									},
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 580,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 614,
										"s": [100, 100, 100]
									},
									{ "t": 644, "s": [68, 68, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823529412, 0.380392156863, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 682,
						"op": 734,
						"st": 604,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 14,
						"ty": 4,
						"nm": "Layer 18",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 614, "s": [0] },
									{ "t": 644, "s": [90] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.23, "y": 1 },
										"o": { "x": 0.77, "y": 0 },
										"t": 614,
										"s": [174.001, 79.097, 0],
										"to": [-6.562, 7.48, 0],
										"ti": [6.562, -7.48, 0]
									},
									{ "t": 644, "s": [134.631, 123.979, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 560,
										"s": [90, 90, 100]
									},
									{
										"i": { "x": [0.473, 0.473, 0.473], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 580,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.352, 0.352, 0.352], "y": [0, 0, 0] },
										"t": 614,
										"s": [144, 100, 100]
									},
									{ "t": 644, "s": [68, 68, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392156863, 0.435294117647, 0.352941176471, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 614,
						"op": 682,
						"st": 604,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 15,
						"ty": 4,
						"nm": "Layer 17",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [50.326, 165.764, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [-86.667, 86.667, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.473, 0.473, 0.473], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 560,
										"s": [90, 90, 100]
									},
									{
										"i": { "x": [0.473, 0.473, 0.473], "y": [1, 1, 1] },
										"o": { "x": [0.352, 0.352, 0.352], "y": [0, 0, 0] },
										"t": 589,
										"s": [144, 100, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.352, 0.352, 0.352], "y": [0, 0, 0] },
										"t": 614,
										"s": [144, 100, 100]
									},
									{ "t": 634, "s": [59, 59, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392156863, 0.435294117647, 0.352941176471, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 560,
						"op": 614,
						"st": 604,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 16,
						"ty": 4,
						"nm": "Layer 16",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0], "y": [1] }, "o": { "x": [0.05], "y": [0] }, "t": 532, "s": [180] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 572, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 616, "s": [0] },
									{ "t": 721, "s": [360] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 532,
										"s": [-36.34, 53.264, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 552,
										"s": [-36.34, -116.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.05, "y": 0.05 },
										"t": 572,
										"s": [-36.34, -66.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 614,
										"s": [-36.34, -66.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 644,
										"s": [-36.34, -208.468, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 674, "s": [-36.34, -19.492, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 532,
										"s": [0, 0, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 552,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 644,
										"s": [100, 100, 100]
									},
									{ "t": 658, "s": [0, 0, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[0, -59.167],
													[-86.667, 59.167],
													[86.667, 59.167]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823531866, 0.380392163992, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 531,
						"op": 734,
						"st": 532,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 17,
						"ty": 4,
						"nm": "Rectangle 2159",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [0.594] }, "o": { "x": [0.78], "y": [0] }, "t": 500, "s": [180] },
									{ "i": { "x": [0.594], "y": [1] }, "o": { "x": [0.212], "y": [0.113] }, "t": 537, "s": [293] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 560, "s": [360] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 614, "s": [360] },
									{ "t": 653, "s": [180] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 500,
										"s": [121.129, 120.079, 0],
										"to": [-39.443, -37.706, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 546,
										"s": [-115.531, -106.156, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.167, "y": 0.167 },
										"t": 560,
										"s": [-35.459, 79.137, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 614,
										"s": [-35.459, 79.137, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 653,
										"s": [-35.459, -172.832, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 673, "s": [-35.459, 89.373, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 546,
										"s": [78.74, 78.74, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 560,
										"s": [135.433, 135.433, 100]
									},
									{ "t": 653, "s": [102.362, 102.362, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 653,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[48, -48],
																[48, 48],
																[-48, 48],
																[-48, -48]
															],
															"c": true
														}
													]
												},
												{
													"t": 673,
													"s": [
														{
															"i": [
																[-37.279, 0],
																[0, -37.279],
																[37.279, 0],
																[0, 37.279]
															],
															"o": [
																[37.279, 0],
																[0, 37.279],
																[-37.279, 0],
																[0, -37.279]
															],
															"v": [
																[5.684, 25.706],
																[73.184, 93.206],
																[5.684, 160.706],
																[-61.816, 93.206]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 613,
						"op": 734,
						"st": 500,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 18,
						"ty": 4,
						"nm": "Layer 15",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [93.66, -66.736, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.833, "y": 0.833 },
													"o": { "x": 0.05, "y": 0 },
													"t": 567,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-43.078, 59.417],
																[-43.333, 59.167],
																[-130, -59.167],
																[-129.745, -58.917]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.833, "y": 0.833 },
													"o": { "x": 0.167, "y": 0.167 },
													"t": 586,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[207.234, 59.231],
																[-214.687, 58.917],
																[-130, -59.167],
																[120.567, -59.102]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.833, "y": 1 },
													"o": { "x": 0.167, "y": 0.167 },
													"t": 614,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[207.234, 59.231],
																[-214.687, 58.917],
																[-130, -59.167],
																[120.567, -59.102]
															],
															"c": true
														}
													]
												},
												{
													"t": 624,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[130, 59.167],
																[-214.833, 58.917],
																[-129.621, 58.944],
																[43.712, 58.944]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 560,
						"op": 734,
						"st": 736,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 19,
						"ty": 4,
						"nm": "Rectangle 2158",
						"parent": 9,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [51.813, 43.4, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [78.74, 78.74, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 618,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-20.091, -30.804],
																[-20.091, 31.343],
																[-20.253, 31.174],
																[-20.253, -30.973]
															],
															"c": true
														}
													]
												},
												{
													"t": 648,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[165.47, -31.074],
																[165.47, 31.074],
																[-165.47, 31.074],
																[-165.47, -31.074]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": -45.747, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2146",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 618,
						"op": 734,
						"st": 618,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 20,
						"ty": 3,
						"nm": "Null 85",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.5, "y": 1 },
										"o": { "x": 0.5, "y": 0 },
										"t": 2422,
										"s": [1158.991, 591.285, 0],
										"to": [-32.333, -4.333, 0],
										"ti": [32.333, 4.333, 0]
									},
									{ "t": 2442, "s": [964.991, 565.285, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [272, 272, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 2422,
						"op": 2808,
						"st": -293,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 21,
						"ty": 4,
						"nm": "Rött tak 3",
						"parent": 20,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0], "y": [1] }, "o": { "x": [0.05], "y": [0] }, "t": 2449.5, "s": [180] },
									{ "t": 2477, "s": [0] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 2449.5,
										"s": [10.133, 51.765, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 2463.25,
										"s": [10.133, -26.435, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 2477, "s": [10.133, -3.435, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 2449.5,
										"s": [0, 0, 100]
									},
									{ "t": 2463.25, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[0, -59.167],
													[-86.667, 59.167],
													[86.667, 59.167]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823531866, 0.380392163992, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2449,
						"op": 3187,
						"st": 2462,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 22,
						"ty": 4,
						"nm": "Blått tak 3",
						"parent": 20,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [69.933, -3.435, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [46, 46, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.833, "y": 0.758 },
													"o": { "x": 0.5, "y": 0 },
													"t": 2463.938,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-43.078, 59.417],
																[-43.333, 59.167],
																[-130, -59.167],
																[-129.745, -58.917]
															],
															"c": true
														}
													]
												},
												{
													"t": 2477,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[129.396, 59.168],
																[-43.5, 59.124],
																[-129.961, -59.418],
																[42.73, -59.166]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.786652149874, 0.894203515146, 0.963343721278, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2463,
						"op": 3187,
						"st": 2639,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 23,
						"ty": 4,
						"nm": "Gul vägg 3",
						"parent": 20,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.96], "y": [1] }, "o": { "x": [1], "y": [0] }, "t": 2422, "s": [-90] },
									{ "t": 2449.5, "s": [0] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 1, "y": 0 },
										"t": 2422,
										"s": [10.133, 64.572, 0],
										"to": [0, -10.121, 0],
										"ti": [0, 0.154, 0]
									},
									{
										"i": { "x": 0.96, "y": 1 },
										"o": { "x": 1, "y": 0 },
										"t": 2435.75,
										"s": [10.133, 3.849, 0],
										"to": [0, -0.154, 0],
										"ti": [0, -9.967, 0]
									},
									{ "t": 2449.5, "s": [10.133, 63.649, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 2430,
										"s": [40, 40, 100]
									},
									{ "t": 2433, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 2422,
													"s": [
														{
															"i": [
																[0, -46.668],
																[46.668, 0],
																[0, 0],
																[0, 46.668],
																[-46.668, 0],
																[0, 0]
															],
															"o": [
																[0, 46.668],
																[0, 0],
																[-46.668, 0],
																[0, -46.668],
																[0, 0],
																[46.668, 0]
															],
															"v": [
																[87.033, 0.012],
																[2.533, 84.512],
																[3.609, 84.045],
																[-80.891, -0.455],
																[3.609, -84.955],
																[2.533, -84.488]
															],
															"c": true
														}
													]
												},
												{
													"t": 2443.3125,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[86.667, 86.667],
																[0, 86.667],
																[-86.667, 86.667],
																[-86.667, 0],
																[-86.667, -86.667],
																[86.667, -86.667]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2422,
						"op": 3187,
						"st": 2422,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 24,
						"ty": 4,
						"nm": "Fönster 3",
						"parent": 20,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 2450.188,
										"s": [89.867, 72.849, 0],
										"to": [0, -1.533, 0],
										"ti": [0, 1.533, 0]
									},
									{ "t": 2463.9375, "s": [89.867, 63.649, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 2450.188,
										"s": [27.6, 27.6, 100]
									},
									{ "t": 2463.9375, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[28.889, 28.889],
													[-28.889, 28.889],
													[-28.889, -28.889],
													[28.889, -28.889]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							},
							{
								"ty": "rd",
								"nm": "Round Corners 1",
								"r": { "a": 0, "k": 335, "ix": 1 },
								"ix": 2,
								"mn": "ADBE Vector Filter - RC",
								"hd": false
							}
						],
						"ip": 2449,
						"op": 3187,
						"st": 2507,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 25,
						"ty": 4,
						"nm": "Grön vägg 3",
						"parent": 20,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [50, 103.515, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [-86.667, 86.667, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 2448.812,
										"s": [0, 0, 100]
									},
									{ "t": 2462.5625, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392163992, 0.435294121504, 0.352941185236, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2447,
						"op": 3187,
						"st": 2505,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 26,
						"ty": 3,
						"nm": "Null 137",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 500, "s": [0] },
									{ "t": 560, "s": [226] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [958.5, 535, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 545,
										"s": [100, 100, 100]
									},
									{ "t": 560, "s": [94, 94, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"ip": 500,
						"op": 4100,
						"st": 500,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 27,
						"ty": 3,
						"nm": "Null 137",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 500, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.479], "y": [0] }, "t": 560, "s": [180] },
									{ "t": 590, "s": [180] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [958.5, 535, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 500,
						"op": 4100,
						"st": 500,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 28,
						"ty": 4,
						"nm": "Rectangle 2157",
						"parent": 27,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [0.594] }, "o": { "x": [0.78], "y": [0] }, "t": 500, "s": [0] },
									{ "i": { "x": [0.594], "y": [1] }, "o": { "x": [0.212], "y": [0.113] }, "t": 537, "s": [113] },
									{ "t": 560, "s": [180] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 500,
										"s": [-41.833, -44, 0],
										"to": [50.093, 47.886, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 546,
										"s": [258.725, 243.318, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 560, "s": [157.033, 7.996, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 546,
										"s": [100, 100, 100]
									},
									{ "t": 560, "s": [172, 172, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ty": "rc",
										"d": 1,
										"s": { "a": 0, "k": [96, 96], "ix": 2 },
										"p": { "a": 0, "k": [0, 0], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 4 },
										"nm": "Rectangle Path 1",
										"mn": "ADBE Vector Shape - Rect",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.831372608858, 0.231372563979, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 525,
						"op": 613,
						"st": 500,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 29,
						"ty": 4,
						"nm": "Rectangle 2156",
						"parent": 26,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [48.302, 64.118, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[165.47, -31.074],
													[165.47, 31.074],
													[-165.47, 31.074],
													[-165.47, -31.074]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": -45.747, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2146",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 500,
						"op": 614,
						"st": 500,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 30,
						"ty": 4,
						"nm": "Rectangle 2155",
						"parent": 27,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 500, "s": [0] },
									{ "t": 560, "s": [90] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 500,
										"s": [-56.333, -39, 0],
										"to": [32.04, 30.291, 0],
										"ti": [-32.04, -30.291, 0]
									},
									{ "t": 546, "s": [135.907, 142.748, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [117, 117, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ty": "rc",
										"d": 1,
										"s": { "a": 0, "k": [96, 96], "ix": 2 },
										"p": { "a": 0, "k": [0, 0], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 4 },
										"nm": "Rectangle Path 1",
										"mn": "ADBE Vector Shape - Rect",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 500,
						"op": 525,
						"st": 500,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 31,
						"ty": 4,
						"nm": "Ellipse 32",
						"parent": 27,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 500,
										"s": [170.333, 166.5, 0],
										"to": [-30.604, -31.145, 0],
										"ti": [30.604, 31.145, 0]
									},
									{ "t": 560, "s": [-13.289, -20.37, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [131, 131, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[-37.279, 0],
													[0, -37.279],
													[37.279, 0],
													[0, 37.279]
												],
												"o": [
													[37.279, 0],
													[0, 37.279],
													[-37.279, 0],
													[0, -37.279]
												],
												"v": [
													[0, -67.5],
													[67.5, 0],
													[0, 67.5],
													[-67.5, 0]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392156863, 0.435294117647, 0.352941176471, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Ellipse 29",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 500,
						"op": 530,
						"st": 500,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 32,
						"ty": 3,
						"nm": "Null 21",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 420, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 480, "s": [180] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 532, "s": [180] },
									{ "t": 592, "s": [360] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [960, 540, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [127, 127, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 303,
						"op": 500,
						"st": 303,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 33,
						"ty": 4,
						"nm": "Layer 14",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 380,
										"s": [-36.993, 108.472, 0],
										"to": [0, 23.622, 0],
										"ti": [0, -23.622, 0]
									},
									{ "t": 410, "s": [-36.993, 250.205, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0.014, 29.375, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 326,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.833, 88.111],
																[28.944, 88.111]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.833, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 346,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.889, -28.889],
																[28.889, -28.889]
															],
															"c": true
														}
													]
												},
												{
													"t": 380,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.889, -28.889],
																[28.889, -28.889]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 326,
						"op": 411,
						"st": 370,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 34,
						"ty": 4,
						"nm": "Layer 13",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 337,
										"s": [136.993, 99.097, 0],
										"to": [0, -3.333, 0],
										"ti": [0, 3.333, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.054, "y": 0.054 },
										"t": 357,
										"s": [136.993, 79.097, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 380, "s": [136.993, 79.097, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 337,
										"s": [60, 60, 100]
									},
									{
										"i": { "x": [0.59, 0.59, 0.59], "y": [1, 1, 1] },
										"o": { "x": [0.164, 0.164, 0.164], "y": [0, 0, 0] },
										"t": 357,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.831, 0.831, 0.831], "y": [1, 1, 1] },
										"o": { "x": [0.422, 0.422, 0.422], "y": [0, 0, 0] },
										"t": 380,
										"s": [100, 100, 100]
									},
									{ "t": 400, "s": [0, 0, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[28.889, 28.889],
													[-28.889, 28.889],
													[-28.889, -28.889],
													[28.889, -28.889]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							},
							{
								"ty": "rd",
								"nm": "Round Corners 1",
								"r": { "a": 0, "k": 335, "ix": 1 },
								"ix": 2,
								"mn": "ADBE Vector Filter - RC",
								"hd": false
							}
						],
						"ip": 337,
						"op": 500,
						"st": 390,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 35,
						"ty": 4,
						"nm": "Layer 12",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 380, "s": [0] },
									{ "t": 410, "s": [90] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 380,
										"s": [136.993, 79.097, 0],
										"to": [-0.394, 7.48, 0],
										"ti": [0.394, -7.48, 0]
									},
									{ "t": 410, "s": [134.631, 123.979, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 326,
										"s": [90, 90, 100]
									},
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 346,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 380,
										"s": [100, 100, 100]
									},
									{ "t": 410, "s": [68, 68, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 380,
						"op": 500,
						"st": 370,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 36,
						"ty": 4,
						"nm": "Layer 11",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [50.326, 165.764, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [-86.667, 86.667, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 326,
										"s": [90, 90, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 346,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 380,
										"s": [100, 100, 100]
									},
									{ "t": 400, "s": [59, 59, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 326,
						"op": 380,
						"st": 370,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 37,
						"ty": 4,
						"nm": "Layer 10",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0], "y": [1] }, "o": { "x": [0.05], "y": [0] }, "t": 298, "s": [180] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 338, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 382, "s": [0] },
									{ "t": 487, "s": [360] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 298,
										"s": [-36.34, 53.264, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 318,
										"s": [-36.34, -116.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.05, "y": 0.05 },
										"t": 338,
										"s": [-36.34, -66.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 380,
										"s": [-36.34, -66.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 410,
										"s": [-36.34, -208.468, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 440, "s": [-36.34, -19.492, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 298,
										"s": [0, 0, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 318,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 410,
										"s": [100, 100, 100]
									},
									{ "t": 424, "s": [0, 0, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[0, -59.167],
													[-86.667, 59.167],
													[86.667, 59.167]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823531866, 0.380392163992, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 297,
						"op": 500,
						"st": 298,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 38,
						"ty": 4,
						"nm": "Rectangle 2154",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [0.594] }, "o": { "x": [0.78], "y": [0] }, "t": 266, "s": [180] },
									{ "i": { "x": [0.594], "y": [1] }, "o": { "x": [0.212], "y": [0.113] }, "t": 303, "s": [293] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 326, "s": [360] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 380, "s": [360] },
									{ "t": 419, "s": [180] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 266,
										"s": [121.129, 120.079, 0],
										"to": [-39.443, -37.706, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 312,
										"s": [-115.531, -106.156, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.167, "y": 0.167 },
										"t": 326,
										"s": [-35.459, 79.137, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 380,
										"s": [-35.459, 79.137, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 419,
										"s": [-35.459, -172.832, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 439, "s": [-35.459, 89.373, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 312,
										"s": [78.74, 78.74, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 326,
										"s": [135.433, 135.433, 100]
									},
									{ "t": 419, "s": [102.362, 102.362, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 419,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[48, -48],
																[48, 48],
																[-48, 48],
																[-48, -48]
															],
															"c": true
														}
													]
												},
												{
													"t": 439,
													"s": [
														{
															"i": [
																[-37.279, 0],
																[0, -37.279],
																[37.279, 0],
																[0, 37.279]
															],
															"o": [
																[37.279, 0],
																[0, 37.279],
																[-37.279, 0],
																[0, -37.279]
															],
															"v": [
																[5.684, 25.706],
																[73.184, 93.206],
																[5.684, 160.706],
																[-61.816, 93.206]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392156863, 0.435294117647, 0.352941176471, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 379,
						"op": 500,
						"st": 266,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 39,
						"ty": 4,
						"nm": "Layer 9",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [93.66, -66.736, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 333,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-43.078, 59.417],
																[-43.333, 59.167],
																[-130, -59.167],
																[-129.745, -58.917]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 353,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[130, 59.167],
																[-214.833, 58.917],
																[-130, -59.167],
																[43.333, -59.167]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.833, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 380,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[130, 59.167],
																[-214.833, 58.917],
																[-130, -59.167],
																[43.333, -59.167]
															],
															"c": true
														}
													]
												},
												{
													"t": 390,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[130, 59.167],
																[-214.833, 58.917],
																[-129.621, 58.944],
																[43.712, 58.944]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 326,
						"op": 500,
						"st": 502,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 40,
						"ty": 4,
						"nm": "Rectangle 2153",
						"parent": 32,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [51.813, 43.4, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [78.74, 78.74, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 384,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-20.091, -30.804],
																[-20.091, 31.343],
																[-20.253, 31.174],
																[-20.253, -30.973]
															],
															"c": true
														}
													]
												},
												{
													"t": 414,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[165.47, -31.074],
																[165.47, 31.074],
																[-165.47, 31.074],
																[-165.47, -31.074]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": -45.747, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2146",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 384,
						"op": 500,
						"st": 384,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 41,
						"ty": 3,
						"nm": "Null 85",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.5, "y": 1 },
										"o": { "x": 0.5, "y": 0 },
										"t": 2188,
										"s": [1158.991, 591.285, 0],
										"to": [-32.333, -4.333, 0],
										"ti": [32.333, 4.333, 0]
									},
									{ "t": 2208, "s": [964.991, 565.285, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [272, 272, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 2188,
						"op": 2574,
						"st": -527,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 42,
						"ty": 4,
						"nm": "Rött tak 2",
						"parent": 41,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0], "y": [1] }, "o": { "x": [0.05], "y": [0] }, "t": 2215.5, "s": [180] },
									{ "t": 2243, "s": [0] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 2215.5,
										"s": [10.133, 51.765, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 2229.25,
										"s": [10.133, -26.435, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 2243, "s": [10.133, -3.435, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 2215.5,
										"s": [0, 0, 100]
									},
									{ "t": 2229.25, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[0, -59.167],
													[-86.667, 59.167],
													[86.667, 59.167]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823531866, 0.380392163992, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2215,
						"op": 2953,
						"st": 2228,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 43,
						"ty": 4,
						"nm": "Blått tak 2",
						"parent": 41,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [69.933, -3.435, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [46, 46, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.833, "y": 0.758 },
													"o": { "x": 0.5, "y": 0 },
													"t": 2229.938,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-43.078, 59.417],
																[-43.333, 59.167],
																[-130, -59.167],
																[-129.745, -58.917]
															],
															"c": true
														}
													]
												},
												{
													"t": 2243,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[129.396, 59.168],
																[-43.5, 59.124],
																[-129.961, -59.418],
																[42.73, -59.166]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.786652149874, 0.894203515146, 0.963343721278, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2229,
						"op": 2953,
						"st": 2405,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 44,
						"ty": 4,
						"nm": "Gul vägg 2",
						"parent": 41,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.96], "y": [1] }, "o": { "x": [1], "y": [0] }, "t": 2188, "s": [-90] },
									{ "t": 2215.5, "s": [0] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 1, "y": 0 },
										"t": 2188,
										"s": [10.133, 64.572, 0],
										"to": [0, -10.121, 0],
										"ti": [0, 0.154, 0]
									},
									{
										"i": { "x": 0.96, "y": 1 },
										"o": { "x": 1, "y": 0 },
										"t": 2201.75,
										"s": [10.133, 3.849, 0],
										"to": [0, -0.154, 0],
										"ti": [0, -9.967, 0]
									},
									{ "t": 2215.5, "s": [10.133, 63.649, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 2196,
										"s": [40, 40, 100]
									},
									{ "t": 2199, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 2188,
													"s": [
														{
															"i": [
																[0, -46.668],
																[46.668, 0],
																[0, 0],
																[0, 46.668],
																[-46.668, 0],
																[0, 0]
															],
															"o": [
																[0, 46.668],
																[0, 0],
																[-46.668, 0],
																[0, -46.668],
																[0, 0],
																[46.668, 0]
															],
															"v": [
																[87.033, 0.012],
																[2.533, 84.512],
																[3.609, 84.045],
																[-80.891, -0.455],
																[3.609, -84.955],
																[2.533, -84.488]
															],
															"c": true
														}
													]
												},
												{
													"t": 2209.3125,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[86.667, 86.667],
																[0, 86.667],
																[-86.667, 86.667],
																[-86.667, 0],
																[-86.667, -86.667],
																[86.667, -86.667]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2188,
						"op": 2953,
						"st": 2188,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 45,
						"ty": 4,
						"nm": "Fönster 2",
						"parent": 41,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 2216.188,
										"s": [89.867, 72.849, 0],
										"to": [0, -1.533, 0],
										"ti": [0, 1.533, 0]
									},
									{ "t": 2229.9375, "s": [89.867, 63.649, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 2216.188,
										"s": [27.6, 27.6, 100]
									},
									{ "t": 2229.9375, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[28.889, 28.889],
													[-28.889, 28.889],
													[-28.889, -28.889],
													[28.889, -28.889]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							},
							{
								"ty": "rd",
								"nm": "Round Corners 1",
								"r": { "a": 0, "k": 335, "ix": 1 },
								"ix": 2,
								"mn": "ADBE Vector Filter - RC",
								"hd": false
							}
						],
						"ip": 2215,
						"op": 2953,
						"st": 2273,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 46,
						"ty": 4,
						"nm": "Grön vägg 2",
						"parent": 41,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [50, 103.515, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [-86.667, 86.667, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 2214.812,
										"s": [0, 0, 100]
									},
									{ "t": 2228.5625, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392163992, 0.435294121504, 0.352941185236, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 2213,
						"op": 2953,
						"st": 2271,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 47,
						"ty": 3,
						"nm": "Null 137",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 266, "s": [0] },
									{ "t": 326, "s": [226] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [958.5, 535, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 311,
										"s": [100, 100, 100]
									},
									{ "t": 326, "s": [94, 94, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"ip": 266,
						"op": 3866,
						"st": 266,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 48,
						"ty": 3,
						"nm": "Null 137",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 266, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.479], "y": [0] }, "t": 326, "s": [180] },
									{ "t": 356, "s": [180] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [958.5, 535, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 266,
						"op": 3866,
						"st": 266,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 49,
						"ty": 4,
						"nm": "Rectangle 2152",
						"parent": 48,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [0.594] }, "o": { "x": [0.78], "y": [0] }, "t": 266, "s": [0] },
									{ "i": { "x": [0.594], "y": [1] }, "o": { "x": [0.212], "y": [0.113] }, "t": 303, "s": [113] },
									{ "t": 326, "s": [180] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 266,
										"s": [-41.833, -44, 0],
										"to": [50.093, 47.886, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 312,
										"s": [258.725, 243.318, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 326, "s": [157.033, 7.996, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 312,
										"s": [100, 100, 100]
									},
									{ "t": 326, "s": [172, 172, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ty": "rc",
										"d": 1,
										"s": { "a": 0, "k": [96, 96], "ix": 2 },
										"p": { "a": 0, "k": [0, 0], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 4 },
										"nm": "Rectangle Path 1",
										"mn": "ADBE Vector Shape - Rect",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392186782, 0.435294147566, 0.352941176471, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 291,
						"op": 379,
						"st": 266,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 50,
						"ty": 4,
						"nm": "Rectangle 2151",
						"parent": 47,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [48.302, 64.118, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[165.47, -31.074],
													[165.47, 31.074],
													[-165.47, 31.074],
													[-165.47, -31.074]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": -45.747, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2146",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 266,
						"op": 380,
						"st": 266,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 51,
						"ty": 4,
						"nm": "Rectangle 2150",
						"parent": 48,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 266, "s": [0] },
									{ "t": 326, "s": [90] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 266,
										"s": [-56.333, -39, 0],
										"to": [32.04, 30.291, 0],
										"ti": [-32.04, -30.291, 0]
									},
									{ "t": 312, "s": [135.907, 142.748, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [117, 117, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ty": "rc",
										"d": 1,
										"s": { "a": 0, "k": [96, 96], "ix": 2 },
										"p": { "a": 0, "k": [0, 0], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 4 },
										"nm": "Rectangle Path 1",
										"mn": "ADBE Vector Shape - Rect",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823529412, 0.380392156863, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 266,
						"op": 291,
						"st": 266,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 52,
						"ty": 4,
						"nm": "Ellipse 31",
						"parent": 48,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 266,
										"s": [170.333, 166.5, 0],
										"to": [-30.604, -31.145, 0],
										"ti": [30.604, 31.145, 0]
									},
									{ "t": 326, "s": [-13.289, -20.37, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [131, 131, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[-37.279, 0],
													[0, -37.279],
													[37.279, 0],
													[0, 37.279]
												],
												"o": [
													[37.279, 0],
													[0, 37.279],
													[-37.279, 0],
													[0, -37.279]
												],
												"v": [
													[0, -67.5],
													[67.5, 0],
													[0, 67.5],
													[-67.5, 0]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Ellipse 29",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 266,
						"op": 296,
						"st": 266,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 53,
						"ty": 3,
						"nm": "Null 21",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 154, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 214, "s": [180] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 266, "s": [180] },
									{ "t": 326, "s": [360] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [960, 540, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [127, 127, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 37,
						"op": 485,
						"st": 37,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 54,
						"ty": 4,
						"nm": "Layer 6",
						"parent": 53,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 114,
										"s": [-36.993, 108.472, 0],
										"to": [0, 23.622, 0],
										"ti": [0, -23.622, 0]
									},
									{ "t": 144, "s": [-36.993, 250.205, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0.014, 29.375, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 60,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.833, 88.111],
																[28.944, 88.111]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.833, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 80,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.889, -28.889],
																[28.889, -28.889]
															],
															"c": true
														}
													]
												},
												{
													"t": 114,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[28.917, 87.639],
																[-28.861, 87.639],
																[-28.889, -28.889],
																[28.889, -28.889]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 60,
						"op": 145,
						"st": 104,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 55,
						"ty": 4,
						"nm": "Layer 3",
						"parent": 53,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 71,
										"s": [136.993, 99.097, 0],
										"to": [0, -3.333, 0],
										"ti": [0, 3.333, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.054, "y": 0.054 },
										"t": 91,
										"s": [136.993, 79.097, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 114, "s": [136.993, 79.097, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 71,
										"s": [60, 60, 100]
									},
									{
										"i": { "x": [0.59, 0.59, 0.59], "y": [1, 1, 1] },
										"o": { "x": [0.164, 0.164, 0.164], "y": [0, 0, 0] },
										"t": 91,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.831, 0.831, 0.831], "y": [1, 1, 1] },
										"o": { "x": [0.422, 0.422, 0.422], "y": [0, 0, 0] },
										"t": 114,
										"s": [100, 100, 100]
									},
									{ "t": 134, "s": [0, 0, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[28.889, 28.889],
													[-28.889, 28.889],
													[-28.889, -28.889],
													[28.889, -28.889]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							},
							{
								"ty": "rd",
								"nm": "Round Corners 1",
								"r": { "a": 0, "k": 335, "ix": 1 },
								"ix": 2,
								"mn": "ADBE Vector Filter - RC",
								"hd": false
							}
						],
						"ip": 71,
						"op": 267,
						"st": 124,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 56,
						"ty": 4,
						"nm": "Layer 8",
						"parent": 53,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 114, "s": [0] },
									{ "t": 144, "s": [90] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.833, "y": 0.833 },
										"o": { "x": 0.167, "y": 0.167 },
										"t": 114,
										"s": [136.993, 79.097, 0],
										"to": [-0.394, 7.48, 0],
										"ti": [0.394, -7.48, 0]
									},
									{ "t": 144, "s": [134.631, 123.979, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 60,
										"s": [90, 90, 100]
									},
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 80,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 114,
										"s": [100, 100, 100]
									},
									{ "t": 144, "s": [68, 68, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392163992, 0.435294121504, 0.352941185236, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 114,
						"op": 267,
						"st": 104,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 57,
						"ty": 4,
						"nm": "Layer 2",
						"parent": 53,
						"td": 1,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [50.326, 165.764, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [-86.667, 86.667, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 60,
										"s": [90, 90, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 80,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 114,
										"s": [100, 100, 100]
									},
									{ "t": 134, "s": [59, 59, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392163992, 0.435294121504, 0.352941185236, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 60,
						"op": 114,
						"st": 104,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 58,
						"ty": 4,
						"nm": "Layer 5",
						"parent": 53,
						"tt": 2,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0], "y": [1] }, "o": { "x": [0.05], "y": [0] }, "t": 32, "s": [180] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 72, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 116, "s": [0] },
									{ "t": 221, "s": [360] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 32,
										"s": [-36.34, 53.264, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 52,
										"s": [-36.34, -116.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.05, "y": 0.05 },
										"t": 72,
										"s": [-36.34, -66.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 114,
										"s": [-36.34, -66.736, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 144,
										"s": [-36.34, -208.468, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 174, "s": [-36.34, -19.492, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 32,
										"s": [0, 0, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 52,
										"s": [100, 100, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
										"t": 144,
										"s": [100, 100, 100]
									},
									{ "t": 158, "s": [0, 0, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[0, -59.167],
													[-86.667, 59.167],
													[86.667, 59.167]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823531866, 0.380392163992, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 31,
						"op": 267,
						"st": 32,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 59,
						"ty": 4,
						"nm": "Rectangle 2148",
						"parent": 53,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [0.594] }, "o": { "x": [0.78], "y": [0] }, "t": 0, "s": [180] },
									{ "i": { "x": [0.594], "y": [1] }, "o": { "x": [0.212], "y": [0.113] }, "t": 37, "s": [293] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.167], "y": [0] }, "t": 60, "s": [360] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 114, "s": [360] },
									{ "t": 153, "s": [180] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 0,
										"s": [121.129, 120.079, 0],
										"to": [-39.443, -37.706, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 46,
										"s": [-115.531, -106.156, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 0.22 },
										"o": { "x": 0.167, "y": 0.167 },
										"t": 60,
										"s": [-35.459, 79.137, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 114,
										"s": [-35.459, 79.137, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 153,
										"s": [-35.459, -172.832, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 173, "s": [-35.459, 89.373, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 46,
										"s": [78.74, 78.74, 100]
									},
									{
										"i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 60,
										"s": [135.433, 135.433, 100]
									},
									{ "t": 153, "s": [102.362, 102.362, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 153,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[48, -48],
																[48, 48],
																[-48, 48],
																[-48, -48]
															],
															"c": true
														}
													]
												},
												{
													"t": 173,
													"s": [
														{
															"i": [
																[-37.279, 0],
																[0, -37.279],
																[37.279, 0],
																[0, 37.279]
															],
															"o": [
																[37.279, 0],
																[0, 37.279],
																[-37.279, 0],
																[0, -37.279]
															],
															"v": [
																[5.684, 25.706],
																[73.184, 93.206],
																[5.684, 160.706],
																[-61.816, 93.206]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 113,
						"op": 267,
						"st": 0,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 60,
						"ty": 4,
						"nm": "Layer 4",
						"parent": 53,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [93.66, -66.736, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 67,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-43.078, 59.417],
																[-43.333, 59.167],
																[-130, -59.167],
																[-129.745, -58.917]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.05, "y": 0 },
													"t": 87,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[130, 59.167],
																[-214.833, 58.917],
																[-130, -59.167],
																[43.333, -59.167]
															],
															"c": true
														}
													]
												},
												{
													"i": { "x": 0.833, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 114,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[130, 59.167],
																[-214.833, 58.917],
																[-130, -59.167],
																[43.333, -59.167]
															],
															"c": true
														}
													]
												},
												{
													"t": 124,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[130, 59.167],
																[-214.833, 58.917],
																[-129.621, 58.944],
																[43.712, 58.944]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 60,
						"op": 267,
						"st": 236,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 61,
						"ty": 4,
						"nm": "Rectangle 2149",
						"parent": 53,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [51.813, 43.4, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [78.74, 78.74, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 118,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-20.091, -30.804],
																[-20.091, 31.343],
																[-20.253, 31.174],
																[-20.253, -30.973]
															],
															"c": true
														}
													]
												},
												{
													"t": 148,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[165.47, -31.074],
																[165.47, 31.074],
																[-165.47, 31.074],
																[-165.47, -31.074]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": -45.747, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2146",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 118,
						"op": 267,
						"st": 118,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 62,
						"ty": 3,
						"nm": "Null 85",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.5, "y": 1 },
										"o": { "x": 0.5, "y": 0 },
										"t": 1922,
										"s": [1158.991, 591.285, 0],
										"to": [-32.333, -4.333, 0],
										"ti": [32.333, 4.333, 0]
									},
									{ "t": 1942, "s": [964.991, 565.285, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [272, 272, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 1922,
						"op": 2308,
						"st": -793,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 63,
						"ty": 4,
						"nm": "Rött tak",
						"parent": 62,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0], "y": [1] }, "o": { "x": [0.05], "y": [0] }, "t": 1949.5, "s": [180] },
									{ "t": 1977, "s": [0] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 1949.5,
										"s": [10.133, 51.765, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 1963.25,
										"s": [10.133, -26.435, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 1977, "s": [10.133, -3.435, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 1949.5,
										"s": [0, 0, 100]
									},
									{ "t": 1963.25, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[0, -59.167],
													[-86.667, 59.167],
													[86.667, 59.167]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458823531866, 0.380392163992, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1949,
						"op": 2687,
						"st": 1962,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 64,
						"ty": 4,
						"nm": "Blått tak",
						"parent": 62,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [69.933, -3.435, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [46, 46, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.833, "y": 0.758 },
													"o": { "x": 0.5, "y": 0 },
													"t": 1963.938,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[-43.078, 59.417],
																[-43.333, 59.167],
																[-130, -59.167],
																[-129.745, -58.917]
															],
															"c": true
														}
													]
												},
												{
													"t": 1977,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[129.396, 59.168],
																[-43.5, 59.124],
																[-129.961, -59.418],
																[42.73, -59.166]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.786652149874, 0.894203515146, 0.963343721278, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1963,
						"op": 2687,
						"st": 2139,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 65,
						"ty": 4,
						"nm": "Gul vägg",
						"parent": 62,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.96], "y": [1] }, "o": { "x": [1], "y": [0] }, "t": 1922, "s": [-90] },
									{ "t": 1949.5, "s": [0] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 1, "y": 0 },
										"t": 1922,
										"s": [10.133, 64.572, 0],
										"to": [0, -10.121, 0],
										"ti": [0, 0.154, 0]
									},
									{
										"i": { "x": 0.96, "y": 1 },
										"o": { "x": 1, "y": 0 },
										"t": 1935.75,
										"s": [10.133, 3.849, 0],
										"to": [0, -0.154, 0],
										"ti": [0, -9.967, 0]
									},
									{ "t": 1949.5, "s": [10.133, 63.649, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 1930,
										"s": [40, 40, 100]
									},
									{ "t": 1933, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 1,
											"k": [
												{
													"i": { "x": 0.22, "y": 1 },
													"o": { "x": 0.78, "y": 0 },
													"t": 1922,
													"s": [
														{
															"i": [
																[0, -46.668],
																[46.668, 0],
																[0, 0],
																[0, 46.668],
																[-46.668, 0],
																[0, 0]
															],
															"o": [
																[0, 46.668],
																[0, 0],
																[-46.668, 0],
																[0, -46.668],
																[0, 0],
																[46.668, 0]
															],
															"v": [
																[87.033, 0.012],
																[2.533, 84.512],
																[3.609, 84.045],
																[-80.891, -0.455],
																[3.609, -84.955],
																[2.533, -84.488]
															],
															"c": true
														}
													]
												},
												{
													"t": 1943.3125,
													"s": [
														{
															"i": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"o": [
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0],
																[0, 0]
															],
															"v": [
																[86.667, 86.667],
																[0, 86.667],
																[-86.667, 86.667],
																[-86.667, 0],
																[-86.667, -86.667],
																[86.667, -86.667]
															],
															"c": true
														}
													]
												}
											],
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1922,
						"op": 2687,
						"st": 1922,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 66,
						"ty": 4,
						"nm": "Fönster",
						"parent": 62,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0, "y": 1 },
										"o": { "x": 0.05, "y": 0 },
										"t": 1950.187,
										"s": [89.867, 72.849, 0],
										"to": [0, -1.533, 0],
										"ti": [0, 1.533, 0]
									},
									{ "t": 1963.9375, "s": [89.867, 63.649, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 1950.187,
										"s": [27.6, 27.6, 100]
									},
									{ "t": 1963.9375, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[28.889, 28.889],
													[-28.889, 28.889],
													[-28.889, -28.889],
													[28.889, -28.889]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							},
							{
								"ty": "rd",
								"nm": "Round Corners 1",
								"r": { "a": 0, "k": 335, "ix": 1 },
								"ix": 2,
								"mn": "ADBE Vector Filter - RC",
								"hd": false
							}
						],
						"ip": 1949,
						"op": 2687,
						"st": 2007,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 67,
						"ty": 4,
						"nm": "Grön vägg",
						"parent": 62,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [50, 103.515, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [-86.667, 86.667, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0, 0, 0], "y": [1, 1, 1] },
										"o": { "x": [0.05, 0.05, 0.05], "y": [0, 0, 0] },
										"t": 1948.812,
										"s": [0, 0, 100]
									},
									{ "t": 1962.5625, "s": [46, 46, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[86.667, 86.667],
													[-86.667, 86.667],
													[-86.667, -86.667],
													[86.667, -86.667]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.380392163992, 0.435294121504, 0.352941185236, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [100, 100], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Group 1",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 1947,
						"op": 2687,
						"st": 2005,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 68,
						"ty": 3,
						"nm": "Null 137",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 0, "s": [0] },
									{ "t": 60, "s": [226] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [958.5, 535, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 45,
										"s": [100, 100, 100]
									},
									{ "t": 60, "s": [94, 94, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"ip": 0,
						"op": 3600,
						"st": 0,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 69,
						"ty": 3,
						"nm": "Null 137",
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 0, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 0, "s": [0] },
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.479], "y": [0] }, "t": 60, "s": [180] },
									{ "t": 90, "s": [180] }
								],
								"ix": 10
							},
							"p": { "a": 0, "k": [958.5, 535, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"ip": 0,
						"op": 3600,
						"st": 0,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 70,
						"ty": 4,
						"nm": "Rectangle 2147",
						"parent": 69,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [0.594] }, "o": { "x": [0.78], "y": [0] }, "t": 0, "s": [0] },
									{ "i": { "x": [0.594], "y": [1] }, "o": { "x": [0.212], "y": [0.113] }, "t": 37, "s": [113] },
									{ "t": 60, "s": [180] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 0,
										"s": [-41.833, -44, 0],
										"to": [50.093, 47.886, 0],
										"ti": [0, 0, 0]
									},
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 46,
										"s": [258.725, 243.318, 0],
										"to": [0, 0, 0],
										"ti": [0, 0, 0]
									},
									{ "t": 60, "s": [157.033, 7.996, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.22, 0.22, 0.22], "y": [1, 1, 1] },
										"o": { "x": [0.78, 0.78, 0.78], "y": [0, 0, 0] },
										"t": 46,
										"s": [100, 100, 100]
									},
									{ "t": 60, "s": [172, 172, 100] }
								],
								"ix": 6,
								"l": 2
							}
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ty": "rc",
										"d": 1,
										"s": { "a": 0, "k": [96, 96], "ix": 2 },
										"p": { "a": 0, "k": [0, 0], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 4 },
										"nm": "Rectangle Path 1",
										"mn": "ADBE Vector Shape - Rect",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.83137254902, 0.23137254902, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 23,
						"op": 113,
						"st": 0,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 71,
						"ty": 4,
						"nm": "Rectangle 2146",
						"parent": 68,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": { "a": 0, "k": [53.802, 46.618, 0], "ix": 2, "l": 2 },
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"o": [
													[0, 0],
													[0, 0],
													[0, 0],
													[0, 0]
												],
												"v": [
													[165.47, -31.074],
													[165.47, 31.074],
													[-165.47, 31.074],
													[-165.47, -31.074]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [0.890196084976, 0.945098042488, 0.980392158031, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": -45.747, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2146",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 0,
						"op": 114,
						"st": 0,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 72,
						"ty": 4,
						"nm": "Rectangle 2145",
						"parent": 69,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": {
								"a": 1,
								"k": [
									{ "i": { "x": [0.22], "y": [1] }, "o": { "x": [0.78], "y": [0] }, "t": 0, "s": [0] },
									{ "t": 60, "s": [90] }
								],
								"ix": 10
							},
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 0,
										"s": [-41.833, -44, 0],
										"to": [32.04, 30.291, 0],
										"ti": [-32.04, -30.291, 0]
									},
									{ "t": 46, "s": [150.407, 137.748, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ty": "rc",
										"d": 1,
										"s": { "a": 0, "k": [96, 96], "ix": 2 },
										"p": { "a": 0, "k": [0, 0], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 4 },
										"nm": "Rectangle Path 1",
										"mn": "ADBE Vector Shape - Rect",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.458086133003, 0.379166662693, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Rectangle 2145",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 0,
						"op": 23,
						"st": 0,
						"bm": 0
					},
					{
						"ddd": 0,
						"ind": 74,
						"ty": 4,
						"nm": "Ellipse 29",
						"parent": 69,
						"sr": 1,
						"ks": {
							"o": { "a": 0, "k": 100, "ix": 11 },
							"r": { "a": 0, "k": 0, "ix": 10 },
							"p": {
								"a": 1,
								"k": [
									{
										"i": { "x": 0.22, "y": 1 },
										"o": { "x": 0.78, "y": 0 },
										"t": 0,
										"s": [142.833, 142, 0],
										"to": [-30.604, -31.145, 0],
										"ti": [30.604, 31.145, 0]
									},
									{ "t": 60, "s": [-40.789, -44.87, 0] }
								],
								"ix": 2,
								"l": 2
							},
							"a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
							"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
						},
						"ao": 0,
						"shapes": [
							{
								"ty": "gr",
								"it": [
									{
										"ind": 0,
										"ty": "sh",
										"ix": 1,
										"ks": {
											"a": 0,
											"k": {
												"i": [
													[-37.279, 0],
													[0, -37.279],
													[37.279, 0],
													[0, 37.279]
												],
												"o": [
													[37.279, 0],
													[0, 37.279],
													[-37.279, 0],
													[0, -37.279]
												],
												"v": [
													[0, -67.5],
													[67.5, 0],
													[0, 67.5],
													[-67.5, 0]
												],
												"c": true
											},
											"ix": 2
										},
										"nm": "Path 1",
										"mn": "ADBE Vector Shape - Group",
										"hd": false
									},
									{
										"ty": "fl",
										"c": { "a": 0, "k": [1, 0.831372608858, 0.231372563979, 1], "ix": 4 },
										"o": { "a": 0, "k": 100, "ix": 5 },
										"r": 1,
										"bm": 0,
										"nm": "Fill 1",
										"mn": "ADBE Vector Graphic - Fill",
										"hd": false
									},
									{
										"ty": "tr",
										"p": { "a": 0, "k": [0, 0], "ix": 2 },
										"a": { "a": 0, "k": [0, 0], "ix": 1 },
										"s": { "a": 0, "k": [133.333, 133.333], "ix": 3 },
										"r": { "a": 0, "k": 0, "ix": 6 },
										"o": { "a": 0, "k": 100, "ix": 7 },
										"sk": { "a": 0, "k": 0, "ix": 4 },
										"sa": { "a": 0, "k": 0, "ix": 5 },
										"nm": "Transform"
									}
								],
								"nm": "Ellipse 29",
								"np": 2,
								"cix": 2,
								"bm": 0,
								"ix": 1,
								"mn": "ADBE Vector Group",
								"hd": false
							}
						],
						"ip": 0,
						"op": 30,
						"st": 0,
						"bm": 0
					}
				]
			}
		],
		"layers": [
			{
				"ddd": 0,
				"ind": 1,
				"ty": 3,
				"nm": "Null 141",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 0, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [500, 510, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [120, 120, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"ip": 31,
				"op": 3453,
				"st": 0,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 2,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"tm": { "a": 0, "k": 12.2, "ix": 2 },
				"w": 1920,
				"h": 1080,
				"ip": 585,
				"op": 3453,
				"st": -147,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 3,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"w": 1920,
				"h": 1080,
				"ip": 463,
				"op": 585,
				"st": -147,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 4,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"tm": { "a": 0, "k": 10.133, "ix": 2 },
				"w": 1920,
				"h": 1080,
				"ip": 434,
				"op": 464,
				"st": -175,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 5,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"w": 1920,
				"h": 1080,
				"ip": 320,
				"op": 434,
				"st": -175,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 6,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"tm": { "a": 0, "k": 8.25, "ix": 2 },
				"w": 1920,
				"h": 1080,
				"ip": 288,
				"op": 320,
				"st": -206,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 7,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"w": 1920,
				"h": 1080,
				"ip": 164,
				"op": 288,
				"st": -206,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 8,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"tm": { "a": 0, "k": 6.167, "ix": 2 },
				"w": 1920,
				"h": 1080,
				"ip": 133,
				"op": 165,
				"st": -236,
				"bm": 0
			},
			{
				"ddd": 0,
				"ind": 9,
				"ty": 0,
				"nm": "Räntor 2",
				"parent": 1,
				"refId": "comp_0",
				"sr": 1,
				"ks": {
					"o": { "a": 0, "k": 100, "ix": 11 },
					"r": { "a": 0, "k": 0, "ix": 10 },
					"p": { "a": 0, "k": [50, 50, 0], "ix": 2, "l": 2 },
					"a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
					"s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
				},
				"ao": 0,
				"w": 1920,
				"h": 1080,
				"ip": 31,
				"op": 133,
				"st": -236,
				"bm": 0
			}
		],
		"markers": []
 }
