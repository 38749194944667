import { isInteger, isNotNull } from "@stabelo/validation-library";

import { Amount } from "./Amount";
import { SerializedPercentage } from "./SerializedPercentage";

export class Percentage {
	public static fromJSON(json: SerializedPercentage): Percentage {
		return new Percentage(json.bps);
	}

	public static fromBps(bps: number): Percentage {
		return new Percentage(bps);
	}

	public static fromDecimal(decimal: number): Percentage {
		return new Percentage(Math.round(isNotNull(decimal, "Cannot call fromDecimal with null value.") * 10000));
	}

	public static fromPercentage(percentage: number): Percentage {
		return new Percentage(Math.round(isNotNull(percentage, "Cannot call fromPercentage with null value.") * 100));
	}

	public static max(...amounts: Percentage[]): Percentage {
		return amounts.reduce((max: Percentage | undefined, amount: Percentage) => {
			if (max === undefined) {
				return amount;
			}
			return max.isGreaterOrEqualThan(amount) ? max : amount;
		});
	}

	public static min(...amounts: Percentage[]): Percentage {
		return amounts.reduce((min: Percentage | undefined, amount: Percentage) => {
			if (min === undefined) {
				return amount;
			}
			return min.isLessOrEqualThan(amount) ? min : amount;
		});
	}

	protected readonly bps: number;

	constructor(bps: number) {
		this.bps = isInteger(bps, `${bps} is not a valid percentage.`);
	}

	public add(percentageToAdd: Percentage): Percentage {
		return new Percentage(this.bps + percentageToAdd.bps);
	}

	public subtract(percentageToSubtract: Percentage): Percentage {
		return new Percentage(this.bps - percentageToSubtract.bps);
	}

	public isLessThan(percentage: Percentage): boolean {
		return this.bps < percentage.bps;
	}

	public isLessOrEqualThan(percentage: Percentage): boolean {
		return this.bps <= percentage.bps;
	}

	public isGreaterThan(percentage: Percentage): boolean {
		return this.bps > percentage.bps;
	}

	public isGreaterOrEqualThan(percentage: Percentage): boolean {
		return this.bps >= percentage.bps;
	}

	public isEqualTo(percentage: Percentage): boolean {
		return this.bps === percentage.bps;
	}

	public equals(percentage: Percentage | undefined): boolean {
		return this.compareWith(percentage) === 0;
	}

	public compareWith(percentage: Percentage | undefined): number {
		if (percentage === undefined) {
			return 1;
		}
		return this.bps - percentage.bps;
	}

	public toPercentage(): number {
		return this.bps / 100;
	}

	public toDecimal(): number {
		return this.bps / 10000;
	}

	public toString(decimals = 2): string {
		return this.toDecimal().toLocaleString("sv-SE", {
			style: "percent",
			maximumFractionDigits: 2,
			minimumFractionDigits: isInteger(decimals),
		});
	}

	public toBps(): number {
		return this.bps;
	}

	public ofAmount(amount: Amount): Amount {
		return amount.multiply(this.toDecimal());
	}

	public toJSON() {
		return {
			bps: this.bps,
			display: this.toString(),
			display_short: this.toString(0),
		};
	}
}
