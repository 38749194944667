import { Button, styled } from "@mui/material";

export const PrimaryButton = styled(Button)(({ theme }) => ({
	"width": "max-content",
	"background": theme.palette.warning.main,
	"fontWeight": 500,
	"&:hover": {
		background: theme.palette.warning.main,
	},
}));
