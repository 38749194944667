import { stringify } from "qs";

import HttpInterface from "./HttpInterface";
import Session from "./Session";

interface IConfiguration {
    autorefresh: boolean;
    idp: {
        url: string;
        scope: string;
        client: string;
    };
}

type ICallback = (session?: Session) => void;

class SessionHandler {
    public currentSession: Session;

    private readonly onUpdateCallbacks: ICallback[] = [];

    constructor(private readonly configuration: IConfiguration) { }

    public initialize(): Promise<SessionHandler> {
        return this.refresh().then(() => this).catch(() => this);
    }

    public redirect(returnPath?: string): string {
        return this.buildUrl("/login", {
            client_id: this.configuration.idp.client,
            redirect_url: returnPath ? `${window.location.protocol}//${window.location.host}${returnPath}` : window.location.href,
            scope: this.configuration.idp.scope,
        });
    }

    public refresh(): Promise<Session> {
        return HttpInterface.fetch(this.buildUrl("/oauth/token"), {
            method: "GET",
        }).then((response) => response.json()).then((json) => {
            return this.notifyUpdate(this.currentSession = this.buildSession(json.access_token));
        }).catch((error) => {
            if (error instanceof Response) {
                return Promise.reject<Session>(this.currentSession = null);
            }
            // If we didn't get a response, assume it was aborted so just try again
            return new Promise((resolve) => setTimeout(() => resolve(this.refresh()), 100));
        });
    }

    public destroy(): Promise<void> {
        return HttpInterface.fetch(this.buildUrl("/oauth/token"), {
            method: "DELETE",
        }).then(() => {
            this.notifyUpdate(this.currentSession = null);
        });
    }

    public onUpdate(callback: (session?: Session) => void): SessionHandler {
        this.onUpdateCallbacks.push(callback);
        return this;
    }

    private buildUrl(path: string, query?: any): string {
        return `${this.configuration.idp.url}/v1/${path.startsWith("/") ? path.substr(1) : path}` + (query !== undefined ? "?" + stringify(query) : "");
    }

    private buildSession(token: string): Session {
        const session = new Session(token);

        if (this.configuration.autorefresh === true) {
            session.onExpire(() => this.refresh());
        } else {
            session.onExpire(() => this.notifyUpdate(this.currentSession = null));
        }

        session.onDestroy(() => {
            this.destroy();
        });

        return session;
    }

    private notifyUpdate(session?: Session): Session {
        this.onUpdateCallbacks.forEach((callback) => callback(session));

        return session;
    }
}

export default SessionHandler;
