import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import * as React from "react";

export const TabPanelForm = ({
	id,
	labelledBy,
	hidden,
	loading,
	onSubmit,
	children,
}: {
	id: string;
	labelledBy: string;
	hidden: boolean;
	loading: boolean;
	onSubmit: () => void;
	children: React.ReactNode;
}) => {
	return (
		<form
			role="tabpanel"
			id={id}
			tabIndex={0}
			aria-labelledby={labelledBy}
			hidden={hidden}
			onSubmit={(event) => {
				event.preventDefault();
				void onSubmit();
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					font: "1rem",
					rowGap: "0.5rem",
					minHeight: "35rem",
				}}
			>
				{children}
				<LoadingButton
					type="submit"
					fullWidth
					loading={loading}
					variant="contained"
					sx={{
						marginTop: "auto",
						fontWeight: "bold",
					}}
				>
					Påbörja ansökan
				</LoadingButton>
			</Box>
		</form>
	);
};
