import { Box, Slider, SxProps, Tooltip, Typography } from "@mui/material";
import * as React from "react";

import { stabeloTheme } from "../../../../../theme-config";
import useEffectDebounce from "../../../../hooks/useEffectDebounce";
import { NumberTextField } from "../../../maskedInputs/NumberTextField";

interface IProps {
	label: string;
	value: number;
	max: number;
	min: number;
	sliderStep: number;
	textFieldDebounceMS?: number;
	error?: boolean;
	helperText?: string;
	sx?: SxProps;
	onChange: (value: number) => void;
}

const AmountInputStyle: SxProps = {
	"marginLeft": "auto",
	"width": "150px",
	"background": stabeloTheme.palette.info.light,
	"borderRadius": 2,
	"boxShadow": 3,
	".MuiInputBase-root": {
		"background": "none",
		"borderColor": stabeloTheme.palette.primary.main,
		"borderWidth": 1,
		"&:hover": {
			background: "none",
		},
		"&.Mui-focused": {
			background: "none",
		},
	},
	[stabeloTheme.breakpoints.up(1500)]: {
		width: "160px",
	},
	[stabeloTheme.breakpoints.down(1350)]: {
		width: "140px",
	},
	[stabeloTheme.breakpoints.down("sm")]: {
		background: "white",
	},
};

export const AmountSelector = ({
	label,
	value,
	max,
	min,
	sliderStep,
	textFieldDebounceMS,
	error,
	helperText,
	onChange,
	sx,
}: IProps) => {
	const [textFieldAmount, setTextFieldAmount] = React.useState<number>(value);

	React.useEffect(() => {
		setTextFieldAmount(value);
	}, [value]);

	useEffectDebounce(
		() => {
			onChange(textFieldAmount);
		},
		textFieldDebounceMS ?? 0,
		[textFieldAmount],
	);

	const handleSliderChange = (_event: Event, sliderValue: number | number[]) => {
		if (Array.isArray(sliderValue)) {
			throw new Error("Array slider value not supported");
		}

		onChange(sliderValue);
	};

	const handleTextFieldChange = (value: string) => {
		setTextFieldAmount(parseInt(value, 10));
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", ...sx }}>
			<Box display={"flex"} sx={{ alignItems: "center" }}>
				<Typography>{label}</Typography>
				<Tooltip
					open={error == true}
					title={helperText}
					slotProps={{
						arrow: {
							sx: {
								color: "#FF7561",
							},
						},
						tooltip: {
							sx: {
								background: "#FF7561",
								paddingLeft: "0.5rem",
								paddingRight: "0.5rem",
								fontWeight: 400,
								color: "black",
							},
						},
						popper: {
							modifiers: [
								{
									name: "offset",
									options: {
										offset: [0, -5],
									},
								},
							],
						},
					}}
				>
					<Box sx={AmountInputStyle}>
						<NumberTextField
							hiddenLabel
							value={Number.isNaN(textFieldAmount) ? "" : textFieldAmount.toString()}
							suffix={"kr"}
							max={max}
							onChange={handleTextFieldChange}
							inputProps={{ sx: { padding: 1 } }}
						/>
					</Box>
				</Tooltip>
			</Box>
			<Slider
				aria-label="mortgage"
				value={Number.isNaN(textFieldAmount) ? min : textFieldAmount}
				onChange={handleSliderChange}
				min={min}
				max={max}
				step={sliderStep}
				sx={{
					padding: "20px 0",
				}}
			/>
		</Box>
	);
};
