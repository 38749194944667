class FormHelper {

    public static serializeForm(form: HTMLFormElement | EventTarget): object {
        const output = {};
        const data = new FormData(form as HTMLFormElement);

        for (const name of (data as any).keys()) {
            // Use filtering to handle the following FormData.getAll() bug in Edge:
            // "On Microsoft Edge 44.18.18362 (with a user Agent string of Edge/18362), the results of getAll() are wrong for elements after the first. Notice the first element of the array is undefined when it should be omitted."
            // https://github.com/jimmywarting/FormData/issues/80
            const values = data.getAll(name).filter((filterData) => filterData !== undefined);

            output[name] = values.length > 1 ? values : values[0];
        }

        return Object.keys(output).reduce((object, name) => {
            name.replace("[", ".[").split(".").reduce((nested, part, index, parts) => {
                if (FormHelper.isArrayIndex(part)) {
                    part = String(parseInt(part.match(/\d+/)[0], 10));
                }
                nested[part] = nested[part] || (FormHelper.isArrayIndex(parts[index + 1]) ? [] : {});
                if (index + 1 === parts.length) {
                    nested[part] = output[name];
                }
                return nested[part];
            }, object);

            return object;
        }, {});
    }

    private static isArrayIndex(str: string): boolean {
        return /^\[\d+\]$/.test(str);
    }

}

export default FormHelper;
