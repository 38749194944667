import * as React from "react";

import SessionContext from "./SessionContext";
import SessionState from "./SessionState";

interface IProperties {
    children: (value: SessionState) => React.ReactNode;
}

class SessionConsumer extends React.Component<IProperties> {
    public render() {
        const {
            children,
        } = this.props;

        return (
            <SessionContext.Consumer>{children}</SessionContext.Consumer>
        );
    }
}

export default SessionConsumer;
