/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { stringifyUrl } from "query-string";

import { HttpInterface } from "@stabelo/awesome-react-infrastructure-library";

import type { LanaPengarV1_BanksResponse } from "../models/LanaPengarV1_BanksResponse";
import type { LanaPengarV1_CreateApplicationResponse } from "../models/LanaPengarV1_CreateApplicationResponse";
import type { LanaPengarV1_CreateCommitmentApplicationRequest } from "../models/LanaPengarV1_CreateCommitmentApplicationRequest";
import type { LanaPengarV1_CreatePurchaseApplicationRequest } from "../models/LanaPengarV1_CreatePurchaseApplicationRequest";
import type { LanaPengarV1_CreateTransferApplicationRequest } from "../models/LanaPengarV1_CreateTransferApplicationRequest";

export class LanaPengarApiV1Service {
    private static url: string | undefined;

    public static setUrl(url: string) {
        LanaPengarApiV1Service.url = url;
    }

    /**
     * @returns LanaPengarV1_CreateApplicationResponse
     */
    public static async lanaPengarV1ControllerCreateApplication({
        requestBody,
    }: {
        requestBody: (LanaPengarV1_CreateTransferApplicationRequest | LanaPengarV1_CreatePurchaseApplicationRequest | LanaPengarV1_CreateCommitmentApplicationRequest),
    }, ): Promise<LanaPengarV1_CreateApplicationResponse> {
        const query =  {} ;
        if (LanaPengarApiV1Service.url === undefined) throw new Error("No url set for generated LanaPengarApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": LanaPengarApiV1Service.url + `/v1-lanapengar/$create-application`, query }), {
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `LanaPengarApiV1Service.lanaPengarV1ControllerCreateApplication (${"/v1-lanapengar/$create-application"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

    /**
     * @returns LanaPengarV1_BanksResponse
     */
    public static async lanaPengarV1ControllerGetBanks(): Promise<LanaPengarV1_BanksResponse> {
        const query =  {} ;
        if (LanaPengarApiV1Service.url === undefined) throw new Error("No url set for generated LanaPengarApiV1Service.");
        return HttpInterface.fetch(stringifyUrl({ "url": LanaPengarApiV1Service.url + `/v1-lanapengar/$get-banks`, query }), {
            method: "GET",
        }).then((response) => !response.ok ? response.json().catch(() => response.text().then((text) => ({ status: response.status, message: `LanaPengarApiV1Service.lanaPengarV1ControllerGetBanks (${"/v1-lanapengar/$get-banks"}) failed with status ${response.status} and message: ${text}.` }))).then((retval) => Promise.reject(retval)) : response.json());
    }

}