import { useEffect } from "react";

const useEffectDebounce = (callback: () => any, timeInMs: number, dependencies: ReadonlyArray<any> = []) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			void callback();
		}, timeInMs);

		return () => clearTimeout(timer);
	}, [timeInMs, callback, dependencies]);
};

export default useEffectDebounce;
