import { Percentage } from "@stabelo/awesome-money-library";

import { ComponentsV1_RateTableItemResponse } from "../../../../generated/iris/models/ComponentsV1_RateTableItemResponse";
import { IRateItem } from "../components/rateTable/RateTable";
import { IRateDictionary } from "../RateCalculator";

export type InterestRateFixation = "3M" | "1Y" | "2Y" | "3Y" | "5Y" | "10Y";

export const getRateFixationDictionary = (rates: ComponentsV1_RateTableItemResponse[]): IRateDictionary => {
	const dict: IRateDictionary = rates.reduce<IRateDictionary>((dict, rate) => {
		const fixationIndex = rate.configuration.rate_fixation;
		if (dict[fixationIndex] === undefined) {
			dict[fixationIndex] = [];
		}

		let productAmountIndex = dict[fixationIndex].findIndex(
			(r) => r.product_amount_value == rate.configuration.product_amount.value,
		);

		if (productAmountIndex === -1) {
			dict[fixationIndex].push({
				product_amount_value: rate.configuration.product_amount.value,
				items: [],
			});
			productAmountIndex = dict[fixationIndex].length - 1;
		}

		let ltvIndex = dict[fixationIndex][productAmountIndex].items.findIndex(
			(r) => r.loan_to_value_bps === rate.configuration.loan_to_value.bps,
		);

		if (ltvIndex === -1) {
			dict[fixationIndex][productAmountIndex].items.push({
				loan_to_value_bps: rate.configuration.loan_to_value.bps,
				price: rate.price,
				is_epc: rate.configuration.epc_classification !== undefined,
			});
			ltvIndex = 0;
		}

		return dict;
	}, {} as IRateDictionary);

	return dict;
};

export const getLabelForRateFixation = (rateFixation: string): string => {
	const characters = rateFixation.split("");

	const interval = characters[rateFixation.length - 1] === "Y" ? "år" : "mån";
	const duration = characters.slice(0, rateFixation.length - 1).join("");

	return `${duration} ${interval}`;
};

export const findRatesForLtvAndLoanSize = (
	ltv: number,
	loanSize: number,
	rateDictionary: IRateDictionary,
): IRateItem[] => {
	const ltvBps = Percentage.fromDecimal(ltv).toBps();
	const loanSizeAmountValue = loanSize * 100;

	return Object.keys(rateDictionary).map((fixation) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [productAmountIndex, _1] = rateDictionary[fixation].reduce(
			([foundIndex, foundValue], current, currentIndex) => {
				// Finding the biggest loan size below our loan size
				if (current.product_amount_value <= loanSizeAmountValue && current.product_amount_value > foundValue) {
					return [currentIndex, current.product_amount_value];
				} else {
					return [foundIndex, foundValue];
				}
			},
			[0, rateDictionary[fixation][0].product_amount_value],
		);

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [ltvIndex, _2] = rateDictionary[fixation][productAmountIndex].items.reduce(
			([foundIndex, foundValue], current, currentIndex) => {
				// Finding the biggest ltv below our ltv
				if (current.loan_to_value_bps < ltvBps && current.loan_to_value_bps >= foundValue) {
					return [currentIndex, current.loan_to_value_bps];
				} else {
					return [foundIndex, foundValue];
				}
			},
			[0, rateDictionary[fixation][productAmountIndex].items[0].loan_to_value_bps],
		);

		const matchingRate = rateDictionary[fixation][productAmountIndex].items[ltvIndex];

		if (matchingRate === undefined)
			throw new Error(`Could not find rate for ltv ${ltv} loan size ${loanSize} and ${fixation}!`);
		return {
			nominalRate: Percentage.fromJSON(matchingRate.price.nominal).toPercentage(),
			effectiveRate: Percentage.fromJSON(matchingRate.price.effective).toPercentage(),
			fixationLabel: getLabelForRateFixation(fixation),
			isEpc: matchingRate.is_epc,
		};
	});
};
