import { ThemeProvider } from "@emotion/react";
import { ComponentTarget, RouteAction } from "@stabelo/awesome-react-infrastructure-library";
import * as React from "react";

import { bolaneguidenTheme } from "../theme-config";

import { BolaneguidenApplicationForm } from "./components/applicationForm/BolaneguidenApplicationForm";
import { RateCalculator } from "./components/rateCalculator/RateCalculator";

export interface IRoute {
	name: string;
	path: string;
	action: any;
}

const rateCalculator: IRoute = {
	name: "rateCalculator",
	path: "/rate-calculator",
	action: RouteAction((params) => {
		return new ComponentTarget(
			"Beräkna din ränta",
			(
				<RateCalculator
					epcApplied={params?.epcApplied === "true"}
					epcLocked={params?.epcLocked === "true"}
					squished={params?.squished === "true"}
					showApplyButton={params?.show_apply_button !== "false"}
					showEffectiveRate={params?.show_effective_rate === "true"}
					showTitleHeading={params?.show_title_heading !== "false"}
					redirectUrl={typeof params?.redirect_url === "string" ? params.redirect_url : undefined}
					clientId={typeof params?.client_id === "string" ? params.client_id : undefined}
				/>
			),
		);
	}),
};

const bolaneguidenApplicationForm: IRoute = {
	name: "bolaneguidenApplicationForm",
	path: "/bolaneguiden-application-form",
	action: RouteAction(() => {
		return new ComponentTarget(
			"Ansök om bolån",
			(
				<ThemeProvider theme={bolaneguidenTheme}>
					<BolaneguidenApplicationForm />
				</ThemeProvider>
			),
		);
	}),
};

export const routes: IRoute[] = [rateCalculator, bolaneguidenApplicationForm];
