import * as React from "react";
import { ResolveContext, Options, Route } from "universal-router";

import Router from "./Router";
import ITarget from "./Target";
import ITargetWrapper from "./TargetWrapper";

class RouterBuilder {
    private routes: Route[];
    private options: Options;
    private authorize: (path: string, target: ITarget) => Promise<ITarget>;
    private render: (component: React.ReactElement<any>) => Promise<void>;
    private hydrate: (component: React.ReactElement<any>) => Promise<void>;

    constructor() {
        this.routes = [];
        this.options = {
            resolveRoute(context, params) {
                if (typeof context.route.action === "function") {
                    return context.route.action(context, params);
                }
            },
        };
        this.render = () => { throw new Error("A render function is required."); };
        this.hydrate = () => { throw new Error("A refresh function is required."); };
    }

    public addRoute(route: Route<ResolveContext, ITargetWrapper>) {
        this.routes.push(route);
        return this;
    }

    public withBaseUrl(baseUrl: string) {
        this.options = { ...this.options, baseUrl };
        return this;
    }

    public withAuthorizationHandler(options: { handler: (target: ITarget, path?: string) => Promise<boolean> | boolean, fallback: (path: string) => ITarget | Promise<ITarget> }) {
        this.authorize = (path: string, target: ITarget) => Promise.resolve(options.handler(target, path)).then((authorized) => authorized === true ? target : options.fallback(path));
        return this;
    }

    public withErrorHandler(errorHandler: (error: Error & { status: number }, context: ResolveContext) => any) {
        this.options = { ...this.options, errorHandler };
        return this;
    }

    public withRenderFunction(renderFunction: (component: React.ReactElement<any>) => Promise<void>) {
        this.render = renderFunction;
        return this;
    }

    public withRefreshFunction(refreshFunction: (component: React.ReactElement<any>) => Promise<void>) {
        this.hydrate = refreshFunction;
        return this;
    }

    public build(): Router {
        return new Router(this.routes, this.options, { render: this.render, hydrate: this.hydrate }, this.authorize);
    }
}

export default RouterBuilder;
