import * as React from "react";

import NavigationContext from "./NavigationContext";
import NavigationState from "./NavigationState";
import Router from "./Router";

interface IProperties {
    router: Router;
    children: React.ReactNode;
}

// tslint:disable-next-line:no-empty-interface
interface IState extends NavigationState {
    // Nothing to add
}

class NavigationProvider extends React.Component<IProperties, IState> {
    constructor(props: IProperties) {
        super(props);

        this.state = props.router.state;
    }

    public componentDidMount() {
        this.props.router.onStateChange((nextState) => {
            this.setState(nextState);
        });
    }

    public render() {
        const {
            children,
        } = this.props;

        return (
            <NavigationContext.Provider value={this.state}>{children}</NavigationContext.Provider>
        );
    }
}

export default NavigationProvider;
