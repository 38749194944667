import * as React from "react";

import { LanaPengarApiV1Service, LanaPengarV1_BankResponse } from "../../generated/iris";

export const useBanks = (): [LanaPengarV1_BankResponse[] | undefined] => {
	const [banks, setBanks] = React.useState<LanaPengarV1_BankResponse[] | undefined>(undefined);

	React.useEffect(() => {
		async function fetchData() {
			const banks = await LanaPengarApiV1Service.lanaPengarV1ControllerGetBanks();
			setBanks(banks.items.sort((bankA, bankB) => bankA.name.localeCompare(bankB.name)));
		}
		void fetchData();
	}, []);

	return [banks];
};
